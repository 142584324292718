import {
	LOAD_TIPS_BANNERS_REQUEST,
	LOAD_TIPS_BANNERS_SUCCESS,
	LOAD_TIPS_BANNERS_FAIL,
	LOAD_HOME_SCREEN_REQUEST,
	LOAD_HOME_SCREEN_SUCCESS,
	LOAD_HOME_SCREEN_FAIL,
	LOAD_HOME_SCREEN_WITH_DAYS_REQUEST,
	LOAD_HOME_SCREEN_WITH_DAYS_SUCCESS,
	LOAD_HOME_SCREEN_WITH_DAYS_FAIL,
	LOAD_ACADEMY_CONTENT_REQUEST,
	LOAD_ACADEMY_CONTENT_SUCCESS,
	LOAD_ACADEMY_CONTENT_FAIL,
	LOAD_CUSTOMERS_REQUEST,
	LOAD_CUSTOMERS_SUCCESS,
	LOAD_CUSTOMERS_FAIL,
	GET_COUPONS_REQUEST,
	GET_COUPONS_SUCCESS,
	GET_COUPONS_FAIL,
	CREATE_COUPON_REQUEST,
	CREATE_COUPON_SUCCESS,
	CREATE_COUPON_FAIL,
	DELETE_COUPON_REQUEST,
	DELETE_COUPON_SUCCESS,
	DELETE_COUPON_FAIL,
	GET_BANNERS_REQUEST,
	GET_BANNERS_SUCCESS,
	GET_BANNERS_FAIL,
	CREATE_BANNER_REQUEST,
	CREATE_BANNER_SUCCESS,
	CREATE_BANNER_FAIL,
	DELETE_BANNER_REQUEST,
	DELETE_BANNER_SUCCESS,
	DELETE_BANNER_FAIL,
	GET_SETTINGS_BASIC_INFO_REQUEST,
	GET_SETTINGS_BASIC_INFO_SUCCESS,
	GET_SETTINGS_BASIC_INFO_FAIL,
	SET_SETTINGS_BASIC_INFO_REQUEST,
	SET_SETTINGS_BASIC_INFO_SUCCESS,
	SET_SETTINGS_BASIC_INFO_FAIL,
	GET_SETTINGS_LOCALIZATION_REQUEST,
	GET_SETTINGS_LOCALIZATION_SUCCESS,
	GET_SETTINGS_LOCALIZATION_FAIL,
	SET_SETTINGS_LOCALIZATION_REQUEST,
	SET_SETTINGS_LOCALIZATION_SUCCESS,
	SET_SETTINGS_LOCALIZATION_FAIL,
	GET_SETTINGS_SERVICE_DETAILS_REQUEST,
	GET_SETTINGS_SERVICE_DETAILS_SUCCESS,
	GET_SETTINGS_SERVICE_DETAILS_FAIL,
	SET_SETTINGS_SERVICE_DETAILS_REQUEST,
	SET_SETTINGS_SERVICE_DETAILS_SUCCESS,
	SET_SETTINGS_SERVICE_DETAILS_FAIL,
	GET_SETTINGS_QUICK_ORDERS_REQUEST,
	GET_SETTINGS_QUICK_ORDERS_SUCCESS,
	GET_SETTINGS_QUICK_ORDERS_FAIL,
	SET_SETTINGS_QUICK_ORDERS_REQUEST,
	SET_SETTINGS_QUICK_ORDERS_SUCCESS,
	SET_SETTINGS_QUICK_ORDERS_FAIL,
	GET_SETTINGS_PAYMENTS_REQUEST,
	GET_SETTINGS_PAYMENTS_SUCCESS,
	GET_SETTINGS_PAYMENTS_FAIL,
	SET_SETTINGS_PAYMENTS_REQUEST,
	SET_SETTINGS_PAYMENTS_SUCCESS,
	SET_SETTINGS_PAYMENTS_FAIL,
	GET_TAXES_REQUEST,
	GET_TAXES_SUCCESS,
	GET_TAXES_FAIL,
	CREATE_TAX_REQUEST,
	CREATE_TAX_SUCCESS,
	CREATE_TAX_FAIL,
	DELETE_TAX_REQUEST,
	DELETE_TAX_SUCCESS,
	DELETE_TAX_FAIL,
	UPLOAD_IMAGE_REQUEST,
	UPLOAD_IMAGE_SUCCESS,
	UPLOAD_IMAGE_FAIL,
	GET_STAFFS_REQUEST,
	GET_STAFFS_SUCCESS,
	GET_STAFFS_FAIL,
	CREATE_STAFF_REQUEST,
	CREATE_STAFF_SUCCESS,
	CREATE_STAFF_FAIL,
	DELETE_STAFF_REQUEST,
	DELETE_STAFF_SUCCESS,
	DELETE_STAFF_FAIL,
	GET_ORDER_BY_ID_REQUEST,
	GET_ORDER_BY_ID_SUCCESS,
	GET_ORDER_BY_ID_FAIL,
	GET_INVOICE_REQUEST,
	GET_INVOICE_SUCCESS,
	GET_INVOICE_FAIL,
	ORDER_STATUS_CHANGE_REQUEST,
	ORDER_STATUS_CHANGE_SUCCESS,
	ORDER_STATUS_CHANGE_FAIL,
	CHANGE_ORDER_STAFF_REQUEST,
	CHANGE_ORDER_STAFF_SUCCESS,
	CHANGE_ORDER_STAFF_FAIL,
	GET_ADJUSTMENTS_REQUEST,
	GET_ADJUSTMENTS_SUCCESS,
	GET_ADJUSTMENTS_FAIL,
	SET_ADJUSTMENTS_REQUEST,
	SET_ADJUSTMENTS_SUCCESS,
	SET_ADJUSTMENTS_FAIL,
	EDIT_ADJUSTMENTS_REQUEST,
	EDIT_ADJUSTMENTS_SUCCESS,
	EDIT_ADJUSTMENTS_FAIL,
	DELETE_ADJUSTMENTS_REQUEST,
	DELETE_ADJUSTMENTS_SUCCESS,
	DELETE_ADJUSTMENTS_FAIL,
	GET_PRINTABLES_REQUEST,
	GET_PRINTABLES_SUCCESS,
	GET_PRINTABLES_FAIL,
	GET_ANNOUNCEMENTS_REQUEST,
	GET_ANNOUNCEMENTS_SUCCESS,
	GET_ANNOUNCEMENTS_FAIL,
	CREATE_ANNOUNCEMENT_REQUEST,
	CREATE_ANNOUNCEMENT_SUCCESS,
	CREATE_ANNOUNCEMENT_FAIL,
	LOAD_PRODUCTS_BY_SUBCATEGORY_REQUEST,
	LOAD_PRODUCTS_BY_SUBCATEGORY_SUCCESS,
	LOAD_PRODUCTS_BY_SUBCATEGORY_FAIL,
	CLEAR_ERRORS,
	GET_LANDING_PAGES_REQUEST,
	GET_LANDING_PAGES_SUCCESS,
	GET_LANDING_PAGES_FAIL,
	SET_LANDING_PAGES_REQUEST,
	SET_LANDING_PAGES_SUCCESS,
	SET_LANDING_PAGES_FAIL,
	GET_SUBDOMAIN_AVAILABILITY_REQUEST,
	GET_SUBDOMAIN_AVAILABILITY_SUCCESS,
	GET_SUBDOMAIN_AVAILABILITY_FAIL,
	GET_REPORTS_CLICK_REQUEST,
	GET_REPORTS_CLICK_SUCCESS,
	GET_REPORTS_CLICK_FAIL,
	SET_CLAIM_STORE,
	CREATE_CLAIM_SUCCESS,
	CREATE_CLAIM_FAIL,
	ADMIN_DASHBOARD_DATA_REQUEST,
	ADMIN_DASHBOARD_DATA_SUCCESS,
	ADMIN_DASHBOARD_DATA_FAIL,
} from '../constants/dashboardConstants';
import { API_ROOT } from '../constants/global';

// Get admin dashboard data
export const getAdminDashBoardData =
	(accessToken: string) => async (dispatch: any) => {
		try {
			dispatch({ type: ADMIN_DASHBOARD_DATA_REQUEST });
			const response = await fetch(`${API_ROOT}admin/dashboard`, {
				method: 'GET',
				headers: {
					'content-type': 'application/json',
					authorization: `Bearer ${accessToken}`,
				},
			});
			const data = await response.json();

			if (data?.statusCode === 404) {
				dispatch({
					type: ADMIN_DASHBOARD_DATA_FAIL,
					payload: data.message,
				});
			} else {
				dispatch({
					type: ADMIN_DASHBOARD_DATA_SUCCESS,
					payload: data.data,
				});
			}
		} catch (error) {
			console.log(error);
			dispatch({
				type: ADMIN_DASHBOARD_DATA_FAIL,
				payload: error.message,
			});
		}
	};

//Load tip banners
export const getTipBanners = () => async (dispatch: any) => {
	try {
		dispatch({ type: LOAD_TIPS_BANNERS_REQUEST });
		const response = await fetch(`${API_ROOT}api/tips-banners`, {
			method: 'GET',
		});
		const data = await response.json();
		dispatch({
			type: LOAD_TIPS_BANNERS_SUCCESS,
			payload: data,
		});
	} catch (error) {
		dispatch({
			type: LOAD_TIPS_BANNERS_FAIL,
			payload: error,
		});
	}
};

//Load Home Screen Data with Days
export const getHomeScreenDataWithDays =
	(accessToken: string, stats: string) => async (dispatch: any) => {
		try {
			dispatch({ type: LOAD_HOME_SCREEN_WITH_DAYS_REQUEST });
			const response = await fetch(
				`${API_ROOT}partners/dashboard?stats=${stats}`,
				{
					method: 'GET',
					headers: {
						authorization: `${accessToken}`,
					},
				}
			);
			const data = await response.json();
			dispatch({
				type: LOAD_HOME_SCREEN_WITH_DAYS_SUCCESS,
				payload: data,
			});
		} catch (error) {
			dispatch({
				type: LOAD_HOME_SCREEN_WITH_DAYS_FAIL,
				payload: error,
			});
		}
	};

//Load Academy Content
export const getAcademyContent =
	(accessToken: string) => async (dispatch: any) => {
		try {
			dispatch({ type: LOAD_ACADEMY_CONTENT_REQUEST });
			const response = await fetch(`${API_ROOT}api/academy`, {
				method: 'GET',
				headers: {
					authorization: `${accessToken}`,
				},
			});
			const data = await response.json();
			dispatch({
				type: LOAD_ACADEMY_CONTENT_SUCCESS,
				payload: data,
			});
		} catch (error) {
			dispatch({
				type: LOAD_ACADEMY_CONTENT_FAIL,
				payload: error,
			});
		}
	};

//Load Customer Lists
export const getCustomers =
	(accessToken: string, storeId: string, query: string | null | undefined) =>
	async (dispatch: any) => {
		try {
			dispatch({ type: LOAD_CUSTOMERS_REQUEST });
			let response;
			if (query === null || query === undefined) {
				response = await fetch(`${API_ROOT}api/customers?storeId=${storeId}`, {
					method: 'GET',
					headers: {
						authorization: `${accessToken}`,
					},
				});
			} else {
				response = await fetch(
					`${API_ROOT}api/customers?storeId=45278&query=${query}`,
					{
						method: 'GET',
						headers: {
							authorization: `${accessToken}`,
						},
					}
				);
			}
			const data = await response.json();
			dispatch({
				type: LOAD_CUSTOMERS_SUCCESS,
				payload: data,
			});
		} catch (error) {
			dispatch({
				type: LOAD_CUSTOMERS_FAIL,
				payload: error,
			});
		}
	};

//Load Coupons
export const getCoupons = (accessToken: string) => async (dispatch: any) => {
	try {
		dispatch({ type: GET_COUPONS_REQUEST });
		const response = await fetch(`${API_ROOT}partners/coupons`, {
			method: 'GET',
			headers: {
				authorization: `Bearer ${accessToken}`,
			},
		});
		const data = await response.json();
		dispatch({
			type: GET_COUPONS_SUCCESS,
			payload: data,
		});
	} catch (error) {
		dispatch({
			type: GET_COUPONS_FAIL,
			payload: error,
		});
	}
};

//Create Coupon
export const createCoupon =
	(accessToken: string, couponData: any) => async (dispatch: any) => {
		try {
			dispatch({ type: CREATE_COUPON_REQUEST });
			const response = await fetch(`${API_ROOT}partners/coupons`, {
				method: 'POST',
				headers: {
					'content-type': 'application/json',
					authorization: `Bearer ${accessToken}`,
				},
				body: JSON.stringify(couponData),
			});
			const data = await response.json();
			dispatch({
				type: CREATE_COUPON_SUCCESS,
				payload: data,
			});
		} catch (error) {
			dispatch({
				type: CREATE_COUPON_FAIL,
				payload: error,
			});
		}
	};

//Delete Coupon
export const deleteCoupon =
	(accessToken: string, id: string) => async (dispatch: any) => {
		try {
			dispatch({ type: DELETE_COUPON_REQUEST });
			const response = await fetch(`${API_ROOT}partners/coupons/${id}`, {
				method: 'DELETE',
				headers: {
					'content-type': 'application/json',
					authorization: `Bearer ${accessToken}`,
				},
			});
			const data = await response.json();
			dispatch({
				type: DELETE_COUPON_SUCCESS,
				payload: data,
			});
		} catch (error) {
			dispatch({
				type: DELETE_COUPON_FAIL,
				payload: error,
			});
		}
	};

//Load Banner
export const getBanners =
	(accessToken: string, storeId: string) => async (dispatch: any) => {
		try {
			dispatch({ type: GET_BANNERS_REQUEST });
			const response = await fetch(
				`${API_ROOT}api/banners?storeId=${storeId}`,
				{
					method: 'GET',
					headers: {
						authorization: `${accessToken}`,
					},
				}
			);
			const data = await response.json();
			dispatch({
				type: GET_BANNERS_SUCCESS,
				payload: data,
			});
		} catch (error) {
			dispatch({
				type: GET_BANNERS_FAIL,
				payload: error,
			});
		}
	};

//Create Banner
export const createBanner =
	(accessToken: string, storeId: string, bannerData: any) =>
	async (dispatch: any) => {
		try {
			dispatch({ type: CREATE_BANNER_REQUEST });
			const response = await fetch(
				`${API_ROOT}api/banners?storeId=${storeId}`,
				{
					method: 'POST',
					headers: {
						'content-type': 'application/json',
						authorization: `${accessToken}`,
					},
					body: JSON.stringify(bannerData),
				}
			);
			const data = await response.json();
			dispatch({
				type: CREATE_BANNER_SUCCESS,
				payload: data,
			});
		} catch (error) {
			dispatch({
				type: CREATE_BANNER_FAIL,
				payload: error,
			});
		}
	};

//Delete Banner
export const deleteBanner =
	(accessToken: string, storeId: string, bannerId: string) =>
	async (dispatch: any) => {
		try {
			dispatch({ type: DELETE_BANNER_REQUEST });
			const response = await fetch(
				`${API_ROOT}api/banners/${bannerId}?storeId=${storeId}`,
				{
					method: 'DELETE',
					headers: {
						'content-type': 'application/json',
						authorization: `${accessToken}`,
					},
				}
			);
			const data = await response.json();
			dispatch({
				type: DELETE_BANNER_SUCCESS,
				payload: data,
			});
		} catch (error) {
			dispatch({
				type: DELETE_BANNER_FAIL,
				payload: error,
			});
		}
	};

//Get Basic info
export const getBasicInfo =
	(accessToken: string, storeId: string) => async (dispatch: any) => {
		try {
			dispatch({ type: GET_SETTINGS_BASIC_INFO_REQUEST });
			const response = await fetch(
				`${API_ROOT}api/store-settings/${storeId}/basic-about`,
				{
					method: 'GET',
					headers: {
						'content-type': 'application/json',
						authorization: `${accessToken}`,
					},
				}
			);
			const data = await response.json();
			dispatch({
				type: GET_SETTINGS_BASIC_INFO_SUCCESS,
				payload: data,
			});
		} catch (error) {
			dispatch({
				type: GET_SETTINGS_BASIC_INFO_FAIL,
				payload: error,
			});
		}
	};

//Post Basic Information
export const setBasicInfo =
	(accessToken: string, storeId: string, basicInfoData: any) =>
	async (dispatch: any) => {
		try {
			dispatch({ type: SET_SETTINGS_BASIC_INFO_REQUEST });
			const response = await fetch(
				`${API_ROOT}api/store-settings/${storeId}/basic-about`,
				{
					method: 'POST',
					headers: {
						'content-type': 'application/json',
						authorization: `${accessToken}`,
					},
					body: JSON.stringify(basicInfoData),
				}
			);
			const data = await response.json();
			dispatch({
				type: SET_SETTINGS_BASIC_INFO_SUCCESS,
				payload: data,
			});
		} catch (error) {
			dispatch({
				type: SET_SETTINGS_BASIC_INFO_FAIL,
				payload: error,
			});
		}
	};

//Get Service Details
export const getServiceDetails =
	(accessToken: string, storeId: string) => async (dispatch: any) => {
		try {
			dispatch({ type: GET_SETTINGS_SERVICE_DETAILS_REQUEST });
			const response = await fetch(
				`${API_ROOT}api/store-settings/${storeId}/service-details`,
				{
					method: 'GET',
					headers: {
						'content-type': 'application/json',
						authorization: `${accessToken}`,
					},
				}
			);
			const data = await response.json();
			dispatch({
				type: GET_SETTINGS_SERVICE_DETAILS_SUCCESS,
				payload: data,
			});
		} catch (error) {
			dispatch({
				type: GET_SETTINGS_SERVICE_DETAILS_FAIL,
				payload: error,
			});
		}
	};

//Post Service Details
export const setServiceDetails =
	(accessToken: string, storeId: string, serviceDetailsData: any) =>
	async (dispatch: any) => {
		try {
			dispatch({ type: SET_SETTINGS_SERVICE_DETAILS_REQUEST });
			const response = await fetch(
				`${API_ROOT}api/store-settings/${storeId}/service-details`,
				{
					method: 'POST',
					headers: {
						'content-type': 'application/json',
						authorization: `${accessToken}`,
					},
					body: JSON.stringify(serviceDetailsData),
				}
			);
			const data = await response.json();
			dispatch({
				type: SET_SETTINGS_SERVICE_DETAILS_SUCCESS,
				payload: data,
			});
		} catch (error) {
			dispatch({
				type: SET_SETTINGS_SERVICE_DETAILS_FAIL,
				payload: error,
			});
		}
	};

//Get Quick Orders
export const getQuickOrder =
	(accessToken: string, storeId: string) => async (dispatch: any) => {
		try {
			dispatch({ type: GET_SETTINGS_QUICK_ORDERS_REQUEST });
			const response = await fetch(
				`${API_ROOT}api/store-settings/${storeId}/quick-orders`,
				{
					method: 'GET',
					headers: {
						'content-type': 'application/json',
						authorization: `${accessToken}`,
					},
				}
			);
			const data = await response.json();
			dispatch({
				type: GET_SETTINGS_QUICK_ORDERS_SUCCESS,
				payload: data,
			});
		} catch (error) {
			dispatch({
				type: GET_SETTINGS_QUICK_ORDERS_FAIL,
				payload: error,
			});
		}
	};

//Post Quick Order
export const setQuickOrder =
	(accessToken: string, storeId: string, serviceDetailsData: any) =>
	async (dispatch: any) => {
		try {
			dispatch({ type: SET_SETTINGS_QUICK_ORDERS_REQUEST });
			const response = await fetch(
				`${API_ROOT}api/store-settings/${storeId}/quick-orders`,
				{
					method: 'POST',
					headers: {
						'content-type': 'application/json',
						authorization: `${accessToken}`,
					},
					body: JSON.stringify(serviceDetailsData),
				}
			);
			const data = await response.json();
			dispatch({
				type: SET_SETTINGS_QUICK_ORDERS_SUCCESS,
				payload: data,
			});
		} catch (error) {
			dispatch({
				type: SET_SETTINGS_QUICK_ORDERS_FAIL,
				payload: error,
			});
		}
	};

//Get Payments
export const getPayment =
	(accessToken: string, storeId: string) => async (dispatch: any) => {
		try {
			dispatch({ type: GET_SETTINGS_PAYMENTS_REQUEST });
			const response = await fetch(
				`${API_ROOT}api/store-settings/${storeId}/payments`,
				{
					method: 'GET',
					headers: {
						'content-type': 'application/json',
						authorization: `${accessToken}`,
					},
				}
			);
			const data = await response.json();
			dispatch({
				type: GET_SETTINGS_PAYMENTS_SUCCESS,
				payload: data,
			});
		} catch (error) {
			dispatch({
				type: GET_SETTINGS_PAYMENTS_FAIL,
				payload: error,
			});
		}
	};

//Post Payments
export const setPayment =
	(accessToken: string, storeId: string, serviceDetailsData: any) =>
	async (dispatch: any) => {
		try {
			dispatch({ type: SET_SETTINGS_PAYMENTS_REQUEST });
			const response = await fetch(
				`${API_ROOT}api/store-settings/${storeId}/payments`,
				{
					method: 'POST',
					headers: {
						'content-type': 'application/json',
						authorization: `${accessToken}`,
					},
					body: JSON.stringify(serviceDetailsData),
				}
			);
			const data = await response.json();
			dispatch({
				type: SET_SETTINGS_PAYMENTS_SUCCESS,
				payload: data,
			});
		} catch (error) {
			dispatch({
				type: SET_SETTINGS_PAYMENTS_FAIL,
				payload: error,
			});
		}
	};

//Get Localization
export const getLocalization =
	(accessToken: string, storeId: string) => async (dispatch: any) => {
		try {
			dispatch({ type: GET_SETTINGS_LOCALIZATION_REQUEST });
			const response = await fetch(
				`${API_ROOT}api/store-settings/${storeId}/basic-localisation`,
				{
					method: 'GET',
					headers: {
						'content-type': 'application/json',
						authorization: `${accessToken}`,
					},
				}
			);
			const data = await response.json();
			dispatch({
				type: GET_SETTINGS_LOCALIZATION_SUCCESS,
				payload: data,
			});
		} catch (error) {
			dispatch({
				type: GET_SETTINGS_LOCALIZATION_FAIL,
				payload: error,
			});
		}
	};

export const setLocalization =
	(accessToken: string, storeId: string, localizationData: any) =>
	async (dispatch: any) => {
		try {
			dispatch({ type: SET_SETTINGS_LOCALIZATION_REQUEST });
			const response = await fetch(
				`${API_ROOT}api/store-settings/${storeId}/basic-localisation`,
				{
					method: 'POST',
					headers: {
						'content-type': 'application/json',
						authorization: `${accessToken}`,
					},
					body: JSON.stringify(localizationData),
				}
			);
			const data = await response.json();
			dispatch({
				type: SET_SETTINGS_LOCALIZATION_SUCCESS,
				payload: data,
			});
		} catch (error) {
			dispatch({
				type: SET_SETTINGS_LOCALIZATION_FAIL,
				payload: error,
			});
		}
	};

//Load Taxes
export const getTaxes =
	(
		accessToken: string,
		storeId: string,
		lat: string | number,
		long: string | number
	) =>
	async (dispatch: any) => {
		try {
			dispatch({ type: GET_TAXES_REQUEST });
			const response = await fetch(
				`${API_ROOT}api/taxes-charges?storeId=${storeId}&lat=${lat}&long=${long}`,
				{
					method: 'GET',
					headers: {
						authorization: `${accessToken}`,
					},
				}
			);
			const data = await response.json();
			dispatch({
				type: GET_TAXES_SUCCESS,
				payload: data,
			});
		} catch (error) {
			dispatch({
				type: GET_TAXES_FAIL,
				payload: error,
			});
		}
	};

//Create Tax
export const createTax =
	(accessToken: string, taxData: any) => async (dispatch: any) => {
		try {
			dispatch({ type: CREATE_TAX_REQUEST });
			const response = await fetch(`${API_ROOT}api/taxes-charges`, {
				method: 'POST',
				headers: {
					'content-type': 'application/json',
					authorization: `${accessToken}`,
				},
				body: JSON.stringify(taxData),
			});
			const data = await response.json();
			dispatch({
				type: CREATE_TAX_SUCCESS,
				payload: data,
			});
		} catch (error) {
			dispatch({
				type: CREATE_TAX_FAIL,
				payload: error,
			});
		}
	};

//Delete Tax
export const deleteTax =
	(accessToken: string, storeId: string | number, taxId: string | number) =>
	async (dispatch: any) => {
		try {
			dispatch({ type: DELETE_TAX_REQUEST });
			const response = await fetch(
				`${API_ROOT}api/taxes-charges/${taxId}?storeId=${storeId}`,
				{
					method: 'DELETE',
					headers: {
						'content-type': 'application/json',
						authorization: `${accessToken}`,
					},
				}
			);
			const data = await response.json();
			dispatch({
				type: DELETE_TAX_SUCCESS,
				payload: data,
			});
		} catch (error) {
			dispatch({
				type: DELETE_TAX_FAIL,
				payload: error,
			});
		}
	};

//Upload Image
export const uploadImage =
	(imageData: FormData, type: string) => async (dispatch: any) => {
		try {
			dispatch({ type: UPLOAD_IMAGE_REQUEST });
			const response = await fetch(`${API_ROOT}api/upload/${type}`, {
				method: 'POST',
				headers: {
					'content-type': 'multipart/form-data',
				},
				body: imageData,
			});
			const data = await response.json();
			dispatch({
				type: UPLOAD_IMAGE_SUCCESS,
				payload: data,
			});
		} catch (error) {
			dispatch({
				type: UPLOAD_IMAGE_FAIL,
				payload: error,
			});
		}
	};

//clear Errors
export const clearErrors = () => async (dispatch: any) => {
	dispatch({ type: CLEAR_ERRORS });
};

//Load Staff
export const getStaffs =
	(accessToken: string, storeId: string) => async (dispatch: any) => {
		try {
			dispatch({ type: GET_STAFFS_REQUEST });
			const response = await fetch(
				`${API_ROOT}api/stores-staff?storeId=${storeId}`,
				{
					method: 'GET',
					headers: {
						authorization: `${accessToken}`,
					},
				}
			);
			const data = await response.json();
			dispatch({
				type: GET_STAFFS_SUCCESS,
				payload: data,
			});
		} catch (error) {
			dispatch({
				type: GET_STAFFS_FAIL,
				payload: error,
			});
		}
	};

//Create Staff
export const createStaff =
	(accessToken: string, storeId: string, staffData: any) =>
	async (dispatch: any) => {
		try {
			dispatch({ type: CREATE_STAFF_REQUEST });
			const response = await fetch(
				`${API_ROOT}api/stores-staff?storeId=${storeId}`,
				{
					method: 'POST',
					headers: {
						'content-type': 'application/json',
						authorization: `${accessToken}`,
					},
					body: JSON.stringify(staffData),
				}
			);
			const data = await response.json();
			dispatch({
				type: CREATE_STAFF_SUCCESS,
				payload: data,
			});
		} catch (error) {
			dispatch({
				type: CREATE_STAFF_FAIL,
				payload: error,
			});
		}
	};

//Delete staff
export const deleteStaff =
	(accessToken: string, storeId: string | number, staffId: string) =>
	async (dispatch: any) => {
		try {
			dispatch({ type: DELETE_STAFF_REQUEST });
			const response = await fetch(
				`${API_ROOT}api/stores-staff/${staffId}?storeId=${storeId}`,
				{
					method: 'DELETE',
					headers: {
						'content-type': 'application/json',
						authorization: `${accessToken}`,
					},
				}
			);
			const data = await response.json();
			dispatch({
				type: DELETE_STAFF_SUCCESS,
				payload: data,
			});
		} catch (error) {
			dispatch({
				type: DELETE_STAFF_FAIL,
				payload: error,
			});
		}
	};

//Get marketing printables
export const getPrintables =
	(accessToken: string, storeId: string | number) => async (dispatch: any) => {
		try {
			dispatch({ type: GET_PRINTABLES_REQUEST });
			const response = await fetch(
				`${API_ROOT}api/store-marketing/${storeId}/printables`,
				{
					method: 'GET',
					headers: {
						'content-type': 'application/json',
						authorization: `${accessToken}`,
					},
				}
			);
			const data = await response.json();
			dispatch({
				type: GET_PRINTABLES_SUCCESS,
				payload: data,
			});
		} catch (error) {
			dispatch({
				type: GET_PRINTABLES_FAIL,
				payload: error,
			});
		}
	};

//Get Announcements for marketing
export const getAnnouncements =
	(accessToken: string, storeId: string | number) => async (dispatch: any) => {
		try {
			dispatch({ type: GET_ANNOUNCEMENTS_REQUEST });
			const response = await fetch(
				`${API_ROOT}api/announcements-suggestions?storeId=${storeId}`,
				{
					method: 'GET',
					headers: {
						'content-type': 'application/json',
						authorization: `${accessToken}`,
					},
				}
			);
			const data = await response.json();
			dispatch({
				type: GET_ANNOUNCEMENTS_SUCCESS,
				payload: data,
			});
		} catch (error) {
			dispatch({
				type: GET_ANNOUNCEMENTS_FAIL,
				payload: error,
			});
		}
	};

//Create Announcements for marketing
export const createAnnouncements =
	(accessToken: string, announcementData: any) => async (dispatch: any) => {
		try {
			dispatch({ type: CREATE_ANNOUNCEMENT_REQUEST });
			const response = await fetch(`${API_ROOT}api/announcements`, {
				method: 'POST',
				headers: {
					'content-type': 'application/json',
					authorization: `${accessToken}`,
				},
				body: JSON.stringify(announcementData),
			});
			const data = await response.json();
			dispatch({
				type: CREATE_ANNOUNCEMENT_SUCCESS,
				payload: data,
			});
		} catch (error) {
			dispatch({
				type: CREATE_ANNOUNCEMENT_FAIL,
				payload: error,
			});
		}
	};

//Get Orders by id
export const getOrdersById =
	(accessToken: string, orderId: any) => async (dispatch: any) => {
		try {
			dispatch({ type: GET_ORDER_BY_ID_REQUEST });
			const response = await fetch(`${API_ROOT}api/orders/${orderId}`, {
				method: 'GET',
				headers: {
					'content-type': 'application/json',
					authorization: `${accessToken}`,
				},
			});
			const data = await response.json();
			dispatch({
				type: GET_ORDER_BY_ID_SUCCESS,
				payload: data,
			});
		} catch (error) {
			dispatch({
				type: GET_ORDER_BY_ID_FAIL,
				payload: error,
			});
		}
	};

//Get order invoice
export const getInvoice =
	(accessToken: string, orderId: any) => async (dispatch: any) => {
		try {
			dispatch({ type: GET_INVOICE_REQUEST });
			const response = await fetch(
				`${API_ROOT}api/orders-invoices/${orderId}`,
				{
					method: 'GET',
					headers: {
						'content-type': 'application/json',
						authorization: `${accessToken}`,
					},
				}
			);
			const data = await response.json();
			dispatch({
				type: GET_INVOICE_SUCCESS,
				payload: data,
			});
		} catch (error) {
			dispatch({
				type: GET_INVOICE_FAIL,
				payload: error,
			});
		}
	};

//Change order Status
export const changeOrderStatusFunction =
	(
		accessToken: string,
		orderId: string | number,
		newStatus: string,
		message: string
	) =>
	async (dispatch: any) => {
		try {
			dispatch({ type: ORDER_STATUS_CHANGE_REQUEST });
			const response = await fetch(
				`${API_ROOT}/api/orders/change/${orderId}/${newStatus}?message=${message}`,
				{
					method: 'GET',
					headers: {
						'content-type': 'application/json',
						authorization: `${accessToken}`,
					},
				}
			);
			const data = await response.json();
			dispatch({
				type: ORDER_STATUS_CHANGE_SUCCESS,
				payload: data,
			});
		} catch (error) {
			dispatch({
				type: ORDER_STATUS_CHANGE_FAIL,
				payload: error,
			});
		}
	};

//Change order Staff
export const changeOrderStaffFunction =
	(
		accessToken: string,
		orderId: string | number,
		driverId: string | number,
		storeId: string | number
	) =>
	async (dispatch: any) => {
		try {
			dispatch({ type: CHANGE_ORDER_STAFF_REQUEST });
			const response = await fetch(
				`${API_ROOT}/api/orders/assign/${orderId}/${driverId}?storeId=${storeId}`,
				{
					method: 'GET',
					headers: {
						'content-type': 'application/json',
						authorization: `${accessToken}`,
					},
				}
			);
			const data = await response.json();
			dispatch({
				type: CHANGE_ORDER_STAFF_SUCCESS,
				payload: data,
			});
		} catch (error) {
			dispatch({
				type: CHANGE_ORDER_STAFF_FAIL,
				payload: error,
			});
		}
	};

//Get Adjustments
export const getAdjustments =
	(accessToken: string, orderId: string | number) => async (dispatch: any) => {
		try {
			dispatch({ type: GET_ADJUSTMENTS_REQUEST });
			const response = await fetch(
				`${API_ROOT}/api/orders/${orderId}/adjustments`,
				{
					method: 'GET',
					headers: {
						'content-type': 'application/json',
						authorization: `${accessToken}`,
					},
				}
			);
			const data = await response.json();
			dispatch({
				type: GET_ADJUSTMENTS_SUCCESS,
				payload: data,
			});
		} catch (error) {
			dispatch({
				type: GET_ADJUSTMENTS_FAIL,
				payload: error,
			});
		}
	};

//Create Adjustments
export const createAdjustments =
	(accessToken: string, orderId: string | number, adjustmentData: any) =>
	async (dispatch: any) => {
		try {
			dispatch({ type: SET_ADJUSTMENTS_REQUEST });
			const response = await fetch(
				`${API_ROOT}/api/orders/${orderId}/adjustments`,
				{
					method: 'POST',
					headers: {
						'content-type': 'application/json',
						authorization: `${accessToken}`,
					},
					body: JSON.stringify(adjustmentData),
				}
			);
			const data = await response.json();
			dispatch({
				type: SET_ADJUSTMENTS_SUCCESS,
				payload: data,
			});
		} catch (error) {
			dispatch({
				type: SET_ADJUSTMENTS_FAIL,
				payload: error,
			});
		}
	};

//Edit Adjustments
export const editAdjustments =
	(accessToken: string, orderId: string | number, adjustmentData: any) =>
	async (dispatch: any) => {
		try {
			dispatch({ type: EDIT_ADJUSTMENTS_REQUEST });
			const response = await fetch(
				`${API_ROOT}/api/orders/${orderId}/adjustments`,
				{
					method: 'PUT',
					headers: {
						'content-type': 'application/json',
						authorization: `${accessToken}`,
					},
					body: JSON.stringify(adjustmentData),
				}
			);
			const data = await response.json();
			dispatch({
				type: EDIT_ADJUSTMENTS_SUCCESS,
				payload: data,
			});
		} catch (error) {
			dispatch({
				type: EDIT_ADJUSTMENTS_FAIL,
				payload: error,
			});
		}
	};

//Delete Adjustments
export const deleteAdjustments =
	(
		accessToken: string,
		orderId: string | number,
		adjustmentId: string | number
	) =>
	async (dispatch: any) => {
		try {
			dispatch({ type: DELETE_ADJUSTMENTS_REQUEST });
			const response = await fetch(
				`${API_ROOT}/api/orders/${orderId}/adjustments/${adjustmentId}`,
				{
					method: 'DELETE',
					headers: {
						'content-type': 'application/json',
						authorization: `${accessToken}`,
					},
				}
			);
			const data = await response.json();
			dispatch({
				type: DELETE_ADJUSTMENTS_SUCCESS,
				payload: data,
			});
		} catch (error) {
			dispatch({
				type: DELETE_ADJUSTMENTS_FAIL,
				payload: error,
			});
		}
	};

//Get products by sub categories
export const getProductsList =
	(categoryId: string | number, storeId: string | number) =>
	async (dispatch: any) => {
		try {
			dispatch({ type: LOAD_PRODUCTS_BY_SUBCATEGORY_REQUEST });
			const response = await fetch(
				'https://fakestoreapi.com/products',
				// `${API_ROOT}/api/search/products?categoryId=${categoryId}&storeId=${storeId}`,
				{
					method: 'GET',
					headers: {
						'content-type': 'application/json',
					},
				}
			);
			const data = await response.json();
			dispatch({
				type: LOAD_PRODUCTS_BY_SUBCATEGORY_SUCCESS,
				payload: data,
			});
		} catch (error) {
			dispatch({
				type: LOAD_PRODUCTS_BY_SUBCATEGORY_FAIL,
				payload: error,
			});
		}
	};

export const getLandingPages =
	(accessToken: string) => async (dispatch: any) => {
		try {
			dispatch({ type: GET_LANDING_PAGES_REQUEST });
			const response = await fetch(`${API_ROOT}partners/landing-page`, {
				method: 'GET',
				headers: {
					'content-type': 'application/json',
					authorization: `Bearer ${accessToken}`,
				},
			});
			const data = await response.json();
			dispatch({
				type: GET_LANDING_PAGES_SUCCESS,
				payload: data,
			});
		} catch (error) {
			dispatch({
				type: GET_LANDING_PAGES_FAIL,
				payload: error,
			});
		}
	};

export const setLandingPages =
	(accessToken: string, landingPagesData: any) => async (dispatch: any) => {
		try {
			dispatch({ type: SET_LANDING_PAGES_REQUEST });
			const response = await fetch(`${API_ROOT}partners/landing-page`, {
				method: 'PUT',
				headers: {
					'content-type': 'application/json',
					authorization: `Bearer ${accessToken}`,
				},
				body: JSON.stringify(landingPagesData),
			});
			const data = await response.json();
			dispatch({
				type: SET_LANDING_PAGES_SUCCESS,
				payload: data,
			});
		} catch (error) {
			dispatch({
				type: SET_LANDING_PAGES_FAIL,
				payload: error,
			});
		}
	};

export const getSubdomainAvailability =
	(accessToken: string, domain: string) => async (dispatch: any) => {
		try {
			dispatch({ type: GET_SUBDOMAIN_AVAILABILITY_REQUEST });
			const response = await fetch(
				`${API_ROOT}partners/subdomain-availability/${domain}`,
				{
					method: 'GET',
					headers: {
						'content-type': 'application/json',
						authorization: `Bearer ${accessToken}`,
					},
				}
			);
			const data = await response.json();
			dispatch({
				type: GET_SUBDOMAIN_AVAILABILITY_SUCCESS,
				payload: data,
			});
		} catch (error) {
			dispatch({
				type: GET_SUBDOMAIN_AVAILABILITY_FAIL,
				payload: error,
			});
		}
	};

export const getReportsClick =
	(accessToken: string, startDate: string, endDate: string) =>
	async (dispatch: any) => {
		try {
			dispatch({ type: GET_REPORTS_CLICK_REQUEST });
			const response = await fetch(
				`${API_ROOT}partners/report?startDate=${startDate}&endDate=${endDate}`,
				{
					method: 'GET',
					headers: {
						'content-type': 'application/json',
						authorization: `Bearer ${accessToken}`,
					},
				}
			);
			const data = await response.json();
			dispatch({
				type: GET_REPORTS_CLICK_SUCCESS,
				payload: data,
			});
		} catch (error) {
			dispatch({
				type: GET_REPORTS_CLICK_FAIL,
				payload: error,
			});
		}
	};

export const storeClaim =
	(accessToken: string, storeData: any) => async (dispatch: any) => {
		try {
			dispatch({ type: SET_CLAIM_STORE });
			const response = await fetch(`${API_ROOT}partners/stores/claim`, {
				method: 'PUT',
				headers: {
					'content-type': 'application/json',
					authorization: `Bearer ${accessToken}`,
				},
				body: JSON.stringify(storeData),
			});
			const data = await response.json();
			console.log('res', data.message);
			dispatch({
				type: CREATE_CLAIM_SUCCESS,
				payload: data.message,
			});
		} catch (error) {
			dispatch({
				type: CREATE_CLAIM_FAIL,
				payload: error,
			});
		}
	};
