import {
	IonButton,
	IonContent,
	IonGrid,
	IonHeader,
	IonIcon,
	IonPage,
	IonRow,
	IonText,
	IonTitle,
	IonToolbar,
} from '@ionic/react';
import React from 'react';
import { addOutline as addOutlineIcon } from 'ionicons/icons';
import Title from '../../components/dashboard/Header';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

const Finance: React.FC = () => {
	const history = useHistory();
	return (
		<IonPage>
			<Title name="Finance" />
			<IonContent className="ion-padding" scrollY={false}>
				<IonGrid>
					<IonRow className="ion-justify-content-end">
						{/* <IonButton
							shape="round"
							expand="full"
							onClick={() => {
								history.push('/app/v2finance/v2/create/true');
							}}
						>
							<IonIcon icon={addOutlineIcon} color="light" />
							<IonText color="light">Add Report Item v2</IonText>
						</IonButton> */}
						<IonButton
							shape="round"
							expand="full"
							onClick={() => {
								history.push('/app/finance/create/true');
							}}
						>
							<IonIcon icon={addOutlineIcon} color="light" />
							<IonText color="light">Add Report Item</IonText>
						</IonButton>

						{/*<Link to="/app/v2finance/test">
						<IonButton
							shape="round"
							expand="full"
							// onClick={() => {
							// 	history.push('/app/finance/test');
							// }}
						>
							<IonIcon icon={addOutlineIcon} color="light" />
							<IonText color="light">Test</IonText>
						</IonButton></Link> */}
					</IonRow>
				</IonGrid>
				<iframe
					title="Finance Report Form"
					className="ion-padding ion-margin"
					src="https://forms.zohopublic.com/virtualoffice268/report/Finance/reportperma/o6vYvfOKbC41JpXLOEltJHBrXdEYPjBACxyqdq_Ng-4 "
					style={{ width: '100%', height: '100%' }}
					frameBorder="0"
				></iframe>
			</IonContent>
		</IonPage>
	);
};

export default Finance;
