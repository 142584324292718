import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import background from '../../assets/home/background.jpg';
import './SignUpWithEmailOTP.css';
import {
	IonContent,
	IonPage,
	IonItem,
	IonLabel,
	IonButton,
	IonInput,
	IonText,
	IonLoading,
	IonCol,
	IonRow,
	useIonViewDidEnter,
	IonGrid,
	IonHeader,
	IonToolbar,
	IonTitle,
	IonButtons,
	IonBackButton,
	IonToast,
} from '@ionic/react';
import LogoImage from '../../components/auth/LogoImage';
import LoginFooter from '../../components/auth/LoginFooter';
import { useDispatch, useSelector } from 'react-redux';
// import {
// 	getCurrentUser,
// 	getEmailOTP,
// 	getUserTokens,
// } from '../actions/authActions';
import {
	getAccessToken,
	getTokenObject,
	setTokensObject,
} from '../../services/auth/userLocalStorage';
import { isDesktop } from 'react-device-detect';
import {
	authenticateUserWhenLogin,
	getCurrentUser,
	getEmailOTP,
	getUserTokens,
} from '../../actions/authActions';

const SignUpWithEmailOTP = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const [email, setEmail] = useState('');
	const [loading, setLoading] = useState(false);
	const [isCredentialsValidate, setIsCredentialsValidate] = useState(true);
	const [resendButtonDisabledTime, setResendButtonDisabledTime] = useState(0);
	const [isVerifyOTPOpen, setIsVerifyOTPOpen] = useState(false);
	const [otpArray, setOtpArray] = useState(['', '', '', '']);
	const [isOpen, setIsOpen] = useState(false);
	const [message, setMessage] = useState('');
	const firstTextInputRef = useRef(null);
	const secondTextInputRef = useRef(null);
	const thirdTextInputRef = useRef(null);
	const fourthTextInputRef = useRef(null);

	// useEffect(() => {
	// 	if (getAccessToken()) {
	// 		history.push({ pathname: '/app/home' });
	// 	}
	// }, []);
	const { userAPITokensLoading, userAPITokens, userAPITokensError } =
		useSelector((state: any) => state.userAPITokens);
	const { currentUserLoading, currentUser, currentUserError } = useSelector(
		(state: any) => state.currentUser
	);
	const fun = async () => {
		await dispatch(authenticateUserWhenLogin());
		setIsCredentialsValidate(true);
		// console.log(userAPITokens.token);
		// await dispatch(getCurrentUser(getAccessToken()));
		history.replace({ pathname: '/app/home' });
	};

	// console.log(currentUser);
	// console.log(object);
	useEffect(() => {
		if (userAPITokensLoading === false) {
			if (userAPITokens) {
				setTokensObject(userAPITokens);
				setResendButtonDisabledTime(0);
				setOtpArray(['', '', '', '']);
				setIsVerifyOTPOpen(false);
				setEmail('');
				fun();
				// loadCurrentUser(userAPITokens.access_token);
			}
			if (userAPITokensError) {
				setIsCredentialsValidate(false);
				console.log('some error occured in home component user api call.');
				console.log(userAPITokensError);
				console.log(message);
			}
		}
	}, [userAPITokens, userAPITokensError, userAPITokensLoading]);
	useEffect(() => {
		sessionStorage.setItem('reloadCount', String(1));
		// GoogleAuth.init();
	}, []);
	// console.log(otpArray);
	const onOtpChange = index => {
		return e => {
			//console.log(e)
			if (isNaN(Number(e.detail.value))) {
				// do nothing when a non digit is pressed

				return;
			}
			const otpArrayCopy = otpArray.concat();
			otpArrayCopy[index] = e.detail.value;
			setOtpArray(otpArrayCopy);
			if (otpArrayCopy[index] !== '' && index === 0) {
				setTimeout(() => secondTextInputRef.current.setFocus(), 50);
			} else if (otpArrayCopy[index] !== '' && index === 1) {
				setTimeout(() => thirdTextInputRef.current.setFocus(), 50);
			} else if (otpArrayCopy[index] !== '' && index === 2) {
				setTimeout(() => fourthTextInputRef.current.setFocus(), 50);
			}
		};
	};
	let resendOtpTimerInterval;
	const startResendOtpTimer = () => {
		if (resendOtpTimerInterval) {
			clearInterval(resendOtpTimerInterval);
		}
		resendOtpTimerInterval = setInterval(() => {
			if (resendButtonDisabledTime <= 0) {
				clearInterval(resendOtpTimerInterval);
			} else {
				setResendButtonDisabledTime(resendButtonDisabledTime - 1);
			}
		}, 1000);
	};
	const [token, setToken] = useState(getAccessToken());
	useEffect(() => {
		if (token) {
			dispatch(getCurrentUser(token));
		}
	}, []);
	useEffect(() => {
		if (currentUserLoading === false) {
			if (currentUser && currentUser.data) {
				//if(currentUser.data[0].store.storetype_id !== 0)
				// console.log(currentUser);
				// history.push({ pathname: '/app/home' });
				history.replace('/app/home');

				if (resendOtpTimerInterval) {
					clearInterval(resendOtpTimerInterval);
				}
				//else
				//history.push({pathname:'/getbusinessdetails'});
			}
		}
	}, [currentUserLoading, currentUser]);
	useEffect(() => {
		startResendOtpTimer();
		return () => {
			if (resendOtpTimerInterval) {
				clearInterval(resendOtpTimerInterval);
			}
		};
	}, [resendButtonDisabledTime]);

	const { userOTPMessage, isOTPLoading, isOTPSuccess, isError } = useSelector(
		(state: any) => state.otpByEmailMessage
	);
	// console.log(statusCode, userOtpMessage, ' iserror ', isError, );
	// const loadCurrentUser = async (token: string) => {
	// 	await dispatch(getCurrentUser(token));
	// };

	// console.log(userOtpMessage, isOtpLoading);
	const sendOTP = async e => {
		e.preventDefault();
		await dispatch(getEmailOTP(email));
		// setResendButtonDisabledTime(60);
		// setTimeout(() => firstTextInputRef.current.setFocus(), 100);
	};

	// Declare a ref to store the timeout ID
	const timeoutIdRef = useRef(null);

	const setFocusOnFirstTextInput = () => {
		// Store the timeout ID in the ref
		timeoutIdRef.current = setTimeout(
			() => firstTextInputRef.current?.setFocus(),
			100
		);
	};

	useEffect(() => {
		if (isError) {
			setIsOpen(true);
			setMessage(userOTPMessage);
		}
		if (
			isOTPSuccess &&
			!userAPITokensLoading &&
			!userAPITokensError &&
			!userAPITokens
		) {
			setIsOpen(true);
			setMessage(userOTPMessage);
			setResendButtonDisabledTime(60);
			setFocusOnFirstTextInput();
			setIsVerifyOTPOpen(true);
		}
		if (userAPITokensError) {
			setIsOpen(true);
			setMessage(userAPITokensError?.message);
		}
		// Clear the timeout if the component is unmounted
		return () => {
			if (timeoutIdRef.current) {
				clearTimeout(timeoutIdRef.current);
			}
		};
	}, [
		isError,
		isOTPSuccess,
		userOTPMessage,
		userAPITokensError,
		userAPITokensLoading,
		userAPITokens,
	]);

	// console.log(isOpen, ' is open');

	// console.log(userOTPMessage);
	// useEffect(() => {
	// 	console.log('userAPITokensLoading ', userAPITokensLoading);
	// 	if (userAPITokensError) {
	// 		console.log(userAPITokensError);
	// 	}
	// 	if (isOTPSuccess && !userAPITokensLoading) {
	// 		console.log('state changed from 200');
	// 		setIsOpen(true);
	// 		setMessage(userOTPMessage);
	// 		setResendButtonDisabledTime(60);
	// 		setFocusOnFirstTextInput();
	// 		setIsVerifyOTPOpen(true);
	// 	}
	// 	if (isError) {
	// 		console.log('state changed from 400');
	// 		setIsOpen(true);
	// 		setMessage(userOTPMessage);
	// 	}
	// 	if (userAPITokensError) {
	// 		console.log('user api token error found');
	// 		setIsOpen(true);
	// 		setMessage(userAPITokensError?.message);
	// 	}
	// 	// Clear the timeout when the component unmounts or when statusCode changes
	// 	return () => {
	// 		if (timeoutIdRef.current) {
	// 			clearTimeout(timeoutIdRef.current);
	// 		}
	// 	};
	// }, [isOTPSuccess, userOTPMessage, userAPITokensError, userAPITokensLoading]);

	const verifyOTP = async e => {
		e.preventDefault();
		await dispatch(
			getUserTokens({
				username: email,
				password: otpArray.join(''),
				scope: 'admin',
			})
		);
	};

	const firstNameInputRef = useRef<HTMLIonInputElement>(null);
	useIonViewDidEnter(() => {
		firstNameInputRef.current?.setFocus();
	});

	let classOfGrid = '';
	if (isDesktop) {
		classOfGrid = 'center-grid md';
	}
	// console.log('in signupwith email');
	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start">
						<IonBackButton defaultHref="/" />
					</IonButtons>
					<IonTitle>Login With Email</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent className="ion-padding ion-text-center">
				<div
					id="background-content"
					style={{
						left: '0px',
						right: '0px',
						top: '0px',
						bottom: '0px',
						position: 'absolute',
						backgroundImage: `url('${background}')`,
						backgroundRepeat: 'no-repeat',
						backgroundPosition: 'center',
						backgroundSize: 'cover',
						opacity: '0.2',
					}}
				></div>
				<IonGrid className={classOfGrid}>
					<IonRow>
						<IonCol>
							<div className="iklogo">
								<LogoImage />
							</div>
							<form className="ion-padding" onSubmit={sendOTP}>
								<IonItem>
									<IonLabel position="stacked">Email</IonLabel>
									<IonInput
										ref={firstNameInputRef}
										required
										autofocus={true}
										value={email}
										type="email"
										onIonChange={e => setEmail(e.detail.value)}
										placeholder="example@email.com"
										className="ion-text-center"
									/>
								</IonItem>
								{resendButtonDisabledTime ? (
									<p
										className="ion-text-center"
										style={{ color: 'var(--ion-color-primary)' }}
									>
										Resend OTP in <b>{resendButtonDisabledTime}s</b>
									</p>
								) : (
									<IonButton
										disabled={isOTPLoading}
										className="ion-margin-top"
										type="submit"
									>
										<IonText color={'light'}>Send OTP</IonText>
									</IonButton>
								)}
							</form>
							{/* if statuscode 200 and resendButtonDisabledTime is true it will be visible */}
							{isVerifyOTPOpen ? (
								<form onSubmit={verifyOTP}>
									<br />
									<IonLabel>Enter OTP sent on your email</IonLabel>
									<IonRow>
										<IonCol>
											<IonInput
												style={{
													borderBottom: '1px solid',
													fontSize: '25px',
													textAlign: 'center',
												}}
												value={otpArray[0]}
												onIonChange={onOtpChange(0)}
												ref={firstTextInputRef}
												required={true}
												type="tel"
												maxlength={1}
												className="ion-padding-horizontal"
											></IonInput>
										</IonCol>
										<IonCol>
											<IonInput
												style={{
													borderBottom: '1px solid',
													fontSize: '25px',
													textAlign: 'center',
												}}
												value={otpArray[1]}
												onIonChange={onOtpChange(1)}
												ref={secondTextInputRef}
												required={true}
												type="tel"
												maxlength={1}
												className="ion-padding-horizontal"
											></IonInput>
										</IonCol>
										<IonCol>
											<IonInput
												style={{
													borderBottom: '1px solid',
													fontSize: '25px',
													textAlign: 'center',
												}}
												value={otpArray[2]}
												onIonChange={onOtpChange(2)}
												ref={thirdTextInputRef}
												required={true}
												type="tel"
												maxlength={1}
												className="ion-padding-horizontal"
											></IonInput>
										</IonCol>
										<IonCol>
											<IonInput
												style={{
													borderBottom: '1px solid',
													fontSize: '25px',
													textAlign: 'center',
												}}
												value={otpArray[3]}
												onIonChange={onOtpChange(3)}
												ref={fourthTextInputRef}
												required={true}
												type="tel"
												maxlength={1}
												className="ion-padding-horizontal"
											></IonInput>
										</IonCol>
									</IonRow>

									<IonText
										color="danger"
										className={`${
											isCredentialsValidate === true ? 'ion-hide' : ''
										}`}
									>
										<h5
											style={{ fontSize: 12 + 'px', marginTop: 5 + 'px' }}
											className={`ion-margin-horizontal `}
										>
											{' '}
											{currentUserError?.error}
										</h5>
									</IonText>
									<IonButton className="ion-margin-top" type="submit">
										<IonText color={'light'}>Verify Now</IonText>
									</IonButton>
								</form>
							) : (
								<></>
							)}
						</IonCol>
					</IonRow>
				</IonGrid>
				<IonToast
					cssClass={`${isError ? 'toast-error' : 'toast-success'} toast`}
					isOpen={isOpen}
					message={message}
					onDidDismiss={() => {
						setIsOpen(false);
						setMessage('');
					}}
					duration={2000}
					position="top"
				></IonToast>
			</IonContent>
			<IonLoading
				isOpen={loading || userAPITokensLoading || isOTPLoading}
				message={'Please Wait...'}
			/>
		</IonPage>
	);
};

export default SignUpWithEmailOTP;
