import {
    CREATE_FINANCE_FAIL,
    CREATE_FINANCE_REQUEST,
    CREATE_FINANCE_SUCCESS,
    DELETE_FINANCE_FAIL,
    DELETE_FINANCE_REQUEST,
    DELETE_FINANCE_SUCCESS,
    EDIT_FINANCE_FAIL,
    EDIT_FINANCE_REQUEST,
    EDIT_FINANCE_SUCCESS,
    GET_FINANCE_BY_ID_FAIL,
    GET_FINANCE_BY_ID_REQUEST,
    GET_FINANCE_BY_ID_SUCCESS,
    SEARCH_FINANCE_QUERY_FAIL,
    SEARCH_FINANCE_QUERY_REQUEST,
    SEARCH_FINANCE_QUERY_SUCCESS,
    CLEAR_FINANCE_STATE,
    GET_ALL_FINANCE_FAIL,
    GET_ALL_FINANCE_REQUEST,
    GET_ALL_FINANCE_SUCCESS,
    GET_ALL_FINANCE_ALL_FAIL,
    GET_ALL_FINANCE_ALL_REQUEST,
    GET_ALL_FINANCE_ALL_SUCCESS,
} from '../constants/financeConstants'; // Adjust the import to your actual finance constants file

export const loadFinanceByIdReducer = (
    state = { finance: {} },
    action: any
) => {
    switch (action.type) {
        case GET_FINANCE_BY_ID_REQUEST:
            return {
                ...state,
                financeByIdLoading: true,
                financeByIdError: null,
            };
        case GET_FINANCE_BY_ID_SUCCESS:
            return {
                ...state,
                financeByIdLoading: false,
                financeById: action.payload,
            };
        case GET_FINANCE_BY_ID_FAIL:
            return {
                ...state,
                financeByIdLoading: false,
                financeByIdError: action.payload,
            };
        case CLEAR_FINANCE_STATE:
            return state;
        default:
            return state;
    }
};

export const createFinanceReducer = (
    state = { createFinanceMessage: {} },
    action: any
) => {
    switch (action.type) {
        case CREATE_FINANCE_REQUEST:
            return {
                ...state,
                createFinanceLoading: true,
                createFinanceError: null,
            };
        case CREATE_FINANCE_SUCCESS:
            return {
                ...state,
                createFinanceLoading: false,
                createFinanceMessage: action.payload,
            };
        case CREATE_FINANCE_FAIL:
            return {
                ...state,
                createFinanceLoading: false,
                createFinanceError: 'Something Went Wrong',
            };
        case CLEAR_FINANCE_STATE:
            return state;
        default:
            return state;
    }
};

export const updateFinanceReducer = (
    state = { editFinanceMessage: {} },
    action: any
) => {
    switch (action.type) {
        case EDIT_FINANCE_REQUEST:
            return {
                ...state,
                editFinanceLoading: true,
                editFinanceError: null,
            };
        case EDIT_FINANCE_SUCCESS:
            return {
                ...state,
                editFinanceLoading: false,
                editFinanceMessage: action.payload,
            };
        case EDIT_FINANCE_FAIL:
            return {
                ...state,
                editFinanceLoading: false,
                editFinanceError: action.payload,
            };
        case CLEAR_FINANCE_STATE:
            return state;
        default:
            return state;
    }
};

export const getAllFinanceReducer = (
    state = { getAllFinanceMessage: [], getAllFinanceLoading: false },
    action: any
) => {
    switch (action.type) {
        case GET_ALL_FINANCE_REQUEST:
            return {
                ...state,
                getAllFinanceLoading: true,
                getAllFinanceError: null,
            };
        case GET_ALL_FINANCE_SUCCESS:
            return {
                ...state,
                getAllFinanceLoading: false,
                getAllFinanceMessage: action.payload,
            };
        case GET_ALL_FINANCE_FAIL:
            return {
                ...state,
                getAllFinanceLoading: false,
                getAllFinanceError: action.payload,
            };
        case CLEAR_FINANCE_STATE:
            return state;
        default:
            return state;
    }
};


export const getAllFinanceAllReducer = (
    state = { getAllFinanceAllMessage: [], getAllFinanceAllLoading: false },
    action: any
) => {
    switch (action.type) {
        case GET_ALL_FINANCE_ALL_REQUEST:
            return {
                ...state,
                getAllFinanceAllLoading: true,
                getAllFinanceAllError: null,
            };
        case GET_ALL_FINANCE_ALL_SUCCESS:
            return {
                ...state,
                getAllFinanceAllLoading: false,
                getAllFinanceAllMessage: action.payload,
            };
        case GET_ALL_FINANCE_ALL_FAIL:
            return {
                ...state,
                getAllFinanceAllLoading: false,
                getAllFinanceAllError: action.payload,
            };
        case CLEAR_FINANCE_STATE:
            return state;
        default:
            return state;
    }
};

export const deleteFinanceReducer = (
    state = { deleteFinanceMessage: {} },
    action: any
) => {
    switch (action.type) {
        case DELETE_FINANCE_REQUEST:
            return {
                ...state,
                deleteFinanceLoading: true,
                deleteFinanceError: null,
            };
        case DELETE_FINANCE_SUCCESS:
            return {
                ...state,
                deleteFinanceLoading: false,
                deleteFinanceMessage: action.payload,
            };
        case DELETE_FINANCE_FAIL:
            return {
                ...state,
                deleteFinanceLoading: false,
                deleteFinanceError: action.payload,
            };
        case CLEAR_FINANCE_STATE:
            return state;
        default:
            return state;
    }
};

export const searchFinanceQueryReducer = (
    state = { searchFinanceQueryMessage: {} },
    action: any
) => {
    switch (action.type) {
        case SEARCH_FINANCE_QUERY_REQUEST:
            return {
                ...state,
                searchFinanceQueryLoading: true,
                searchFinanceQueryError: null,
            };
        case SEARCH_FINANCE_QUERY_SUCCESS:
            return {
                ...state,
                searchFinanceQueryLoading: false,
                searchFinanceQueryMessage: action.payload,
            };
        case SEARCH_FINANCE_QUERY_FAIL:
            return {
                ...state,
                searchFinanceQueryLoading: false,
                searchFinanceQueryError: action.payload,
            };
        case CLEAR_FINANCE_STATE:
            return state;
        default:
            return state;
    }
};
