import React, { useEffect, useRef, useState } from 'react';
import {
	IonLoading,
	IonPage,
	IonContent,
	IonList,
	IonChip,
	useIonViewWillEnter,
	IonInfiniteScroll,
	IonInfiniteScrollContent,
	IonText,
	IonRefresher,
	IonRefresherContent,
	IonCard,
	IonCardContent,
	IonGrid,
	IonRow,
	IonButton,
	IonIcon,
} from '@ionic/react';

import { getAccessToken } from '../../services/auth/userLocalStorage';
import { useDispatch, useSelector } from 'react-redux';
import ShortInfo from '../../components/order/ShortInfo';
import Title from '../../components/dashboard/Header';
import { getAllOrders } from '../../actions/orderActions';
import CustomerTable from '../../components/customer/CustomerTable';
import OrderTable from '../../components/order/OrderTable';
import { addOutline as addOutlineIcon } from 'ionicons/icons';
import { useHistory } from 'react-router';

const OrdersHome = () => {
	const dispatch = useDispatch();
	const { ordersLoading } = useSelector((state: any) => state.orders);
	const { order: createOrderData } = useSelector(
		(state: any) => state.createAdminOrder
	);

	const history = useHistory();

	useEffect(() => {
		dispatch(getAllOrders(getAccessToken().token));
	}, [dispatch, createOrderData]);

	return (
		<IonPage>
			<div className="ion-page" id="main-content">
				<Title name="Orders" />

				<IonContent className="ion-padding">
					<IonGrid className="ion-padding">
						<IonRow className="ion-justify-content-end">
							<IonButton
								shape="round"
								expand="full"
								onClick={() => history.push('/app/orders/create/true')}
							>
								<IonIcon icon={addOutlineIcon} color="light" size="small" />
								<IonText color={'light'}>Add Order</IonText>
							</IonButton>
						</IonRow>
					</IonGrid>
					<OrderTable />
					<IonLoading isOpen={ordersLoading} message="Please Wait..." />
				</IonContent>
			</div>
		</IonPage>
	);
};

export default OrdersHome;
