export const weightOptionsGm = [
	{ id: 1, label: '100 gm', value: '100 gm' },
	{ id: 2, label: '200 gm', value: '200 gm' },
	{ id: 3, label: '300 gm', value: '300 gm' },
	{ id: 4, label: '400 gm', value: '400 gm' },
	{ id: 5, label: '500 gm', value: '500 gm' },
	{ id: 6, label: '600 gm', value: '600 gm' },
	{ id: 7, label: '700 gm', value: '700 gm' },
	{ id: 8, label: '800 gm', value: '800 gm' },
	{ id: 9, label: '900 gm', value: '900 gm' },
	{ id: 10, label: '1 kg', value: '1 kg' },
	{ id: 11, label: '1 kg 100 gm', value: '1 kg 100 gm' },
	{ id: 12, label: '1 kg 200 gm', value: '1 kg 200 gm' },
	{ id: 13, label: '1 kg 300 gm', value: '1 kg 300 gm' },
	{ id: 14, label: '1 kg 400 gm', value: '1 kg 400 gm' },
	{ id: 15, label: '1 kg 500 gm', value: '1 kg 500 gm' },
	{ id: 16, label: '1 kg 600 gm', value: '1 kg 600 gm' },
	{ id: 17, label: '1 kg 700 gm', value: '1 kg 700 gm' },
	{ id: 18, label: '1 kg 800 gm', value: '1 kg 800 gm' },
	{ id: 19, label: '1 kg 900 gm', value: '1 kg 900 gm' },
	{ id: 20, label: '2 kg', value: '2 kg' },
	{ id: 21, label: '2 kg 100 gm', value: '2 kg 100 gm' },
	{ id: 22, label: '2 kg 200 gm', value: '2 kg 200 gm' },
	{ id: 23, label: '2 kg 300 gm', value: '2 kg 300 gm' },
	{ id: 24, label: '2 kg 400 gm', value: '2 kg 400 gm' },
	{ id: 25, label: '2 kg 500 gm', value: '2 kg 500 gm' },
	{ id: 26, label: '2 kg 600 gm', value: '2 kg 600 gm' },
	{ id: 27, label: '2 kg 700 gm', value: '2 kg 700 gm' },
	{ id: 28, label: '2 kg 800 gm', value: '2 kg 800 gm' },
	{ id: 29, label: '2 kg 900 gm', value: '2 kg 900 gm' },
	{ id: 30, label: '3 kg', value: '3 kg' },
	{ id: 31, label: '3 kg 100 gm', value: '3 kg 100 gm' },
	{ id: 32, label: '3 kg 200 gm', value: '3 kg 200 gm' },
	{ id: 33, label: '3 kg 300 gm', value: '3 kg 300 gm' },
	{ id: 34, label: '3 kg 400 gm', value: '3 kg 400 gm' },
	{ id: 35, label: '3 kg 500 gm', value: '3 kg 500 gm' },
	{ id: 36, label: '3 kg 600 gm', value: '3 kg 600 gm' },
	{ id: 37, label: '3 kg 700 gm', value: '3 kg 700 gm' },
	{ id: 38, label: '3 kg 800 gm', value: '3 kg 800 gm' },
	{ id: 39, label: '3 kg 900 gm', value: '3 kg 900 gm' },
	{ id: 40, label: '4 kg', value: '4 kg' },
	{ id: 41, label: '4 kg 100 gm', value: '4 kg 100 gm' },
	{ id: 42, label: '4 kg 200 gm', value: '4 kg 200 gm' },
	{ id: 43, label: '4 kg 300 gm', value: '4 kg 300 gm' },
	{ id: 44, label: '4 kg 400 gm', value: '4 kg 400 gm' },
	{ id: 45, label: '4 kg 500 gm', value: '4 kg 500 gm' },
	{ id: 46, label: '4 kg 600 gm', value: '4 kg 600 gm' },
	{ id: 47, label: '4 kg 700 gm', value: '4 kg 700 gm' },
	{ id: 48, label: '4 kg 800 gm', value: '4 kg 800 gm' },
	{ id: 49, label: '4 kg 900 gm', value: '4 kg 900 gm' },
	{ id: 50, label: '5 kg', value: '5 kg' },
	{ id: 51, label: '5 kg 100 gm', value: '5 kg 100 gm' },
	{ id: 52, label: '5 kg 200 gm', value: '5 kg 200 gm' },
	{ id: 53, label: '5 kg 300 gm', value: '5 kg 300 gm' },
	{ id: 54, label: '5 kg 400 gm', value: '5 kg 400 gm' },
	{ id: 55, label: '5 kg 500 gm', value: '5 kg 500 gm' },
	{ id: 56, label: '5 kg 600 gm', value: '5 kg 600 gm' },
	{ id: 57, label: '5 kg 700 gm', value: '5 kg 700 gm' },
	{ id: 58, label: '5 kg 800 gm', value: '5 kg 800 gm' },
	{ id: 59, label: '5 kg 900 gm', value: '5 kg 900 gm' },
	{ id: 60, label: '6 kg', value: '6 kg' },
	{ id: 61, label: '6 kg 100 gm', value: '6 kg 100 gm' },
	{ id: 62, label: '6 kg 200 gm', value: '6 kg 200 gm' },
	{ id: 63, label: '6 kg 300 gm', value: '6 kg 300 gm' },
	{ id: 64, label: '6 kg 400 gm', value: '6 kg 400 gm' },
	{ id: 65, label: '6 kg 500 gm', value: '6 kg 500 gm' },
	{ id: 66, label: '6 kg 600 gm', value: '6 kg 600 gm' },
	{ id: 67, label: '6 kg 700 gm', value: '6 kg 700 gm' },
	{ id: 68, label: '6 kg 800 gm', value: '6 kg 800 gm' },
	{ id: 69, label: '6 kg 900 gm', value: '6 kg 900 gm' },
	{ id: 70, label: '7 kg', value: '7 kg' },
	{ id: 71, label: '7 kg 100 gm', value: '7 kg 100 gm' },
	{ id: 72, label: '7 kg 200 gm', value: '7 kg 200 gm' },
	{ id: 73, label: '7 kg 300 gm', value: '7 kg 300 gm' },
	{ id: 74, label: '7 kg 400 gm', value: '7 kg 400 gm' },
	{ id: 75, label: '7 kg 500 gm', value: '7 kg 500 gm' },
	{ id: 76, label: '7 kg 600 gm', value: '7 kg 600 gm' },
	{ id: 77, label: '7 kg 700 gm', value: '7 kg 700 gm' },
	{ id: 78, label: '7 kg 800 gm', value: '7 kg 800 gm' },
	{ id: 79, label: '7 kg 900 gm', value: '7 kg 900 gm' },
	{ id: 80, label: '8 kg', value: '8 kg' },
	{ id: 81, label: '8 kg 100 gm', value: '8 kg 100 gm' },
	{ id: 82, label: '8 kg 200 gm', value: '8 kg 200 gm' },
	{ id: 83, label: '8 kg 300 gm', value: '8 kg 300 gm' },
	{ id: 84, label: '8 kg 400 gm', value: '8 kg 400 gm' },
	{ id: 85, label: '8 kg 500 gm', value: '8 kg 500 gm' },
	{ id: 86, label: '8 kg 600 gm', value: '8 kg 600 gm' },
	{ id: 87, label: '8 kg 700 gm', value: '8 kg 700 gm' },
	{ id: 88, label: '8 kg 800 gm', value: '8 kg 800 gm' },
	{ id: 89, label: '8 kg 900 gm', value: '8 kg 900 gm' },
	{ id: 90, label: '9 kg', value: '9 kg' },
	{ id: 91, label: '9 kg 100 gm', value: '9 kg 100 gm' },
	{ id: 92, label: '9 kg 200 gm', value: '9 kg 200 gm' },
	{ id: 93, label: '9 kg 300 gm', value: '9 kg 300 gm' },
	{ id: 94, label: '9 kg 400 gm', value: '9 kg 400 gm' },
	{ id: 95, label: '9 kg 500 gm', value: '9 kg 500 gm' },
	{ id: 96, label: '9 kg 600 gm', value: '9 kg 600 gm' },
	{ id: 97, label: '9 kg 700 gm', value: '9 kg 700 gm' },
	{ id: 98, label: '9 kg 800 gm', value: '9 kg 800 gm' },
	{ id: 99, label: '9 kg 900 gm', value: '9 kg 900 gm' },
];

export const weightOptionsKg = [
	{ id: 1, label: '100 kg', value: '100' },
	{ id: 2, label: '110 kg', value: '110' },
	{ id: 3, label: '120 kg', value: '120' },
	{ id: 4, label: '130 kg', value: '130' },
	{ id: 5, label: '140 kg', value: '140' },
	{ id: 6, label: '150 kg', value: '150' },
	{ id: 7, label: '160 kg', value: '160' },
	{ id: 8, label: '170 kg', value: '170' },
	{ id: 9, label: '180 kg', value: '180' },
	{ id: 10, label: '190 kg', value: '190' },
	{ id: 11, label: '200 kg', value: '200' },
	{ id: 12, label: '210 kg', value: '210' },
	{ id: 13, label: '220 kg', value: '220' },
	{ id: 14, label: '230 kg', value: '230' },
	{ id: 15, label: '240 kg', value: '240' },
	{ id: 16, label: '250 kg', value: '250' },
	{ id: 17, label: '260 kg', value: '260' },
	{ id: 18, label: '270 kg', value: '270' },
	{ id: 19, label: '280 kg', value: '280' },
	{ id: 20, label: '290 kg', value: '290' },
	{ id: 21, label: '300 kg', value: '300' },
	{ id: 22, label: '310 kg', value: '310' },
	{ id: 23, label: '320 kg', value: '320' },
	{ id: 24, label: '330 kg', value: '330' },
	{ id: 25, label: '340 kg', value: '340' },
	{ id: 26, label: '350 kg', value: '350' },
	{ id: 27, label: '360 kg', value: '360' },
	{ id: 28, label: '370 kg', value: '370' },
	{ id: 29, label: '380 kg', value: '380' },
	{ id: 30, label: '390 kg', value: '390' },
	{ id: 31, label: '400 kg', value: '400' },
	{ id: 32, label: '410 kg', value: '410' },
	{ id: 33, label: '420 kg', value: '420' },
	{ id: 34, label: '430 kg', value: '430' },
	{ id: 35, label: '440 kg', value: '440' },
	{ id: 36, label: '450 kg', value: '450' },
	{ id: 37, label: '460 kg', value: '460' },
	{ id: 38, label: '470 kg', value: '470' },
	{ id: 39, label: '480 kg', value: '480' },
	{ id: 40, label: '490 kg', value: '490' },
	{ id: 41, label: '500 kg', value: '500' },
	{ id: 42, label: '510 kg', value: '510' },
	{ id: 43, label: '520 kg', value: '520' },
	{ id: 44, label: '530 kg', value: '530' },
	{ id: 45, label: '540 kg', value: '540' },
	{ id: 46, label: '550 kg', value: '550' },
	{ id: 47, label: '560 kg', value: '560' },
	{ id: 48, label: '570 kg', value: '570' },
	{ id: 49, label: '580 kg', value: '580' },
	{ id: 50, label: '590 kg', value: '590' },
	{ id: 51, label: '600 kg', value: '600' },
	{ id: 52, label: '610 kg', value: '610' },
	{ id: 53, label: '620 kg', value: '620' },
	{ id: 54, label: '630 kg', value: '630' },
	{ id: 55, label: '640 kg', value: '640' },
	{ id: 56, label: '650 kg', value: '650' },
	{ id: 57, label: '660 kg', value: '660' },
	{ id: 58, label: '670 kg', value: '670' },
	{ id: 59, label: '680 kg', value: '680' },
	{ id: 60, label: '690 kg', value: '690' },
	{ id: 61, label: '700 kg', value: '700' },
	{ id: 62, label: '710 kg', value: '710' },
	{ id: 63, label: '720 kg', value: '720' },
	{ id: 64, label: '730 kg', value: '730' },
	{ id: 65, label: '740 kg', value: '740' },
	{ id: 66, label: '750 kg', value: '750' },
	{ id: 67, label: '760 kg', value: '760' },
	{ id: 68, label: '770 kg', value: '770' },
	{ id: 69, label: '780 kg', value: '780' },
	{ id: 70, label: '790 kg', value: '790' },
	{ id: 71, label: '800 kg', value: '800' },
	{ id: 72, label: '810 kg', value: '810' },
	{ id: 73, label: '820 kg', value: '820' },
	{ id: 74, label: '830 kg', value: '830' },
	{ id: 75, label: '840 kg', value: '840' },
	{ id: 76, label: '850 kg', value: '850' },
	{ id: 77, label: '860 kg', value: '860' },
	{ id: 78, label: '870 kg', value: '870' },
	{ id: 79, label: '880 kg', value: '880' },
	{ id: 80, label: '890 kg', value: '890' },
	{ id: 81, label: '900 kg', value: '900' },
	{ id: 82, label: '910 kg', value: '910' },
	{ id: 83, label: '920 kg', value: '920' },
	{ id: 84, label: '930 kg', value: '930' },
	{ id: 85, label: '940 kg', value: '940' },
	{ id: 86, label: '950 kg', value: '950' },
	{ id: 87, label: '960 kg', value: '960' },
	{ id: 88, label: '970 kg', value: '970' },
	{ id: 89, label: '980 kg', value: '980' },
	{ id: 90, label: '990 kg', value: '990' },
	{ id: 91, label: '1000 kg', value: '1000' },
];

export const hourOptions = [
	{ id: 1, label: '1 hour', value: '1 hour' },
	{ id: 2, label: '2 hours', value: '2 hours' },
	{ id: 3, label: '3 hours', value: '3 hours' },
	{ id: 4, label: '4 hours', value: '4 hours' },
	{ id: 5, label: '5 hours', value: '5 hours' },
	{ id: 6, label: '6 hours', value: '6 hours' },
	{ id: 7, label: '7 hours', value: '7 hours' },
	{ id: 8, label: '8 hours', value: '8 hours' },
	{ id: 9, label: '9 hours', value: '9 hours' },
	{ id: 10, label: '10 hours', value: '10 hours' },
	{ id: 11, label: '11 hours', value: '11 hours' },
	{ id: 12, label: '12 hours', value: '12 hours' },
];

export const electricityUnitsOptions = [
	{ id: 1, label: '15', value: '15' },
	{ id: 2, label: '16', value: '16' },
	{ id: 3, label: '17', value: '17' },
	{ id: 4, label: '18', value: '18' },
	{ id: 5, label: '19', value: '19' },
	{ id: 6, label: '20', value: '20' },
	{ id: 7, label: '21', value: '21' },
	{ id: 8, label: '22', value: '22' },
	{ id: 9, label: '23', value: '23' },
	{ id: 10, label: '24', value: '24' },
	{ id: 11, label: '25', value: '25' },
	{ id: 12, label: '26', value: '26' },
	{ id: 13, label: '27', value: '27' },
	{ id: 14, label: '28', value: '28' },
	{ id: 15, label: '29', value: '29' },
	{ id: 16, label: '30', value: '30' },
	{ id: 17, label: '31', value: '31' },
	{ id: 18, label: '32', value: '32' },
	{ id: 19, label: '33', value: '33' },
	{ id: 20, label: '34', value: '34' },
	{ id: 21, label: '35', value: '35' },
	{ id: 22, label: '36', value: '36' },
	{ id: 23, label: '37', value: '37' },
	{ id: 24, label: '38', value: '38' },
	{ id: 25, label: '39', value: '39' },
	{ id: 26, label: '40', value: '40' },
	{ id: 27, label: '41', value: '41' },
	{ id: 28, label: '42', value: '42' },
	{ id: 29, label: '43', value: '43' },
	{ id: 30, label: '44', value: '44' },
	{ id: 31, label: '45', value: '45' },
	{ id: 32, label: '46', value: '46' },
	{ id: 33, label: '47', value: '47' },
	{ id: 34, label: '48', value: '48' },
	{ id: 35, label: '49', value: '49' },
	{ id: 36, label: '50', value: '50' },
	{ id: 37, label: '51', value: '51' },
	{ id: 38, label: '52', value: '52' },
	{ id: 39, label: '53', value: '53' },
	{ id: 40, label: '54', value: '54' },
	{ id: 41, label: '55', value: '55' },
	{ id: 42, label: '56', value: '56' },
	{ id: 43, label: '57', value: '57' },
	{ id: 44, label: '58', value: '58' },
	{ id: 45, label: '59', value: '59' },
	{ id: 46, label: '60', value: '60' },
	{ id: 47, label: '61', value: '61' },
	{ id: 48, label: '62', value: '62' },
	{ id: 49, label: '63', value: '63' },
	{ id: 50, label: '64', value: '64' },
	{ id: 51, label: '65', value: '65' },
	{ id: 52, label: '66', value: '66' },
	{ id: 53, label: '67', value: '67' },
	{ id: 54, label: '68', value: '68' },
	{ id: 55, label: '69', value: '69' },
	{ id: 56, label: '70', value: '70' },
];
