import {
	IonButtons,
	IonContent,
	IonHeader,
	IonIcon,
	IonPage,
	IonTitle,
	IonToolbar,
} from '@ionic/react';
import React from 'react';
import { useHistory } from 'react-router';
import { arrowBack as arrowBackIcon } from 'ionicons/icons';

const CreateOperation: React.FC = () => {
	const history = useHistory();
	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start">
						<IonIcon
							size="large"
							onClick={e => history.goBack()}
							icon={arrowBackIcon}
							style={{
								cursor: 'pointer',
							}}
						/>
					</IonButtons>
					<IonTitle>Create Operation Report</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent className="ion-padding" scrollY={false}>
				<iframe
					title="Finance Report Form"
					className="ion-padding ion-margin"
					src="https://forms.zohopublic.com/virtualoffice268/form/CLCDailyReport/formperma/aWN79vLLyk7rAGJoK9zVwv4V4NelZA8_KrgGStOFadE"
					style={{ width: '100%', height: '100%' }}
					frameBorder="0"
				></iframe>
			</IonContent>
		</IonPage>
	);
};

export default CreateOperation;
