
import {
	IonButton,
	IonButtons,
	IonCard,
	IonCardContent,
	IonContent,
	IonDatetime,
	IonDatetimeButton,
	IonHeader,
	IonIcon,
	IonLoading,
	IonModal,
	IonPage,
	IonSelect,
	IonSelectOption,
	IonText,
	IonTitle,
	IonToast,
	IonToolbar,
	isPlatform,
} from '@ionic/react';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { arrowBack as arrowBackIcon, cogSharp, trash } from 'ionicons/icons';

import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessage } from '@hookform/error-message';
import {
	IonCol,
	IonGrid,
	IonRow,
	IonItem,
	IonLabel,
	IonInput,
} from '@ionic/react';
import saveFile from '../../utils/SaveFile';
import { Camera, CameraResultType, Photo } from '@capacitor/camera';
import { useDispatch, useSelector } from 'react-redux';
import { createProduct } from '../../actions/productActions';
import { getAccessToken } from '../../services/auth/userLocalStorage';
import { createFinance, getAllFinance, getAllFinanceAll, getFinanceById, updateFinance } from '../../actions/financeActions';
import { formatDate } from '../../services/formatters';
import { Link } from 'react-router-dom';

const categories = [
	{ id: 1, value: 'salary', label: 'Salary' },
	{ id: 2, value: 'chemicals', label: 'Chemicals' },
	{ id: 3, value: 'transport', label: 'Transport' },
	{ id: 4, value: 'biz-dev', label: 'Biz Dev' },
	{ id: 5, value: 'electricity', label: 'Electricity' },
	{ id: 6, value: 'auto-eMI', label: 'Auto EMI' },
	{ id: 7, value: 'gas', label: 'Gas' },
	{ id: 8, value: 'others', label: 'Others' },
];

const types = [
	{ id: 1, value: 'capex', label: 'Capex' },
	{ id: 2, value: 'opex', label: 'Opex' },
];

const expenseHandlers = [
	{ id: 1, value: 'asheesh', label: 'Asheesh' },
	{ id: 2, value: 'pradeep', label: 'Pradeep' },
	{ id: 3, value: 'clc', label: 'CLC' },
];

const FinanceSchema = yup.object().shape({
		// date: yup.string().default('2024-03-22').required('Date is required'),
		date: yup.string().default('2024-03-22'),
	id: yup.number().default(3), // Set default value for id field
	category: yup
		.string()
		.oneOf(
			categories.map(category => category.value),
			'Invalid category'
		)
		.required('Category is required'),
	type: yup
		.string()
		.oneOf(
			types.map(type => type.value),
			'Invalid type'
		)
		.required('Type is required'),
	amount: yup
		.number() // Specify amount field as number
		.typeError('Amount must be a number') // Error message if amount is not a number
		.required('Amount is required'),
	notes: yup.string(),
	reference: yup.string(),
	expense_by: yup
		.string()
		.oneOf(
			expenseHandlers.map(handler => handler.value),
			'Invalid handler'
		)
		.required('This field is required'),
	bill: yup.string(),
});

interface UpdateFinanceProps {
    location: {
        state: {
            finance: {
                id: number;
                category: string;
                type: string;
                expense_by: string;
                notes:string;
                reference:string;
                amount:number;
				date:any;
                // Add more fields as needed
            };
        };
    };
}
type FinanceFormInputs = yup.InferType<typeof FinanceSchema>;

const UpdateFinanceForm: React.FC<UpdateFinanceProps> = (props) => {
	const  params: {id : string}  = useParams();
// console.log(params.id);
	const history = useHistory();
	const [loading, setLoading] = useState(true);
	// const [notuploading, setnotupLoading] = useState(true);

	const [billUrl, setBillUrl] = useState('');
	const [billx, setBillx] = useState('');

	const billInputRef = useRef<HTMLInputElement>();
	const {
		handleSubmit,
		register,
		control,
		setValue,
		formState: { errors },
		reset,
	} = useForm<FinanceFormInputs>({
		resolver: yupResolver(FinanceSchema),
	});
	const { ref: billRef, ...billRest } = register('bill', {});

	const [showToast, setShowToast] = useState(false);
	const [toastMessage, setToastMessage] = useState('');
const dispatch=useDispatch();



// const { createFinanceLoading, createFinanceMessage, createFinanceError } =useSelector((state: any) => state.createFinanceData);

    // const { finance } = props?.location?.state;
//     // console.log(finance);
//    if(params?.id){
// 	setnotupLoading(true);

//    }
const { editFinanceLoading, editFinanceMessage, editFinanceError } =useSelector((state: any) => state.updateFinanceData);
	useEffect(() => {
        const tokenObject = getAccessToken();
		setLoading(true);
        dispatch(getFinanceById(params?.id,tokenObject.token));

        
    }, [dispatch,params?.id]);

	const { financeById,financeByIdLoading,financeByIdError } = useSelector((state: any) => state.financeById);
	const [category,setCategory]= useState();
	const [amount, setAmount] = useState(0); // Initialize amount as a number with a default value of 0

	// Later in your code, when setting the amount state:
	 //	// console.log(financeById);
	 const handleSetAmount = (value) => {
		// Convert the input value to a number
		const numberValue = parseInt(value);
	  
		// Check if the numberValue is a valid number and positive
		if (!isNaN(numberValue) && numberValue >= 0) {
		  // Update the amount state if the value is valid
		  setAmount(numberValue);
		}
	  };




	useEffect(()=>{
	
if(financeById?.id){
	
	// console.log(financeById,"object one");
		setCategory(financeById.category);
		setValue('amount',financeById?.amount);
		setValue('bill', financeById?.bill);
		setBillx(financeById?.bill)
		setBillUrl(financeById?.bill_display_url);
		setValue('category',financeById?.category);
		setValue('notes',financeById?.notes);
		setValue('reference',financeById?.reference);
		setValue('id',financeById?.id);
		setLoading(false);
	}
//   console.log(financeById?.bill_display_url)
//   console.log("bill",financeById?.bill)
//   console.log("bllx",billx)
	},[financeById,financeByIdLoading,category])
	



	// useEffect(() => {
	// 	console.log("bllx", billx);
	//   }, [billx,financeById]);
	const removeFile = () => {
		// setContractFile('');
		setBillUrl('https://api.citylinencare.com/uploads');
		setValue('bill', '');
	};

	const handleBillChange = async (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setLoading(true);
		if (event.target?.files?.length > 0) {
			// Get the first file from the selected files
			const file = event.target.files.item(0);

			// Update the value of the bill input
			const myPlatformPath = await saveFile(file, 'bills');
			setLoading(false);
			// need to uncomment 👇
			setValue('bill', myPlatformPath);
			setBillx(myPlatformPath)
			// console.log('billinfunction', myPlatformPath);
			// console.log("bllx",billx)

			// Generate a URL for the selected file
			const pictureUrl = URL.createObjectURL(file);
			// need to remove 👇
			// setValue('bill', pictureUrl);

			// Set the picture URL
			// setPictureUrl(pictureUrl);
			setBillUrl(prev => pictureUrl);
		}
	};
	/**
	 * Handles the click event of the picture.
	 * If the platform is 'capacitor', opens the camera and sets the picture URL.
	 * Otherwise, triggers a click event on the file input.
	 */
	const handlePictureClick = async (): Promise<void> => {
		// Check if the platform is 'capacitor'
		if (isPlatform('capacitor')) {
			try {
				// Open the camera and get a photo
				const photo: Photo = await Camera.getPhoto({
					resultType: CameraResultType.Uri,
				});
				// console.log(photo.webPath);
				// Set the picture URL to the web path of the photo
				// setPictureUrl(photo.webPath);
				setBillUrl(photo.webPath);
			} catch (error) {
				// Log any errors that occur during the process
				console.log(error);
			}
		} else {
			// If the platform is not 'capacitor', trigger a click event on the file input
			billInputRef.current.click();
		}
	};

	// useEffect(() => {
	// 	setValue('bill', '');
	// }, []);


	
	const onSubmit = data => {
		console.log(data,"after sumbmi");
		 dispatch(updateFinance(data, getAccessToken().token));
		//  console.log(editFinanceMessage);
		 dispatch(getAllFinanceAll(getAccessToken().token));
		 dispatch(getAllFinance(getAccessToken().token,1,"10"));
	};


	useEffect(() => {
		setLoading(false);
		if (editFinanceMessage?.id) {
			setShowToast(true);
			// console.log(editFinanceMessage);
			setToastMessage('Finance Report Updated Successfully');
			// reset();
			history.push('/app/v2finance');
			dispatch(getAllFinanceAll(getAccessToken().token));
			dispatch(getAllFinance(getAccessToken().token,1,"10"));
		
		}
		if (editFinanceError) {
			//if any error happened
			setShowToast(true);
			setToastMessage('Something went wrong');
		}
	}, [editFinanceMessage, editFinanceError]);

	
	  
	function formatDateString(dateString) {
		// Check if dateString is defined and not empty
		if (!dateString) {
		  return null; // Return null if dateString is not provided or empty
		}
	  
		// Replace the second occurrence of '-' with '-0'
		return dateString.replace(/^(.*?-)(\d{2})-/, (match, group1, group2) => {
		  const month = parseInt(group2, 10); // Convert the month part to an integer
		  return `${group1}${month < 10 ? '0' : ''}${month}-`; // Ensure month has leading zero if necessary
		});
	  }	 
	  const formattedDateString = formatDateString(financeById?.date);
	  




	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>

					<IonButtons slot="start">
						{/* <Link to> </Link> */}
						<IonIcon
							size="large"
							onClick={e => {
								history.push('/app/v2finance');
								
							}}
							
							icon={arrowBackIcon}
							style={{
								cursor: 'pointer',
							}}
						/>
					</IonButtons>
					<IonTitle>Update Finance Report</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent className="ion-padding">
				<form
					onSubmit={handleSubmit(onSubmit)}
					className="responsive-width border ion-padding-horizontal"
				>

					<IonGrid>
						<IonRow className="ion-align-items-center ion-justify-content-start">
							<IonCol sizeMd="4">
								<IonItem lines="none">
									<IonLabel>Date</IonLabel>
								</IonItem>
							</IonCol>
							<IonCol>
							<Controller
									name="date"
									control={control}
									defaultValue={financeById?.date}

									render={({ field }) => (

										<IonItem lines="none">
											<IonDatetimeButton datetime="datetime"></IonDatetimeButton>
											<IonModal
												keepContentsMounted={true}
											>
												<IonItem>
													<IonDatetime
														id="datetime"
														presentation="date"
														value={
															field.value
																? formatDate(
																		field.value,
																		'YYYY-MM-DD'
																  )
																: formatDate(
																		// new Date(financeById?.date),
																		new Date(formattedDateString),
																		'YYYY-MM-DD'
																  )
																
														}
														showDefaultButtons={
															true
														}
														onIonChange={e => {
															// console.log(
															// 	e.detail.value
															// );
															field.onChange(
																e.detail.value
															);
														}}
													/>
												</IonItem>
											</IonModal>
										</IonItem>
									)}
								/>
							</IonCol>
						</IonRow>
					</IonGrid>
					<IonItem className="ion-margin-top">
						<IonLabel>Category</IonLabel>
						<Controller
						   defaultValue={financeById?.category}
							render={({ field }) => (
								
								<IonSelect
									placeholder="-Select-"
									value={field.value ? field.value : financeById?.category}
									
									onIonChange={e => {
										field.onChange(e.detail.value);
									}}
								>
									{categories.map(category => (
										<IonSelectOption key={category.id} value={category.value}>
											{category.label}
										</IonSelectOption>
									))}
								</IonSelect>
							)}
							control={control}
							name="category"
						/>
					</IonItem>
					<ErrorMessage
						errors={errors}
						name="category"
						render={({ message }) => (
							<IonText
								color="danger"
								className="ion-margin-horizontal"
								style={{ fontSize: 12 + 'px', marginTop: 0 + 'px' }}
							>
								{message}
							</IonText>
						)}
					/>
					<IonItem className="ion-margin-top">
						<IonLabel>Type</IonLabel>
						<Controller
                        	 defaultValue={financeById?.type}
                             
							render={({ field }) => (
								<IonSelect
									placeholder="-Select-"
									value={field.value ? field.value : financeById?.type}
									onIonChange={e => {
										field.onChange(e.detail.value);
									}}
								>
									{types.map(type => (
										<IonSelectOption key={type.id} value={type.value}>
											{type.label}
										</IonSelectOption>
									))}
								</IonSelect>
							)}
							control={control}
							name="type"
						/>
					</IonItem>
					<ErrorMessage
						errors={errors}
						name="type"
						render={({ message }) => (
							<IonText
								color="danger"
								className="ion-margin-horizontal"
								style={{ fontSize: 12 + 'px', marginTop: 0 + 'px' }}
							>
								{message}
							</IonText>
						)}
					/>
					<IonItem className="ion-margin-top">
						<IonLabel position="stacked">Amount</IonLabel>
						<IonInput placeholder="Enter amount" {...register('amount')} />
					</IonItem>
					<ErrorMessage
						errors={errors}
						name="amount"
						render={({ message }) => (
							<IonText
								color="danger"
								className="ion-margin-horizontal"
								
								style={{ fontSize: 12 + 'px', marginTop: 0 + 'px' }}
							>
								{message}
							</IonText>
						)}
					/>
					<IonItem className="ion-margin-top">
						<IonLabel position="stacked">Notes</IonLabel>
						<IonInput placeholder="Enter notes" {...register('notes')}  value={financeById?.notes}/>
					</IonItem>
					<IonItem className="ion-margin-top">
						<IonLabel position="stacked">Reference</IonLabel>
						<IonInput
							placeholder="Enter reference"
							{...register('reference')}
                            value={financeById?.reference}
						/>
					</IonItem>
					<IonItem className="ion-margin-top">
						<IonLabel>Expense By</IonLabel>
						<Controller
                        defaultValue={financeById?.expense_by}
							render={({ field }) => (
								<IonSelect
									placeholder="-Select-"
									value={field.value ? field.value : financeById?.expense_by}
									onIonChange={e => {
										field.onChange(e.detail.value);
									}}
								>
									{expenseHandlers.map(expenseHandler => (
										<IonSelectOption
											key={expenseHandler.id}
											value={expenseHandler.value}
										>
											{expenseHandler.label}
										</IonSelectOption>
									))}
								</IonSelect>
							)}
							control={control}
							name="expense_by"
						/>
					</IonItem>
					<ErrorMessage
						errors={errors}
						name="expense_by"
						render={({ message }) => (
							<IonText
								color="danger"
								className="ion-margin-horizontal"
								style={{ fontSize: 12 + 'px', marginTop: 0 + 'px' }}
							>
								{message}
							</IonText>
						)}
					/>
					<IonRow className="ion-align-items-center ion-margin-horizontal ion-margin-bottom ion-margin-top">
						<IonCol sizeMd="4" sizeSm="6">
							<IonLabel position="stacked">Bill</IonLabel>
						</IonCol>
						<IonCol sizeMd="3" sizeSm="6">
							<input
								type="file"
								hidden
								accept="image/*"
								{...billRest}
								ref={e => {
									billRef(e);
									billInputRef.current = e;
								}}
								onChange={handleBillChange}
							/>
							<IonButton
								size="small"
								shape="round"
								onClick={handlePictureClick}
							>
								<IonText color={'light'}>
									{(billUrl != 'https://api.citylinencare.com/uploads')  ? 'Change Bill' : 'Upload Bill'}
								</IonText>
							</IonButton>
						</IonCol>
						<IonCol sizeMd="5" sizeSm="12" sizeXs="12">
							{(billUrl != 'https://api.citylinencare.com/uploads') ? (
								<IonCard style={{ width: '80%', margin: 'auto' }}>
									<IonCardContent>
										<img src={billUrl} alt="" width={'90%'} />
										<IonIcon
											color="danger"
											icon={trash}
											size="small"
											onClick={removeFile}
											className="ion-float-right"
											style={{ cursor: 'pointer' }}
										></IonIcon>
									</IonCardContent>
								</IonCard>

							) : (
								<></>
							)
					}
						</IonCol>
					</IonRow>
					<IonGrid className="ion-text-center ion-margin-vertical">
						<IonButton shape="round" type="submit">
							<IonText color={'light'}>Submit</IonText>
						</IonButton>
					</IonGrid>
				</form>
				<IonToast
					isOpen={showToast}
					message={toastMessage}
					onDidDismiss={() => {
						setToastMessage('');
						setShowToast(false);
					}}
					duration={2000}
					position="top"
				></IonToast>
				<IonLoading isOpen={loading || editFinanceLoading || financeByIdLoading } message="Please Wait.." />
				{/* <IonLoading isOpen={notuploading} message="Please Wait.." /> */}

				
				
			</IonContent>
		</IonPage>
	);
};

export default UpdateFinanceForm;
