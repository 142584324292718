import { CLEAR_ERRORS } from '../constants/authConstants';
import {
	CREATE_ADMIN_ORDER_FAIL,
	CREATE_ADMIN_ORDER_REQUEST,
	CREATE_ADMIN_ORDER_SUCCESS,
	GET_BUSINESSES_FAIL,
	GET_BUSINESSES_PRODUCT_FAIL,
	GET_BUSINESSES_PRODUCT_REQUEST,
	GET_BUSINESSES_PRODUCT_SUCCESS,
	GET_BUSINESSES_REQUEST,
	GET_BUSINESSES_SUCCESS,
	GET_ORDERS_FAIL,
	GET_ORDERS_REQUEST,
	GET_ORDERS_SUCCESS,
} from '../constants/orderConstants';

//orders
export const loadOrdersReducer = (state = { orders: {} }, action: any) => {
	switch (action.type) {
		case GET_ORDERS_REQUEST:
			return {
				...state,
				ordersLoading: true,
				ordersError: null,
			};
		case GET_ORDERS_SUCCESS:
			return {
				...state,
				ordersLoading: false,
				orders: action.payload,
			};
		case GET_ORDERS_FAIL:
			return {
				...state,
				ordersLoading: false,
				ordersError: action.payload,
			};
		case CLEAR_ERRORS:
			return {
				...state,
				error: null,
			};
		default:
			return state;
	}
};

// get businesses to create order
export const getAllBusinessReducer = (
	state = { businesses: [] },
	action: any
) => {
	switch (action.type) {
		case GET_BUSINESSES_REQUEST:
			return {
				...state,
				loading: true,
				error: null,
			};
		case GET_BUSINESSES_SUCCESS:
			return {
				...state,
				loading: false,
				businesses: action.payload,
			};
		case GET_BUSINESSES_FAIL:
			return {
				...state,
				loading: false,
				error: action.payload,
			};
		default:
			return state;
	}
};

// get products by business Id
export const getProductsByBusinessIdReducer = (
	state = { products: [] },
	action: any
) => {
	switch (action.type) {
		case GET_BUSINESSES_PRODUCT_REQUEST:
			return {
				...state,
				loading: true,
				error: null,
			};
		case GET_BUSINESSES_PRODUCT_SUCCESS:
			return {
				...state,
				loading: false,
				products: action.payload,
			};
		case GET_BUSINESSES_PRODUCT_FAIL:
			return {
				...state,
				loading: false,
				error: action.payload,
			};
		default:
			return state;
	}
};

// create admin order
export const createAdminOrderReducer = (state = { order: {} }, action: any) => {
	switch (action.type) {
		case CREATE_ADMIN_ORDER_REQUEST:
			return {
				...state,
				loading: true,
				error: null,
			};
		case CREATE_ADMIN_ORDER_SUCCESS:
			return {
				...state,
				loading: false,
				order: action.payload,
			};
		case CREATE_ADMIN_ORDER_FAIL:
			return {
				...state,
				loading: false,
				error: action.payload,
			};
		default:
			return state;
	}
};
