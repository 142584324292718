import axios from 'axios';
import { API_ROOT } from '../constants/global';

async function saveFile(file, type) {
	if (!file) return '';
	const formData = new FormData();
	formData.append('file', file);
	try {
		const response = await axios.post(
			`${API_ROOT}upload/${type}`,
			formData
			// {
			// 	onUploadProgress: progressEvent => {
			// 		const { loaded, total } = progressEvent;
			// 		console.log(loaded, total);
			// 		let percent = Math.floor((loaded * 100) / total);
			// 		console.log(percent);
			// 		setProgress(percent);
			// 	},
			// }
			// {
			// 	headers: {
			// 		'Content-Type': 'multipart/form-data',
			// 	},
			// }
		);
		// console.log(response.data.platform_path);
		return response.data.platform_path;
		// const response = await fetch(`${API_ROOT}upload/${type}`, {
		// 	method: 'POST',
		// 	body: formData,
		// });
		// const data = await response.json();
		// return data.platform_path;
	} catch (error) {
		return '';
	}
}

export default saveFile;
