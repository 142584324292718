import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IonButton, IonButtons, IonCol, IonContent, IonFooter, IonGrid, IonHeader, IonIcon, IonItem, IonLoading, IonPage, IonRow, IonSelect, IonSelectOption, IonText, IonTitle, IonToolbar } from '@ionic/react';
import { getAllFinance, getAllFinanceAll } from '../../actions/financeActions';
import { getAccessToken } from '../../services/auth/userLocalStorage';
import FinanceList from '../Finance/FinanceList';
import { arrowBack as arrowBackIcon } from 'ionicons/icons';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { addOutline as addOutlineIcon } from 'ionicons/icons';
import {
	arrowForwardOutline as rightArrowIcon,
	arrowBackOutline as leftArrowIcon,
	link,
	informationCircle,
	closeOutline as closeOutlineIcon,
	eyedropOutline,
	create,
	trashBin,
	trash,
} from 'ionicons/icons';
import Testing from './Testing';

const Test: React.FC = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [perpage, setPerPage] = useState("10");
    const [pageno, setPageNo] = useState(1); // Change pageno to number type
    const [isNextDisabled, setIsNextDisabled] = useState(false); // State to control Next button disabled state
    const [isPrevDisabled, setIsPrevDisabled] = useState(true); // State to control Prev button disabled state
const [financelengthzero,setFinanceLengthZero]=useState(false)
    // Function to handle the change in IonSelect
    const handlePerPageChange = (event: CustomEvent) => {
        const selectedValue = event.detail.value;
        setPerPage(selectedValue);
    };
   

    useEffect(() => {
        const tokenObject = getAccessToken();
        dispatch(getAllFinance(tokenObject.token, pageno, perpage));

        dispatch(getAllFinanceAll(tokenObject.token))

        
    }, [dispatch, pageno, perpage]);
 useEffect(() => {
    setPageNo(1);
 },[perpage])
   
    const { getAllFinanceMessage: finances, getAllFinanceLoading: loading, getAllFinanceError: error } = useSelector((state: any) => state.getAllFinance);

    const { getAllFinanceAllMessage: financesAll, getAllFinanceAllLoading: loadingAll, getAllFinanceAllError: errorAll } = useSelector((state: any) => state.getAllFinanceAll);

    // console.log(financesAll.length,"this is all");
    useEffect(()=>{
        const x= parseInt(perpage)*pageno;
        // console.log(x,"it x");
        if(x >= financesAll.length){
          setIsNextDisabled(true);
        //   setFinanceLengthZero(true);a
        }
        else{
            setIsNextDisabled(false);
        }

        if(pageno <= 1){
            setIsPrevDisabled(true);
        }
        else{
            setIsPrevDisabled(false);
        }
        
},[pageno,perpage,dispatch,finances,financesAll])
    const handleNextClick = () => {
        setPageNo(prevPageNo => prevPageNo + 1);
    };

    const handlePrevClick = () => {
        setPageNo(prevPageNo => prevPageNo - 1);
        
    };

    // if (loading) {
    //     return <div>Loading...</div>;
    // }

    if (error) {
        return <div>Error: {error}</div>;
    }
    // console.log(finances);
  

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonIcon
                            size="large"
                            onClick={e => history.goBack()}
                            icon={arrowBackIcon}
                            style={{
                                cursor: 'pointer',
                            }}
                        />
                    </IonButtons>
                    <IonTitle>Finance Page</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
            <IonGrid className='ion-padding'>
					<IonRow className="ion-justify-content-center ">
                    <IonCol className="ion-hide-md-down" sizeMd="7"></IonCol>
						<IonButton
							shape="round"
							expand="full"
							onClick={() => {
								history.push('/app/v2finance/v2/create/true');
							}}
						>
							<IonIcon icon={addOutlineIcon} color="light" />
							<IonText color="light">Add Report Item</IonText>
						</IonButton>
						{/* <IonButton
							shape="round"
							expand="full"
							onClick={() => {
								history.push('/app/finance/true');
							}}
						>
							<IonIcon icon={addOutlineIcon} color="light" />
							<IonText color="light">Add Report Item</IonText>
						</IonButton> */}

						{/* <Link to="/app/finance/create/test/true">
						<IonButton
							shape="round"
							expand="full"
							// onClick={() => {
							// 	history.push('/app/finance/test');
							// }}
						>
							<IonIcon icon={addOutlineIcon} color="light" />
							<IonText color="light">Test</IonText>
						</IonButton></Link> */}
					</IonRow>
				</IonGrid>
        {/* <FinanceList finances={finances} /> */}
        <Testing finances={finances} />
   <div style={{paddingLeft:"20px"}}>
   <IonFooter className="ion-margin-top ion-padding ">
				<IonGrid className=" ">
    <IonRow style={{justifyContent:"space-between"}}  className='ion-no-padding responsive-width' >
    
    <IonButton   className='ion-text-left '
										fill="outline"
										onClick={handlePrevClick}
                                        disabled={isPrevDisabled}
									>
										<IonIcon
											slot="start"
											size="medium"
											icon={leftArrowIcon}
										></IonIcon>
										Previous
									</IonButton>
    <IonItem lines='none' className='ion-hide-md-down'>
                    <IonSelect placeholder="-Select-" value={perpage} onIonChange={handlePerPageChange} >
                        <IonSelectOption value="10">10 Records per Page</IonSelectOption>
                        <IonSelectOption value="5">5 Records per Page</IonSelectOption>
                        <IonSelectOption value="20">20 Records per Page</IonSelectOption>
                        <IonSelectOption value="50">50 Records per Page</IonSelectOption>
                    </IonSelect>
                </IonItem>
                

              
                                    <IonButton className='ion-text-right'
										fill="outline"
                                        
										onClick={handleNextClick}  disabled={isNextDisabled}
									>
										
										Next
                                        <IonIcon
											slot="end"
											size="medium"
											icon={rightArrowIcon}
										></IonIcon>
									</IonButton>
                    
    </IonRow>
</IonGrid>
</IonFooter>
    </div>             

               
    {/* <IonLoading isOpen={loading} message="Uploading.." /> */}
				<IonLoading
					isOpen={loading}
					message="Please Wait.."
				/>   
            </IonContent>
        </IonPage>
    );
};

export default Test;
