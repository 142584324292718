export const GET_FINANCE_BY_ID_REQUEST = 'GET_FINANCE_BY_ID_REQUEST';
export const GET_FINANCE_BY_ID_SUCCESS = 'GET_FINANCE_BY_ID_SUCCESS';
export const GET_FINANCE_BY_ID_FAIL = 'GET_FINANCE_BY_ID_FAIL';

export const CREATE_FINANCE_REQUEST = 'CREATE_FINANCE_REQUEST';
export const CREATE_FINANCE_SUCCESS = 'CREATE_FINANCE_SUCCESS';
export const CREATE_FINANCE_FAIL = 'CREATE_FINANCE_FAIL';

export const EDIT_FINANCE_REQUEST = 'EDIT_FINANCE_REQUEST';
export const EDIT_FINANCE_SUCCESS = 'EDIT_FINANCE_SUCCESS';
export const EDIT_FINANCE_FAIL = 'EDIT_FINANCE_FAIL';

export const DELETE_FINANCE_REQUEST = 'DELETE_FINANCE_REQUEST';
export const DELETE_FINANCE_SUCCESS = 'DELETE_FINANCE_SUCCESS';
export const DELETE_FINANCE_FAIL = 'DELETE_FINANCE_FAIL';

export const GET_ALL_FINANCE_REQUEST = 'GET_ALL_FINANCE_REQUEST';
export const GET_ALL_FINANCE_SUCCESS = 'GET_ALL_FINANCE_SUCCESS';
export const GET_ALL_FINANCE_FAIL = 'GET_ALL_FINANCE_FAIL';


export const GET_ALL_FINANCE_ALL_REQUEST = 'GET_ALL_FINANCE_ALL_REQUEST';
export const GET_ALL_FINANCE_ALL_SUCCESS = 'GET_ALL_FINANCE_ALL_SUCCESS';
export const GET_ALL_FINANCE_ALL_FAIL = 'GET_ALL_FINANCE_ALL_FAIL';

export const SEARCH_FINANCE_QUERY_REQUEST = 'SEARCH_FINANCE_QUERY_REQUEST';
export const SEARCH_FINANCE_QUERY_SUCCESS = 'SEARCH_FINANCE_QUERY_SUCCESS';
export const SEARCH_FINANCE_QUERY_FAIL = 'SEARCH_FINANCE_QUERY_FAIL';

export const CLEAR_FINANCE_STATE = 'CLEAR_FINANCE_STATE';
