import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import IntelikartDashboardLogo from '../../images/IntelikartDashboardLogo.png';
import { RouteComponentProps, withRouter, useLocation } from 'react-router';
import {
	IonMenu,
	IonHeader,
	IonIcon,
	IonImg,
	IonToolbar,
	IonTitle,
	IonContent,
	IonList,
	IonItem,
	IonLabel,
	IonMenuToggle,
	IonListHeader,
	IonAlert,
} from '@ionic/react';
import {
	homeOutline,
	logOutOutline,
	pricetagOutline,
	settingsOutline,
	storefrontOutline,
	chatbubbleEllipsesOutline,
	rocketOutline,
	thumbsUpOutline,
	megaphoneOutline,
	albumsOutline,
	readerOutline,
	fileTrayOutline,
	locationOutline,
	peopleOutline,
	listOutline,
	cashOutline,
} from 'ionicons/icons';
import './Menu.css';

// import { getAccessToken } from '../../services/auth/userLocalStorage';
import LogoImage from '../auth/LogoImage';
import { getAccessToken } from '../../services/auth/userLocalStorage';
import { trace } from 'console';
interface Pages {
	title: string;
	path: string;
	icon: string;
	routerDirection?: string;
}
const DashboardMenu = () => {
	// const { currentUser } = useSelector((state: any) => state.currentUser);
	const [showAlert, setShowAlert] = useState(false);
	const location = useLocation();
	const history = useHistory();
	const token = getAccessToken();
	// console.log('inDash');
	const routes = {
		// appPages: [
		//   { title: 'Home', path: '/my/userdashboard', icon: homeOutline },
		//   { title: 'Customers', path: '/my/customer', icon: peopleOutline },
		//   { title: 'Catalogue', path: '/my/catalogue', icon: appsSharp },
		//   { title: 'Orders', path: '/my/orders', icon: listOutline },
		//   { title: 'Coupons', path: '/my/coupons', icon: pricetagOutline },
		//   { title: 'Banners', path: '/my/banners', icon: imageOutline },
		//   { title: 'Marketing', path: '/my/marketing', icon: printOutline },
		//   { title: 'Staff', path: '/my/staffs', icon: peopleOutline }
		// ]
		// ,
		// loggedInPages: [
		//   { title: 'Subscriptions', path: '/my/subscriptions', icon: personOutline },
		//   { title: 'Settings', path: '/my/settings', icon: settingsOutline },
		//   { title: 'IK Academy', path: '/my/ikacademy', icon: schoolOutline },
		//   { title: 'Help & Support', path: '/my/help&support', icon: helpCircleOutline }
		// ]
		// ,
		// loggedOutPages: [{ title: 'Logout', path: '/logout', icon: logOutOutline }],

		// added
		appPages: [
			{ title: 'Home', path: '/app/home', icon: homeOutline },
			// { title: 'Leads', path: '/my/leads', icon: thumbsUpOutline },
			{ title: 'Products', path: '/app/products', icon: fileTrayOutline },
			{ title: 'Customers', path: '/app/customers', icon: peopleOutline },
			{ title: 'Orders', path: '/app/orders', icon: listOutline },
			{ title: 'Operations', path: '/app/operations', icon: settingsOutline },
			{ title: 'Finance', path: '/app/finance', icon: cashOutline },
			{ title: 'Finance V2', path: '/app/v2finance', icon: cashOutline },


			// {
			// 	title: 'Store Details',
			// 	path: '/app/stores/store-details/123',
			// 	icon: peopleOutline,
			// },
			// { title: 'Stores', path: '/app/stores', icon: peopleOutline },
			// { title: 'Taxes', path: '/app/taxes', icon: storefrontOutline },
			// {
			// 	title: 'Create Leads',
			// 	path: '/app/createleads/123',
			// 	icon: storefrontOutline,
			// },
			// {
			// 	title: 'localization',
			// 	path: '/app/localization',
			// 	icon: locationOutline,
			// },
			// { title: 'Coupons', path: '/my/coupons', icon: pricetagOutline },
			// {
			// 	title: 'Landing Pages',
			// 	path: '/app/landingpages',
			// 	icon: albumsOutline,
			// },
			// { title: 'Marketing', path: '/my/marketing', icon: rocketOutline },
			// { title: 'Branding', path: '/my/branding', icon: megaphoneOutline },
			// {
			// 	title: 'Support',
			// 	path: '/my/support',
			// 	icon: chatbubbleEllipsesOutline,
			// },
			// { title: 'Reports', path: '/my/reports', icon: readerOutline },
			// { title: 'Reports', path: '/app/reports', icon: readerOutline },
			// { title: 'Settings', path: '/my/settings', icon: settingsOutline },
		],
	};

	function renderListItems(list: Pages[]) {
		return list
			.filter(route => !!route.path)
			.map(p => {
				return (
					<IonMenuToggle key={p.title} auto-hide="false">
						<IonItem
							detail={false}
							routerLink={p.path}
							routerDirection="none"
							className={
								location.pathname.includes(p.path) ? 'selected' : undefined
							}
						>
							<IonIcon slot="start" icon={p.icon} />
							<IonLabel>{p.title}</IonLabel>
						</IonItem>
					</IonMenuToggle>
				);
			});
	}
	return (
		<IonMenu type="overlay" contentId="main" class="sidemenu">
			<IonHeader>
				<IonToolbar>
					<IonTitle>
						<IonItem lines="none" detail={false}>
							<LogoImage width="100" />
						</IonItem>
					</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				<IonList lines="none">{renderListItems(routes.appPages)}</IonList>
				<IonList lines="none">
					<IonListHeader>Others</IonListHeader>
					<IonMenuToggle key="LOGOUT" auto-hide="false">
						<IonItem
							detail={false}
							onClick={e => setShowAlert(true)}
							style={{ cursor: 'pointer' }}
							routerDirection="none"
							className={
								location.pathname.startsWith('/logout') ? 'selected' : undefined
							}
						>
							<IonIcon slot="start" icon={logOutOutline} />
							<IonLabel>Logout</IonLabel>
						</IonItem>
					</IonMenuToggle>
				</IonList>
			</IonContent>
			<IonAlert
				isOpen={showAlert === true}
				onDidDismiss={() => setShowAlert(false)}
				header={'Confirm Log Out'}
				message={`Are you sure you want to log out?`}
				buttons={[
					{
						text: 'NO',
						role: 'cancel',
					},
					{
						text: 'YES',
						handler: () => {
							history.push('/logout');
						},
					},
				]}
			/>
		</IonMenu>
	);
};

export default DashboardMenu;
