import { API_ROOT } from '../constants/global';
import {
	CREATE_ADMIN_ORDER_FAIL,
	CREATE_ADMIN_ORDER_REQUEST,
	CREATE_ADMIN_ORDER_SUCCESS,
	GET_BUSINESSES_FAIL,
	GET_BUSINESSES_PRODUCT_FAIL,
	GET_BUSINESSES_PRODUCT_REQUEST,
	GET_BUSINESSES_PRODUCT_SUCCESS,
	GET_BUSINESSES_REQUEST,
	GET_BUSINESSES_SUCCESS,
	GET_ORDERS_FAIL,
	GET_ORDERS_REQUEST,
	GET_ORDERS_SUCCESS,
} from '../constants/orderConstants';

export const getAllOrders = (accessToken: string) => async (dispatch: any) => {
	try {
		dispatch({ type: GET_ORDERS_REQUEST });
		const response = await fetch(
			// `${API_ROOT}api/orders/stores/${orderStatus}?page=1&per_page=10`,
			`${API_ROOT}orders`,
			{
				method: 'GET',
				headers: {
					'content-type': 'application/json',
					authorization: `Bearer ${accessToken}`,
				},
			}
		);
		const data = await response.json();
		// console.log(data.data);
		if (response.status === 200 && data.data) {
			dispatch({
				type: GET_ORDERS_SUCCESS,
				payload: data.data,
			});
		} else {
			// console.log(response);
			dispatch({
				type: GET_ORDERS_FAIL,
				payload: data,
			});
		}
	} catch (error) {
		dispatch({
			type: GET_ORDERS_FAIL,
			payload: error,
		});
	}
};

// get businesses to create order
export const getALLBusiness =
	(accessToken: string) => async (dispatch: any) => {
		try {
			dispatch({ type: GET_BUSINESSES_REQUEST });
			const response = await fetch(`${API_ROOT}order-init/businesses`, {
				method: 'GET',
				headers: {
					'content-type': 'application/json',
					authorization: `Bearer ${accessToken}`,
				},
			});
			const data = await response.json();
			if (response.status === 200 && data.data) {
				dispatch({
					type: GET_BUSINESSES_SUCCESS,
					payload: data.data,
				});
			} else {
				dispatch({
					type: GET_BUSINESSES_FAIL,
					payload: data,
				});
			}
		} catch (error) {
			dispatch({
				type: GET_BUSINESSES_FAIL,
				payload: error,
			});
		}
	};

// get products based on business Id
export const getProductsByBusinessId =
	(accessToken: string, id: number) => async (dispatch: any) => {
		try {
			dispatch({ type: GET_BUSINESSES_PRODUCT_REQUEST });
			const response = await fetch(
				`${API_ROOT}order-init/products?businessId=${id}`,
				{
					method: 'GET',
					headers: {
						'content-type': 'application/json',
						authorization: `Bearer ${accessToken}`,
					},
				}
			);
			const data = await response.json();
			if (response.status === 200 && data.data) {
				dispatch({
					type: GET_BUSINESSES_PRODUCT_SUCCESS,
					payload: data.data,
				});
			} else {
				dispatch({
					type: GET_BUSINESSES_PRODUCT_FAIL,
					payload: data,
				});
			}
		} catch (error) {
			dispatch({
				type: GET_BUSINESSES_PRODUCT_FAIL,
				payload: error.message,
			});
		}
	};

// admin order creation
export const createAdminOrder =
	(accessToken: string, orderData: any) => async (dispatch: any) => {
		try {
			dispatch({ type: CREATE_ADMIN_ORDER_REQUEST });
			const response = await fetch(`${API_ROOT}admin/orders`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					authorization: `Bearer ${accessToken}`,
				},
				body: JSON.stringify(orderData),
			});
			const data = await response.json();
			if (response.status !== 200) {
				dispatch({
					type: CREATE_ADMIN_ORDER_FAIL,
					payload: data,
				});
			} else {
				dispatch({
					type: CREATE_ADMIN_ORDER_SUCCESS,
					payload: data.data,
				});
			}
		} catch (error) {
			console.log(error);
			dispatch({
				type: CREATE_ADMIN_ORDER_FAIL,
				payload: error,
			});
		}
	};
