
import React from 'react';
import { Link } from 'react-router-dom';
import { IonContent, IonButton, IonList,IonHeader, IonPage, IonTitle, IonToolbar, IonGrid, IonRow, IonCol, IonCard, IonCardContent, IonIcon  } from '@ionic/react';
import { create, trash } from 'ionicons/icons';
import { useDispatch } from 'react-redux';
import { deleteFinance } from '../../actions/financeActions';
import { getAccessToken } from '../../services/auth/userLocalStorage';

const Testing: React.FC<{ finances: any[] }> = ({ finances }) => {
  const dispatch = useDispatch();
    const handledeleteFinance = (id) =>{
        console.log("deletein");
        const AccessToken=getAccessToken()
    // dispatch(deleteFinance(AccessToken.token,id));
    }

    return (
     
            <IonGrid className='ion-no-padding responsive-width'>
    {/* <div style={{ position: "sticky", top: "-2px", zIndex: "999", backgroundColor: "#F7F9FA",borderBottom:"2px solid lightgray",paddingTop:"3px" }}>
        <IonRow  className='ion-justify-content-center ion-align-items-center'>
            <IonCol className='ion-no-padding ion-text-center ' style={{borderRight:"2px solid lightgray"}}><h6  style={{fontWeight:"bolder"}}>S.No</h6></IonCol>
            <IonCol className='ion-no-padding ion-text-center' style={{borderRight:"2px solid lightgray"}}><h6 style={{fontWeight:"800"}}>Amount</h6></IonCol>
            <IonCol className='ion-no-padding ion-text-center' style={{borderRight:"2px solid lightgray"}}><h6 style={{fontWeight:"800"}}>Category</h6></IonCol>
            <IonCol className='ion-no-padding ion-text-center' style={{borderRight:"2px solid lightgray"}}><h6 style={{fontWeight:"800"}}>Type</h6></IonCol>
            <IonCol className='ion-no-padding ion-text-center' style={{borderRight:"2px solid lightgray"}}><h6 style={{fontWeight:"800"}}>Note</h6></IonCol>
            <IonCol className='ion-no-padding ion-text-center' style={{borderRight:"2px solid lightgray"}}><h6 style={{fontWeight:"800"}}>Reference</h6></IonCol>
            <IonCol className='ion-no-padding ion-text-center' style={{borderRight:"2px solid lightgray"}}><h6 style={{fontWeight:"800"}}>Expense By</h6></IonCol>
            <IonCol className='ion-no-padding ion-text-center' style={{borderRight:"2px solid lightgray"}}><h6 style={{fontWeight:"800"}}>Bill</h6></IonCol>
            <IonCol className='ion-no-padding ion-text-center'  style={{borderRight:"2px solid lightgray"}}><h6 style={{fontWeight:"800"}}>Added time</h6></IonCol>
            <span style={{visibility:"hidden",padding:"0px 10px 0px 10px"}}>
            <IonButton
					size="small"
					fill="outline"
				>
					<IonIcon slot="icon-only" size="small" icon={create} />
				</IonButton>
            </span>
            
        </IonRow>
    </div> */}

{/*     
    <div style={{ paddingTop: "0px" }}>
        {finances.map((finance: any, index: number) => (
            <IonRow key={index}  style={{border:"2px solid lightgray"}}
            //  className=' ion-align-items-center'
             >
                <IonCol className=" ion-text-center" style={{borderRight:"2px solid lightgray"}}>{index + 1}</IonCol>
                <IonCol className=" ion-text-center" size='' style={{borderRight:"2px solid lightgray"}}>{finance.amount}</IonCol>
                <IonCol className=" ion-text-center" size='' style={{borderRight:"2px solid lightgray"}}>{finance.category}</IonCol>
                <IonCol className=" ion-text-center" size='' style={{borderRight:"2px solid lightgray"}}>{finance?.type}</IonCol>
                <IonCol className=" ion-text-center" size='' style={{borderRight:"2px solid lightgray"}}>{finance?.notes}</IonCol>
                <IonCol className=" ion-text-center" size='' style={{borderRight:"2px solid lightgray",borderLeft:"2px solid lightgray"}}>{finance.reference}</IonCol>
                <IonCol className=" ion-text-center" size='' style={{borderRight:"2px solid lightgray"}}>{finance.expense_by}</IonCol>
                <IonCol className=" ion-text-center" size='' style={{borderRight:"2px solid lightgray"}}><img src={finance.bill_display_url} alt="" /></IonCol>
                <IonCol className=" ion-text-center" size='' style={{borderRight:"2px solid lightgray"}}>{finance.date}</IonCol>
                <span style={{padding:"0px 10px 0px 10px",borderRight:"2px solid lightgray"}}>
                    <Link to={{ pathname: `/app/finance/update/true/${finance?.id}`, state:  {finance}  }}>
                    <IonButton
					size="small"
					fill="outline"
				>
					<IonIcon slot="icon-only" size="small" icon={create} />
				</IonButton>
                    </Link>
                </span>
              
                
            </IonRow>
        ))}
    </div> */}

<IonRow className="ionrow-style ion-align-items-center ion-text-center ion-padding "
			style={{ height: '80px' }}>
            {/* <IonCol className="ion-col-style" size='1' >S.No.</IonCol> */}
			<IonCol className="ion-col-style" sizeSm='2.1' size='2.5' ><b>Amount</b></IonCol>
			{/* <IonCol className="ion-col-style" size='1.3'><b>Category</b></IonCol> */}
            <IonCol className="ion-col-style" sizeSm='2.3' size='2.5'><b>Type</b></IonCol>
            <IonCol className="ion-col-style ion-hide-md-down" sizeSm='2.3' ><b>Notes</b></IonCol>
            {/* <IonCol className="ion-col-style" size='1.5'><b>Added Time</b></IonCol> */}
          
            <IonCol className="ion-col-style" sizeSm='2.2' size='2.4'><b>Expense By</b></IonCol>
            <IonCol className="ion-col-style" sizeSm='2.1' size='2.5'><b>Bill</b></IonCol>
              {/* <IonCol className="ion-col-style" size='2.5'><b>Reference</b></IonCol> */}
            <IonCol sizeSm='1' size='1'>
            <span style={{visibility:"hidden",padding:"0px 10px 0px 10px"}}>
            <IonButton
					size="small"
					fill="outline"
				>
					<IonIcon slot="icon-only" size="small" icon={create} />
				</IonButton>
            </span></IonCol>
		</IonRow>
        {finances.map((finance: any, index: number) => (
            <IonRow key={index}  className="ionrow-style ion-align-items-center ion-text-center ion-padding "
			style={{ height: '80px'}}
             >
               
			<IonCol className="ion-col-style" sizeSm='2.1'  size='2.5'>{finance.amount}</IonCol>
			{/* <IonCol className="ion-col-style" size='1'>{finance.category}</IonCol> */}
            <IonCol className="ion-col-style" sizeSm='2.3'  size='2.5'>{finance.type}</IonCol>
            <IonCol className="ion-col-style ion-hide-md-down" sizeSm='2.3'  size='2.3'>{finance.notes}</IonCol>
            {/* <IonCol className="ion-col-style" size='1.5'>{finance.date}</IonCol> */}
  
            <IonCol className="ion-col-style" sizeSm='2.2'  size='2.4'>{finance.expense_by}</IonCol>
            <IonCol className="ion-col-style" sizeSm='2.1'  size='2.5'>
            <img src={finance.bill_display_url} alt="" />
            </IonCol>
                      {/* <IonCol className="ion-col-style" size='1'>{finance.reference}</IonCol> */}
            <IonCol sizeSm='1'>
          
            <span style={{padding:"0px 0px 0px 0px"}}>
            <Link to={{ pathname: `/app/v2finance/update/true/${finance?.id}`, state:  {finance}  }}>
            <IonButton
					size="small"
					fill="outline"
				>
					<IonIcon slot="icon-only" size="small" icon={create} />
				</IonButton></Link>
            </span>  </IonCol>
             </IonRow>
        ))}

</IonGrid>

      
        
       
    );
};

export default Testing;

