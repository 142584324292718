import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { IonContent, IonPage } from '@ionic/react';
import TipBanners from '../components/dashboard/TipBanners';
import HomeScreen from '../components/dashboard/HomeScreen';
import Title from '../components/dashboard/Header';
import './Dashboard.css';
import { RouteComponentProps, withRouter, useLocation } from 'react-router';

const Dashboard: React.FC = () => {
	const [count, setCount] = useState<any>();

	useEffect(() => {
		if (sessionStorage.getItem('reloadCount')) {
			setCount(sessionStorage.getItem('reloadCount'));
		} else {
			sessionStorage.setItem('reloadCount', String(1));
			setCount('1');
		}
	}, []);

	useEffect(() => {
		if (count === '1') {
			sessionStorage.setItem('reloadCount', String(10));
			window.location.reload();
		}
	}, [count]);
	// console.log('object');
	return (
		<IonPage className="">
			<div className="ion-page" id="main-content">
				<Title name="Home" />
				<IonContent className="ion-padding">
					<HomeScreen />
					{/* <TipBanners/>  added*/}
				</IonContent>
			</div>
		</IonPage>
	);
};

export default Dashboard;
