import { IonCol, IonImg, IonInput, IonRow, IonToggle } from '@ionic/react';
import React, { useState } from 'react';

type EditableProductRowProps = {
	register: any;
	index: number;
	product: any;
	handleToggle: any;
};

const EditableProductRow: React.FC<EditableProductRowProps> = ({
	register,
	product,
	index,
	handleToggle,
}) => {
	const [selected, setSelected] = useState(true);

	// console.log('I am here in editable product row');
	// console.log(product);
	// useEffect(() => {
	// 	if (isSelected) {
	// 		appendField();
	// 	}
	// }, [isSelected]);
	// console.log(product);
	// console.log(fields);
	// console.log(product.product_id);
	return (
		<IonRow
			className="ionrow-style ion-align-items-center ion-text-center"
			style={{ height: '80px' }}
		>
			<IonCol className="ion-col-style" sizeMd="1">
				<IonToggle
					enableOnOffLabels
					checked={selected}
					onIonChange={e => {
						// console.log(product.id, e.detail.checked);
						setSelected(e.detail.checked);
						handleToggle(product.product_id, e.detail.checked);
						// setIsSelected(!isSelected);
					}}
				>
					Select
				</IonToggle>
			</IonCol>

			<IonCol className="ion-col-style " sizeMd="1.2">
				<div>
					<IonImg
						style={{
							width: '100%',
							height: '50px',
						}}
						src={product.image_display_url}
					/>
				</div>
			</IonCol>
			<IonCol className="ion-col-style" sizeMd="2">
				<IonInput
					value={product.name}
					{...register(`products.${index}.name`)}
				/>
			</IonCol>
			<IonCol className="ion-col-style" sizeMd="2">
				<IonInput
					value={product.internal_notes}
					{...register(`products.${index}.internal_notes`)}
				/>
			</IonCol>
			<IonCol className="ion-col-style" sizeMd="1.2">
				<IonInput
					value={product.dimension}
					{...register(`products.${index}.dimension`)}
				/>
			</IonCol>
			<IonCol className="ion-col-style" sizeMd="1.2">
				<IonInput
					value={product.weight_in_gms}
					{...register(`products.${index}.weight_in_gms`)}
				/>
			</IonCol>
			<IonCol className="ion-col-style" sizeMd="1.5">
				<IonInput
					value={product.laundry_service_price}
					{...register(`products.${index}.laundry_service_price`)}
				/>
			</IonCol>
			<IonCol className="ion-col-style" sizeMd="1.9">
				<IonInput
					value={product.laundry_service_price_per_kg}
					{...register(`products.${index}.laundry_service_price_per_kg`)}
				/>
			</IonCol>
		</IonRow>
	);
};

export default EditableProductRow;
