import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import './Home.css';

import {
	IonContent,
	IonPage,
	IonText,
	IonButton,
	IonImg,
	IonLoading,
	IonIcon,
	IonGrid,
	IonRow,
	IonCol,
	IonCard,
	IonCardHeader,
	IonCardContent,
	IonHeader,
	IonCardTitle,
	IonCardSubtitle,
	IonFooter,
} from '@ionic/react';
import background from '../../assets/home/background.jpg';
import LogoImage from '../../components/auth/LogoImage';
import LaptopImage from '../images/eco.png';
import Step1Image from '../images/step1.png';
import Step2Image from '../images/step2.png';
import Step3Image from '../images/step3.png';
import GoogleLogo from '../images/auth/GoogleLogo.png';
import FacebookLogo from '../images/auth/FacebookLogo.png';
import OTPEmail from '../images/auth/OTPEmail.png';
import LoginFooter from '../../components/auth/LoginFooter';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
// import analyticsImg from "../images/dashboard_analytics.PNG"
//import { FacebookLogin } from '@rdlabo/capacitor-facebook-login';
// import {
// 	getUserTokens,
// 	getCurrentUser,
// 	clearErrors,
// 	authenticateUserWhenLogin,
// 	authenticateUserWhenLogout,
// } from '../../actions/authActions';
import {
	getAccessToken,
	setTokensObject,
} from '../../services/auth/userLocalStorage';
import { BrowserView, isDesktop, MobileView } from 'react-device-detect';
import {
	// ellipsisHorizontal,
	// ellipsisVertical,
	// logoGoogle,
	mail,
	// mail,
	// arrowForward,
	// caretForward,
	// accessibilityOutline,
	// addCircle,
	// shareSocial,
	// cash,
	// refreshCircle,
	// thumbsUp,
	// duplicate,
} from 'ionicons/icons';
import {
	authenticateUserWhenLogin,
	getCurrentUser,
} from '../../actions/authActions';

const Home: React.FC = () => {
	// const [doReload, setDoReload] = useState(false);
	const history = useHistory();
	const dispatch = useDispatch();

	// Fetching user API tokens and current user data from the Redux store
	const { userAPITokensLoading, userAPITokens, loadUserAPITokensError } =
		useSelector((state: any) => state.userAPITokens);
	const { currentUserLoading, currentUser, currentUserError } = useSelector(
		(state: any) => state.currentUser
	);
	const { isUserAuthenticate } = useSelector(
		(state: any) => state.userAuthenticate
	);

	// Set the reload count in session storage
	useEffect(() => {
		sessionStorage.setItem('reloadCount', String(1));
		// GoogleAuth.init();
	}, []);

	// Load user API tokens and redirect based on authentication status
	useEffect(() => {
		if (userAPITokensLoading === false) {
			if (userAPITokens) {
				setTokensObject(userAPITokens);
				loadCurrentUser(userAPITokens.access_token);

				let token = getAccessToken();
				if (token) {
					history.replace('/app/home');
				} else {
					history.push('/email-otp-login');
				}
			}
		}
	}, [userAPITokens]);

	// Load current user and redirect based on authentication status
	useEffect(() => {
		if (currentUserLoading === false && getAccessToken()) {
			if (currentUser && currentUser.data) {
				dispatch(authenticateUserWhenLogin());
				let token = getAccessToken();
				if (token) {
					history.push('/app/home');
				} else {
					history.push('/email-otp-login');
				}
			}
		}
	}, [currentUser]);

	/**
	 * Loads the current user data.
	 * @param token - Access token
	 */

	const loadCurrentUser = async (token: any) => {
		await dispatch(getCurrentUser(token));
	};

	return (
		<IonPage>
			<IonContent>
				<div
					id="background-content"
					style={{
						left: '0px',
						right: '0px',
						top: '0px',
						bottom: '0px',
						position: 'absolute',
						backgroundImage: `url('${background}')`,
						backgroundRepeat: 'no-repeat',
						backgroundPosition: 'center',
						backgroundSize: 'cover',
						opacity: '0.3',
					}}
				></div>
				<IonGrid
					className="center-grid"
					style={{
						background: 'transparent',
						border: 'none',
						boxShadow: 'none',
						zIndex: 100,
					}}
				>
					<div className="login-card">
						<div className="">
							<LogoImage />
						</div>
						<div>
							<h5
								className="ion-text-center"
								style={{
									fontSize: '20px',
									color: 'var(--ion-color-primary)',
								}}
							>
								Admin App
							</h5>

							<IonButton routerLink="/email-otp-login" expand="block">
								<IonIcon icon={mail} slot="start" color="light" />
								<IonText color={'light'} className="ion-margin-horizontal">
									Continue with Email OTP
								</IonText>
							</IonButton>
						</div>
					</div>
				</IonGrid>

				{/* <IonGrid className="ion-no-padding">
					<IonRow className="ion-no-padding">
						<IonCol>
							<IonCard className="ion-no-padding">
								<IonCardContent className="outerContainerdt">
									<IonGrid>
										<IonRow>
											<IonCol>
												<div className={classOfLogo}>
													<LogoImage />
												</div>
											</IonCol>
										</IonRow>
										<IonRow className="ion-padding ion-text-center">
											<IonCol>
												<IonText
													style={{
														fontSize: '50px',
														fontWeight: 'bold',
														color: 'white',
													}}
												>
													Partner. Serve. Grow.
												</IonText>
											</IonCol>
										</IonRow>
										<IonRow className="ion-text-center">
											<IonCol>
												<IonText style={{ fontSize: '20px', color: 'white' }}>
													Let's join hands to grow together by serving digital
													economies. <br /> As a partner, sell our products to
													your customers and earn recurring revenues.
													<br />
													<br />
													Become a Partner & be Future Ready
												</IonText>
											</IonCol>
										</IonRow>
										<IonRow>
											<IonButton
												className={classOfLoginButton}
												shape="round"
												color="danger"
												onClick={() => signInWithGoogle()}
												style={{ width: '250px' }}
											>
												<IonIcon
													className="iconlogin"
													slot="start"
													icon={logoGoogle}
												/>
												<IonText>Continue with Google</IonText>
											</IonButton>
										</IonRow>
									</IonGrid>
								</IonCardContent>
							</IonCard>
						</IonCol>
					</IonRow>
					<IonRow className="ion-no-padding">
						<IonCol>
							<IonCard className="ion-no-padding">
								<IonCardContent>
									<IonGrid>
										<IonRow className="ion-text-center">
											<IonCol className="displayLaptopdt">
												<IonImg className="ion-text-center" src={LaptopImage} />
											</IonCol>
										</IonRow>
									</IonGrid>
								</IonCardContent>
							</IonCard>
						</IonCol>
					</IonRow>
					<IonRow className="ion-text-center">
						<IonCol>
							<IonCol>
								<IonCard>
									<IonCardContent className="outerContainerdt">
										<IonText
											style={{
												fontSize: '30px',
												fontWeight: 'bold',
												color: 'white',
											}}
										>
											Reasons to join hands
										</IonText>
									</IonCardContent>
								</IonCard>
							</IonCol>
						</IonCol>
					</IonRow>
					<IonRow className="ion-text-center">
						<IonCol>
							<IonCard>
								<IonCardHeader>
									<IonIcon icon={addCircle} size="large" />
									<IonCardTitle>Easy Joining & Training Process</IonCardTitle>
									<IonCardSubtitle></IonCardSubtitle>
								</IonCardHeader>
							</IonCard>
						</IonCol>

						<IonCol>
							<IonCard>
								<IonCardHeader>
									<IonIcon icon={shareSocial} size="large" />
									<IonCardTitle>Leads Sharing for Outreach</IonCardTitle>
									<IonCardSubtitle></IonCardSubtitle>
								</IonCardHeader>
							</IonCard>
						</IonCol>
					</IonRow>
					<IonRow className="ion-text-center">
						<IonCol>
							<IonCard>
								<IonCardHeader>
									<IonIcon icon={refreshCircle} size="large" />
									<IonCardTitle>Lifetime Recurring Revenues</IonCardTitle>
									<IonCardSubtitle></IonCardSubtitle>
								</IonCardHeader>
							</IonCard>
						</IonCol>

						<IonCol>
							<IonCard>
								<IonCardHeader>
									<IonIcon icon={cash} size="large" />
									<IonCardTitle>Share up to 30% of Revenues</IonCardTitle>
									<IonCardSubtitle></IonCardSubtitle>
								</IonCardHeader>
							</IonCard>
						</IonCol>
					</IonRow>

					<IonRow className="ion-text-center">
						<IonCol>
							<IonCard>
								<IonCardHeader>
									<IonIcon icon={thumbsUp} size="large" />
									<IonCardTitle>Awesome Support & Growth</IonCardTitle>
									<IonCardSubtitle></IonCardSubtitle>
								</IonCardHeader>
							</IonCard>
						</IonCol>

						<IonCol>
							<IonCard>
								<IonCardHeader>
									<IonIcon icon={duplicate} size="large" />
									<IonCardTitle>Cross Sell your Services</IonCardTitle>
									<IonCardSubtitle></IonCardSubtitle>
								</IonCardHeader>
							</IonCard>
						</IonCol>
					</IonRow>

					<IonRow>
						<IonCol>
							<IonCard>
								<IonCardContent>
									<IonText>
										Copyrights reserved by Intelikart. Terms of Services
										applicable.
									</IonText>
								</IonCardContent>
							</IonCard>
						</IonCol>
					</IonRow>
				</IonGrid> */}
			</IonContent>
			<LoginFooter />
		</IonPage>
	);
};

export default Home;
