import React, { useEffect, useState } from 'react';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import YouTube from 'react-youtube';
import {
	IonAlert,
	IonAvatar,
	IonButton,
	IonCard,
	IonCardHeader,
	IonCardTitle,
	IonCheckbox,
	IonCol,
	IonContent,
	IonGrid,
	IonIcon,
	IonImg,
	IonInfiniteScroll,
	IonInfiniteScrollContent,
	IonInput,
	IonItem,
	IonLabel,
	IonList,
	IonLoading,
	IonPage,
	IonRow,
	IonSegment,
	IonSegmentButton,
	IonText,
	useIonViewDidEnter,
	useIonViewWillEnter,
} from '@ionic/react';
import {
	closeCircleOutline,
	search as searchIcon,
	// add as addIcon,
	// createSharp as createSharpIcon,
	// trashSharp as trashSharpIcon,
	addOutline as addOutlineIcon,
	// addCircle as addCircleIcon,
} from 'ionicons/icons';
import { useDispatch, useSelector } from 'react-redux';
import { getAccessToken } from '../../services/auth/userLocalStorage';
import { getStoresCategory } from '../../actions/categoryAction';
import { getProductsList } from '../../actions/dashboardActions';
import { Link } from 'react-router-dom';
import {
	deleteProduct,
	getAllProduct,
	searchQueryProduct,
} from '../../actions/productActions';
import Title from '../dashboard/Header';
import MyProductTable from './MyProductTable';
import { API_ROOT } from '../../constants/global';

// install Swiper modules
SwiperCore.use([Navigation]);

const ProductsHomeScreen = (props: any) => {
	//states for search product by name as query
	const [query, setQuery] = useState<any>('');
	const [isQueryValidate, setIsQueryValidate] = useState(true);

	//states for displaying initial products and load more products.
	const [filterArrayObjectChange, setFilterArrayObjectChange] = useState(1);
	const [filterArrayObject, setFilterArrayObject] = useState({});
	const [disableInfiniteScroll, setDisableInfiniteScroll] =
		useState<boolean>(true);
	const [subcategoryId, setSubcategoryId] = useState<any>();
	const [subcategoryName, setSubcategoryName] = useState<any>();
	const [page, setPage] = useState<any>({});

	//states for product short info
	const [checkedProducts, setCheckedProducts] = useState<any>({});
	const [isAnyProductChecked, setIsAnyProductChecked] = useState(false);
	const [checkedProductCount, setCheckedProductCount] = useState(0);
	const [showAlertForMulti, setShowAlertForMulti] = useState(false);
	const [showAlertForSingle, setShowAlertForSingle] = useState(false);
	const [deletedProductId, setDeletedProductId] = useState<any>();

	// const [loading, setLoading] = useState(false);
	const [isProductDeleted, setIsProductDeleted] = useState(1);
	const [isProductAdded, setIsProductAdded] = useState(1);
	const [isProductUpdate, setIsProductUpdate] = useState(1);
	const [searchResults, setSearchResults] = useState<any>();

	// states for products
	// const [products, setProducts] = useState([]);
	// const [loading, setLoading] = useState(true);
	// console.log('producthome', products);

	const { editProductLoading, editProductMessage, editProductError } =
		useSelector((state: any) => state.updateProductData);

	const { createProductLoading, createProductMessage, createProductError } =
		useSelector((state: any) => state.createProductData);

	const {
		getAllProductLoading: loading,
		getAllProductMessage: products,
		getAllProductError,
	} = useSelector((state: any) => state.getAllProducts);
	// console.log(getAllProductMessage);

	const dispatch = useDispatch();

	useEffect(() => {
		const tokenObject = getAccessToken();
		dispatch(getAllProduct(tokenObject.token));

		// const fetchData = async () => {
		// 	const response = await fetch(`${API_ROOT}products`, {
		// 		method: 'GET',
		// 		headers: {
		// 			'Content-Type': 'application/json',
		// 			authorization: `Bearer ${tokenObject.token}`,
		// 		},
		// 	});
		// 	const data = await response.json();
		// 	if (data) {
		// 		setProducts(data.data);
		// 		// setNextPage(resdata?.meta.pagination.links.next);
		// 		// setPreviousPage(resdata?.meta.pagination.links.previous);
		// 	}
		// 	setLoading(false);
		// };

		// fetchData();
	}, [editProductMessage, createProductMessage]);

	// console.log(editProductMessage, 'editProductMessage');
	useEffect(() => {
		if (query) {
			setSearchResults(
				products.filter((product: any) =>
					product.name.toLowerCase().includes(query.toLowerCase())
				)
			);
		}
	}, [query, products]);

	// var idFromProps;
	// var cateIdFromProps;
	// if (props && props.location && props.location.state) {
	// 	idFromProps = props.location.state;
	// 	if (idFromProps.id) {
	// 		cateIdFromProps = idFromProps.id;
	// 	}
	// }

	const { currentUser } = useSelector((state: any) => state.currentUser);
	// const { fetchCategoriesLoading, categories, fetchCategoriesError } =
	// 	useSelector((state: any) => state.categories);

	// const {
	// 	productsBySubcategoryLoading,
	// 	productsBySubcategory,
	// 	productsBySubcategoryError,
	// } = useSelector((state: any) => state.productListBySubcategory);

	// const { deleteProductLoading } = useSelector(
	// 	(state: any) => state.deleteProductData
	// );

	// const { editProductLoading, editProductMessage, editProductError } =
	// 	useSelector((state: any) => state.updateProductData);

	// const {
	// 	searchProductQueryLoading,
	// 	searchProductQueryMessage,
	// 	searchProductQueryError,
	// } = useSelector((state: any) => state.searchQueryProductData);

	// const dispatch = useDispatch();

	//youtube option
	// const opts = {
	// 	height: '390',
	// 	width: '100%',
	// };

	//for reloading when new product is added
	// useEffect(() => {
	// 	if (
	// 		createProductLoading === false &&
	// 		createProductMessage &&
	// 		createProductMessage[0] &&
	// 		createProductMessage[0].category_id
	// 	) {
	// 		page[`${createProductMessage[0].category_id}`] = 1;
	// 		let temp = filterArrayObject;
	// 		temp[`${createProductMessage[0].category_id}`] = [];
	// 		setFilterArrayObject(temp);
	// 		setFilterArrayObjectChange(filterArrayObjectChange + 1);
	// 		setIsProductAdded(isProductAdded + 1);
	// 	}
	// }, [createProductLoading]);

	//for reloading when any product is edited
	// useEffect(() => {
	// 	if (
	// 		Boolean(
	// 			editProductLoading === false &&
	// 				editProductMessage &&
	// 				editProductMessage[0] &&
	// 				editProductMessage[0].category_id
	// 		)
	// 	) {
	// 		page[`${editProductMessage[0].category_id}`] = 1;
	// 		let temp = filterArrayObject;
	// 		temp[`${editProductMessage[0].category_id}`] = [];
	// 		setFilterArrayObject(temp);
	// 		setFilterArrayObjectChange(filterArrayObjectChange + 1);
	// 		setIsProductUpdate(isProductUpdate + 1);
	// 	}
	// }, [editProductLoading]);

	// useEffect(() => {
	// 	if (searchProductQueryLoading === false) {
	// 		setSearchResults(searchProductQueryMessage.data);
	// 	}
	// }, [searchProductQueryLoading]);

	// useEffect(() => {
	// 	if (
	// 		fetchCategoriesLoading === false &&
	// 		categories[0] &&
	// 		categories[0].subCategory &&
	// 		categories[0].subCategory[0]
	// 	) {
	// 		setSubcategoryId(categories[0].subCategory[0].id);
	// 		setSubcategoryName(categories[0].subCategory[0].name);
	// 	}
	// }, [fetchCategoriesLoading]);

	// useEffect(() => {
	// 	if (!categories && getAccessToken() && currentUser && currentUser.data) {
	// 		dispatch(
	// 			getStoresCategory(getAccessToken(), currentUser.data[0].store_id)
	// 		);
	// 	}
	// }, [currentUser]);

	//dynamic content
	//when subcategory change then get their product for first time.
	// useEffect(() => {
	// 	if (currentUser) {
	// 		setCheckedProducts({});
	// 		setIsAnyProductChecked(false);
	// 		setCheckedProductCount(0);
	// 		fetchData();
	// 	}
	// }, [
	// 	subcategoryId,
	// 	isProductDeleted,
	// 	isProductAdded,
	// 	isProductUpdate,
	// 	currentUser,
	// ]);

	// useEffect(() => {
	// 	if (productsBySubcategoryLoading === false) {
	// 		if (
	// 			productsBySubcategory &&
	// 			productsBySubcategory &&
	// 			productsBySubcategory.length > 0
	// 		) {
	// 			setDisableInfiniteScroll(Boolean(productsBySubcategory.length < 20));
	// 		} else {
	// 			setDisableInfiniteScroll(true);
	// 		}
	// 	}
	// 	if (
	// 		productsBySubcategoryLoading === false &&
	// 		productsBySubcategory &&
	// 		productsBySubcategory
	// 	) {
	// 		if (productsBySubcategory.length > 0) {
	// 			const op = productsBySubcategory.reduce(function (acc, curr) {
	// 				if (acc[subcategoryId]) {
	// 					acc[subcategoryId].push(curr);
	// 				} else {
	// 					acc[subcategoryId] = [];
	// 					acc[subcategoryId].push(curr);
	// 				}
	// 				return acc;
	// 			}, filterArrayObject);
	// 			setFilterArrayObjectChange(filterArrayObjectChange + 1);
	// 			setFilterArrayObject(op);
	// 		}
	// 		// if (productsBySubcategory.data.length === 0) {
	// 		if (productsBySubcategory.length === 0) {
	// 			let temp = filterArrayObject;
	// 			temp[subcategoryId] = [];
	// 			setFilterArrayObjectChange(filterArrayObjectChange + 1);
	// 			setFilterArrayObject(temp);
	// 		}
	// 	}
	// }, [productsBySubcategoryLoading]);

	// useEffect(() => {
	// 	if (idFromProps && props.location.state) {
	// 		setSubcategoryId(idFromProps.id);
	// 		setSubcategoryName(idFromProps.name);
	// 	}
	// }, [idFromProps, cateIdFromProps]);
	const { currentUserLoading } = useSelector((state: any) => state.currentUser);
	// const fetchData = async () => {
	// 	if (
	// 		subcategoryId &&
	// 		currentUser &&
	// 		currentUser.data[0] &&
	// 		!(subcategoryId in page)
	// 	) {
	// 		setDisableInfiniteScroll(false);
	// 		await dispatch(
	// 			getProductsList(subcategoryId, currentUser.data[0].store_id)
	// 		);
	// 		let temp = page;
	// 		temp[`${subcategoryId}`] = 2;
	// 		setPage(temp);
	// 	}
	// 	if (
	// 		subcategoryId &&
	// 		currentUser &&
	// 		currentUser.data[0] &&
	// 		subcategoryId in page &&
	// 		page[`${subcategoryId}`] === 1
	// 	) {
	// 		setDisableInfiniteScroll(false);
	// 		await dispatch(
	// 			getProductsList(subcategoryId, currentUser.data[0].store_id)
	// 		);
	// 		let temp = page;
	// 		temp[`${subcategoryId}`] = 2;
	// 		setPage(temp);
	// 	}
	// };
	// console.log(productsBySubcategory);

	// useEffect(() => {}, [filterArrayObjectChange]);
	useIonViewWillEnter(() => {
		setSearchResults(null);
		setQuery('');
	});

	// async function searchNext($event: CustomEvent<void>) {
	// 	await fetchNextData();
	// 	($event.target as HTMLIonInfiniteScrollElement).complete();
	// }

	// const fetchNextData = async () => {
	// 	if (
	// 		subcategoryId in filterArrayObject &&
	// 		filterArrayObject[`${subcategoryId}`].length === 20
	// 	) {
	// 		let res = await fetch(
	// 			`https://api.intelikart.com/api/search/products?page=${page.subcategoryId}&categoryId=${subcategoryId}&storeId=${currentUser.data[0].store_id}`,
	// 			{
	// 				method: 'GET',
	// 				headers: {
	// 					'content-type': 'application/json',
	// 				},
	// 			}
	// 		);

	// 		res
	// 			.json()
	// 			.then(async res => {
	// 				if (res && res.data && res.data.length > 0) {
	// 					const op = res.data.reduce(function (acc, curr) {
	// 						if (acc[subcategoryId]) {
	// 							acc[subcategoryId].push(curr);
	// 						} else {
	// 							acc[subcategoryId] = [];
	// 							acc[subcategoryId].push(curr);
	// 						}
	// 						return acc;
	// 					}, filterArrayObject);
	// 					setFilterArrayObject(op);
	// 					setFilterArrayObjectChange(filterArrayObjectChange + 1);
	// 					setDisableInfiniteScroll(res.data.length < 20);
	// 					let temp = page;
	// 					temp[`${subcategoryId}`] = temp[`${subcategoryId}`] + 1;
	// 					setPage(temp);
	// 				} else {
	// 					setDisableInfiniteScroll(true);
	// 				}
	// 			})
	// 			.catch(err => console.error(err));
	// 	}
	// };

	//functions for product short info
	// const onCheckedClicked = async productId => {
	// 	if (`${productId}` in checkedProducts) {
	// 		let temp = checkedProducts;
	// 		temp[`${productId}`] = !temp[`${productId}`];
	// 		if (temp[`${productId}`]) {
	// 			await setCheckedProductCount(checkedProductCount + 1);
	// 		} else {
	// 			await setCheckedProductCount(checkedProductCount - 1);
	// 		}
	// 		await setCheckedProducts(temp);
	// 	} else {
	// 		let temp = checkedProducts;
	// 		temp[`${productId}`] = true;
	// 		await setCheckedProductCount(checkedProductCount + 1);
	// 		await setCheckedProducts(temp);
	// 	}
	// 	for (const value in checkedProducts) {
	// 		if (checkedProducts[value]) {
	// 			setIsAnyProductChecked(true);
	// 			break;
	// 		} else {
	// 			setIsAnyProductChecked(false);
	// 		}
	// 	}
	// };

	// const deleteAllSelected = async () => {
	// 	if (getAccessToken() && currentUser) {
	// 		setLoading(true);
	// 		for (const value in checkedProducts) {
	// 			if (checkedProducts[value]) {
	// 				await dispatch(
	// 					deleteProduct(getAccessToken(), currentUser.data[0].store_id, value)
	// 				);
	// 			}
	// 		}
	// 		page[`${subcategoryId}`] = 1;
	// 		let temp = filterArrayObject;
	// 		temp[`${subcategoryId}`] = [];
	// 		setFilterArrayObject(temp);
	// 		setFilterArrayObjectChange(filterArrayObjectChange + 1);
	// 		setCheckedProducts({});
	// 		setisAnyProductChecked(false);
	// 		setCheckedProductCount(0);
	// 		setLoading(false);
	// 		setIsProductDeleted(isProductDeleted + 1);
	// 	}
	// };

	// const deleteSingleProduct = async () => {
	// 	if (getAccessToken() && currentUser && deletedProductId) {
	// 		await dispatch(
	// 			deleteProduct(
	// 				getAccessToken(),
	// 				currentUser.data[0].store_id,
	// 				deletedProductId
	// 			)
	// 		);
	// 		page[`${subcategoryId}`] = 1;
	// 		let temp = filterArrayObject;
	// 		temp[`${subcategoryId}`] = [];
	// 		setFilterArrayObject(temp);
	// 		setFilterArrayObjectChange(filterArrayObjectChange + 1);
	// 		setIsProductDeleted(isProductDeleted + 1);
	// 	}
	// };

	// const searchProductByName = async e => {
	// 	setQuery(e.detail.value);
	// 	setSearchResults(null);
	// 	if (e.detail.value.length == 0) {
	// 		return;
	// 	} else {
	// 		setIsQueryValidate(true);
	// 		if (currentUser && currentUser.data[0]) {
	// 			await dispatch(
	// 				searchQueryProduct(e.detail.value, currentUser.data[0].store_id)
	// 			);
	// 		}
	// 	}
	// };
	const onSearchCancel = e => {
		setSearchResults(null);
		setQuery('');
	};

	return (
		<IonPage>
			<Title name="Products" />
			<IonContent>
				<div>
					<IonRow className="ion-margin-top ion-align-items-center ion-wrap-reverse">
						<IonCol className="" sizeMd="6" sizeXs="12">
							<IonItem className="ion-margin">
								<IonLabel>
									<IonIcon icon={searchIcon}></IonIcon>
								</IonLabel>
								<IonInput
									placeholder="Search Product by Name"
									value={query}
									onIonChange={e => {
										// console.log(e.detail.value);
										// searchProductByName(e);
										setQuery(e.detail.value);
									}}
								></IonInput>
								{searchResults ? (
									<IonIcon
										icon={closeCircleOutline}
										onClick={e => {
											onSearchCancel(e);
										}}
									></IonIcon>
								) : (
									''
								)}
							</IonItem>
						</IonCol>
						<IonCol className="ion-hide-md-down" sizeMd="3"></IonCol>
						<IonCol
							className="ion-text-end ion-padding-horizontal"
							sizeMd="3"
							sizeXs="12"
						>
							<Link to={`products/create/true`}>
								<IonButton shape="round" expand="full">
									<IonIcon icon={addOutlineIcon} color="light" />
									<IonText color={'light'}> Add New Product</IonText>
								</IonButton>
							</Link>
						</IonCol>
					</IonRow>
					<MyProductTable products={searchResults ? searchResults : products} />
					<IonLoading
						isOpen={!currentUserLoading && loading}
						message="Please Wait..."
					></IonLoading>
					{/* <IonCard className="ion-text-center ion-no-padding">
							<IonCardHeader>
								<IonCardTitle>
									Watch video to understand how to add products.
								</IonCardTitle>
							</IonCardHeader>
							<YouTube videoId="dkOvPjF41YA" opts={opts} />
						</IonCard> */}
				</div>

				{/* <IonLoading
					isOpen={
						productsBySubcategoryLoading === true ||
						fetchCategoriesLoading === true ||
						loading === true ||
						deleteProductLoading === true
					}
					message="Please Wait"
				/> */}
			</IonContent>
			{/* <IonButton
				onClick={e => setShowAlertForMulti(true)}
				expand="full"
				color="danger"
				className={`${isAnyProductChecked ? '' : 'ion-hide'}`}
			>
				<IonIcon icon={trashSharpIcon}></IonIcon> &nbsp; Delete all selected
			</IonButton> */}
		</IonPage>
	);
};

export default ProductsHomeScreen;
