import { WithContext as ReactTags } from 'react-tag-input';
import { yupResolver } from '@hookform/resolvers/yup';
import {
	IonButton,
	IonButtons,
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardSubtitle,
	IonCardTitle,
	IonCheckbox,
	IonCol,
	IonContent,
	IonGrid,
	IonHeader,
	IonIcon,
	IonInput,
	IonItem,
	IonLabel,
	IonLoading,
	IonPage,
	IonProgressBar,
	IonRow,
	IonSelect,
	IonSelectOption,
	IonText,
	IonTitle,
	IonToast,
	IonToolbar,
	isPlatform,
} from '@ionic/react';
import './AddCustomer.css';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { arrowBack as arrowBackIcon } from 'ionicons/icons';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { Camera, CameraResultType, Photo } from '@capacitor/camera';
// import { GoogleMap, LoadScript } from '@react-google-maps/api';
import { API_ROOT } from '../../constants/global';
import { getAccessToken } from '../../services/auth/userLocalStorage';
import EditableProductRow from '../../components/customer/EditableProductRow';
import * as yup from 'yup';
import { createCustomer } from '../../actions/customerAction';
import { useDispatch, useSelector } from 'react-redux';
import ToggleProductRow from '../../components/customer/ToggleProductRow';
import axios from 'axios';
import ContractCard from '../../components/customer/ContractPdfCard';
import saveFile from '../../utils/SaveFile';
import { getAllProduct } from '../../actions/productActions';

export const States = [
	'Andhra Pradesh',
	'Arunachal Pradesh',
	'Assam',
	'Bihar',
	'Chhattisgarh',
	'Goa',
	'Gujarat',
	'Haryana',
	'Himachal Pradesh',
	'Jharkhand',
	'Karnataka',
	'Kerala',
	'Madhya Pradesh',
	'Maharashtra',
	'Manipur',
	'Meghalaya',
	'Mizoram',
	'Nagaland',
	'Odisha',
	'Punjab',
	'Rajasthan',
	'Sikkim',
	'Tamil Nadu',
	'Telangana',
	'Tripura',
	'Uttar Pradesh',
	'Uttarakhand',
	'West Bengal',
	'Andaman and Nicobar Islands',
	'Chandigarh',
	'Dadra and Nagar Haveli and Daman and Diu',
	'Lakshadweep',
	'Delhi',
	'Puducherry',
];

export const CustomerSchema = yup.object().shape({
	first_name: yup.string().required('First Name is required'),
	last_name: yup.string().required('Last Name is required'),
	email: yup
		.string()
		.email('Must be a valid email')
		.required('Email is required'),
	name: yup.string().required('Business name is required'),
	logo: yup.string().required('Business logo is required'),
	industry: yup
		.string()
		.oneOf(
			[
				'hospitals',
				'hotels',
				'factory',
				'hostels',
				'restaurant',
				'schools',
				'theater',
				'guestHouse',
				'ashram',
			],
			'Invalid industry'
		)
		.required('Industry is required'),
	address_line1: yup.string().required('Address line 1 is required'),
	address_line2: yup.string(),
	city: yup.string().required('City is required'),
	// state: yup.string().required('State is required'),
	state: yup
		.string()
		.required('State is required')
		.test('valid-state', 'Invalid State', value => {
			return States.map(state => state.toLowerCase()).includes(
				value.toLowerCase()
			);
		}),
	pin_code: yup
		.string()
		.required('Pin code is required')
		.matches(/^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$/, 'Invalid Pin Code'),
	phone: yup
		.string()
		.required('Phone is required')
		.matches(/^[6-9]\d{9}$/, 'Invalid Phone Number'),
	gstin: yup.string().required('GSTIN is required'),
	latitude: yup
		.string()
		.matches(/^-?([0-8]?[0-9]|90)(\.[0-9]{1,10})?$/, 'Invalid Latitude'),
	longitude: yup
		.string()
		.matches(
			/^-?([0-9]{1,2}|1[0-7][0-9]|180)(\.[0-9]{1,10})?$/,
			'Invalid Longitude'
		),
	status: yup
		.string()
		.oneOf(['active', 'inactive'])
		.required('Status is required'),
	bill_type: yup
		.string()
		.required('Bill type is required')
		.oneOf(['per-kg', 'per-piece', 'flat']),
	flat_rate_monthly_inr: yup.string().when('bill_type', {
		is: (val: string) => val === 'flat',
		then: (schema: any) => schema.required('Bill rate is required'),
	}),

	contract_file: yup.string(),
	additional_emails: yup.array().of(
		yup.object().shape({
			id: yup.string(),
			text: yup.string().email('Must be a valid email'),
		})
	),
	auto_approve: yup.number(),
	products: yup
		.array()
		.of(yup.object())
		.required('Products are required. Please Refresh The page.'),
});

export type CustomerFormInputs = yup.InferType<typeof CustomerSchema>;

const KeyCodes = {
	comma: 188,
	enter: [10, 13],
	space: 32,
};
const initialPictureUrl =
	'https://cdn.intelikart.com/uploads/new-images/default-store.png';

const delimiters = [...KeyCodes.enter, KeyCodes.comma, KeyCodes.space];
const AddCustomer: React.FC = () => {
	const [loading, setLoading] = useState(false);
	const [contractFile, setContractFile] = useState<any>();
	const [progress, setProgress] = useState(0);
	const [unSelectedProducts, setUnSelectedProducts] = useState<any[]>([]);
	const [allActiveProducts, setAllActiveProducts] = useState<any[]>([]);
	const logoInputRef = useRef<HTMLInputElement>();
	const contractInputRef = useRef<HTMLInputElement>();
	const history = useHistory();
	const [pictureUrl, setPictureUrl] = useState(initialPictureUrl);

	const removeFile = () => {
		setContractFile('');
		setValue('contract_file', '');
	};

	const dispatch = useDispatch();

	const { createCustomerLoading, createCustomerMessage, createCustomerError } =
		useSelector((state: any) => state.createCustomerData);
	// console.log(createCustomerError, ' createCustomerError');
	// console.log(createCustomerMessage, ' createCustomerMessage');
	/**
	 * Handles the change event of the file input element.
	 * Updates the value of the logo input and sets the picture URL.
	 * @param {React.ChangeEvent<HTMLInputElement>} event - The change event.
	 */
	const handleLogoChange = async (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setLoading(true);
		// Check if a file has been selected
		if (event.target?.files?.length > 0) {
			// Get the first file from the selected files
			const file = event.target.files.item(0);

			// Update the value of the logo input
			const myPlatformPath = await saveFile(file, 'customer-logo-image');
			setLoading(false);
			setValue('logo', myPlatformPath);
			// Generate a URL for the selected file
			const pictureUrl = URL.createObjectURL(file);

			// Set the picture URL
			setPictureUrl(pictureUrl);
		}
	};

	const handleToggle = (id: string, isSelected: boolean) => {
		if (isSelected) {
			//if toggle checked then append the product
			// console.log(id, isSelected);
			// select only unselected product by filtering the selected product
			setUnSelectedProducts(
				unSelectedProducts.filter(product => product.id !== id)
			);
			append({
				...allActiveProducts.find(product => product.id === id),
				product_id: id,
			});
		} else {
			// set the unselected product in unselected list
			setUnSelectedProducts([
				...unSelectedProducts,
				allActiveProducts.find(product => product.id === id),
			]);
			// // console.log(fields.findIndex((pd: any) => pd.productId === id));
			remove(fields.findIndex((pd: any) => pd.product_id === id));
		}
	};
	// console.log(unSelectedProducts);
	const handleContractChange = async (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		// setLoading(true);
		// Check if a file has been selected
		if (event.target?.files?.length > 0) {
			// Get the first file from the selected files
			const file = event.target.files.item(0);
			console.log(file);
			setContractFile({
				name: file.name,
				size: (file.size / 1024).toFixed(1),
				type: file.type.split('/')[1],
			});
			// Update the value of the logo input
			if (file.type.split('/')[1] === 'pdf') {
				const myPlatformPath = await saveFile(file, 'customer-contract');
				setValue('contract_file', myPlatformPath);
			} else {
				setError('contract_file', {
					type: 'manual',
					message: 'Only pdf files are allowed',
				});
			}
			// Generate a URL for the selected file
			// const pictureUrl = URL.createObjectURL(file);

			// Set the picture URL
			// setPictureUrl(pictureUrl);
		}
	};

	const {
		handleSubmit,
		control,
		setValue,
		register,
		setError,
		getValues,
		reset,
		formState: { errors },
	} = useForm<CustomerFormInputs>({ resolver: yupResolver(CustomerSchema) });
	// console.log(getValues());
	// console.log(errors);
	const { fields, remove, append } = useFieldArray({
		control,
		name: 'products',
	});
	const [isBillRateVisible, setIsBillRateVisible] = useState<Boolean>(false);
	const [showToast, setShowToast] = useState(false);
	const [toastMessage, setToastMessage] = useState('');
	const {
		ref: logoRef,
		// onChange: logoOnChange,
		...rest
	} = register('logo', {});
	const { ref: contractRef, ...contractRest } = register('contract_file', {});

	/**
	 * Handles the click event of the picture.
	 * If the platform is 'capacitor', opens the camera and sets the picture URL.
	 * Otherwise, triggers a click event on the file input.
	 */
	const handlePictureClick = async (): Promise<void> => {
		// Check if the platform is 'capacitor'
		if (isPlatform('capacitor')) {
			try {
				// Open the camera and get a photo
				const photo: Photo = await Camera.getPhoto({
					resultType: CameraResultType.Uri,
				});
				// Set the picture URL to the web path of the photo
				setPictureUrl(photo.webPath);
			} catch (error) {
				// Log any errors that occur during the process
				console.log(error);
			}
		} else {
			// If the platform is not 'capacitor', trigger a click event on the file input
			logoInputRef.current.click();
		}
	};
	// useEffect(() => {
	// 	const interval = setInterval(() => {
	// 		setProgress(prevProgress => prevProgress + 0.01);
	// 	}, 50);

	// 	return () => clearInterval(interval);
	// }, [contractFile]);
	// console.log(getValues());
	// console.log(progress);

	const onSubmit = async (data: any) => {
		// alert(JSON.stringify(data, null, 2));
		// console.log(data);
		// console.log(data.additional_emails);
		const emails = data.additional_emails
			.map((data: { text: any }) => data.text)
			.join(',');

		const newCustomer = {
			id: 0,
			first_name: data.first_name,
			last_name: data.last_name,
			email: data.email,
			business: {
				id: 0,
				user_id: 0,
				name: data.name,
				logo: data.logo,
				industry: data.industry,
				address_line1: data.address_line1,
				address_line2: data.address_line2,
				city: data.city,
				state: data.state,
				pin_code: data.pin_code,
				phone: data.phone,
				gstin: data.gstin,
				latitude: data.latitude,
				longitude: data.longitude,
				status: data.status,
				contract: {
					id: 0,
					business_id: 0,
					bill_type: data.bill_type,
					flat_rate_monthly_inr: data.flat_rate_monthly_inr || '',
					contract_file: data.contract_file,
				},
				communication: {
					id: 0,
					business_id: 0,
					additional_emails: emails,
					auto_approve: data.auto_approve || 0,
				},
				business_products: data.products,
			},
		};
		// console.log(newCustomer);
		const accessToken = await getAccessToken();
		dispatch(createCustomer(newCustomer, accessToken.token));
		setValue('products', []);
		setPictureUrl(initialPictureUrl);
	};

	// console.log(fields);
	useEffect(() => {
		setLoading(false);
		if (createCustomerMessage?.id) {
			setShowToast(true);
			setToastMessage('Customer Added Successfully');
			reset();
			history.push('/app/customers');
			// console.log('Customer Added Successfully');
			// console.log(createCustomerMessage);
		}
		if (createCustomerError) {
			//if any error happened
			setShowToast(true);
			setToastMessage('Something went wrong');
		}
	}, [createCustomerMessage, createCustomerError]);

	// useEffect(() => {
	// 	reset();
	// }, [createCustomerMessage]);

	useEffect(() => {
		setValue('logo', null);
		setValue('contract_file', '');
	}, []);

	const {
		getAllProductLoading,
		getAllProductMessage: allProducts,
		getAllProductError,
	} = useSelector((state: any) => state.getAllProducts);

	useEffect(() => {
		const tokenObject = getAccessToken();
		if (allProducts.length) {
			const activeProducts = allProducts.filter(
				product => product.status === 'active'
			);
			setAllActiveProducts(activeProducts);
			setUnSelectedProducts(activeProducts);
		} else {
			dispatch(getAllProduct(tokenObject.token));
		}
	}, [allProducts]);
	// console.log(getAllProductError);

	// console.log(unSelectedProducts);
	// console.log(fields);

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start">
						<IonIcon
							size="large"
							onClick={e => history.goBack()}
							icon={arrowBackIcon}
							style={{
								cursor: 'pointer',
							}}
						/>
					</IonButtons>
					<IonTitle>Add Customer</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent className="ion-padding">
				<form
					onSubmit={handleSubmit(onSubmit)}
					className="responsive-width border"
				>
					<section className="ion-padding-top ion-padding-horizontal">
						<IonCard className="ion-no-margin ion-no-padding">
							<IonItem lines="none">
								{/* <IonIcon size="small" icon={person}></IonIcon> */}
								<IonText>
									<h5>Personal Details</h5>
								</IonText>
							</IonItem>
						</IonCard>
						{/* For First Name */}
						<IonItem className="ion-margin-top">
							<IonLabel position="stacked">First Name</IonLabel>
							<IonInput
								placeholder="Enter your first Name"
								{...register('first_name', {
									// required: 'First name is required',
									// pattern: {
									// 	value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
									// 	message: 'invalid email address',
									// },
								})}
							/>
						</IonItem>
						{/* For First Name Errors */}
						<ErrorMessage
							errors={errors}
							name="first_name"
							render={({ message }) => (
								<IonText
									color="danger"
									className="ion-margin-horizontal"
									style={{ fontSize: 12 + 'px', marginTop: 0 + 'px' }}
								>
									{message}
								</IonText>
							)}
						/>
						<br />
						{/* For Last Name */}
						<IonItem className="ion-margin-top">
							<IonLabel position="stacked">Last Name</IonLabel>
							<IonInput
								placeholder="Enter your last Name"
								{...register('last_name', {
									// required: 'Last name is required',
									// pattern: {
									// 	value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
									// 	message: 'invalid email address',
									// },
								})}
							/>
						</IonItem>
						<ErrorMessage
							errors={errors}
							name="last_name"
							render={({ message }) => (
								<IonText
									color="danger"
									className="ion-margin-horizontal"
									style={{ fontSize: 12 + 'px', marginTop: 0 + 'px' }}
								>
									{message}
								</IonText>
							)}
						/>
						<br />
						<IonItem className="ion-margin-top">
							<IonLabel position="stacked">Email</IonLabel>
							<IonInput
								placeholder="Enter your email address"
								{...register('email', {
									// required: 'Email is required',
									// pattern: {
									// 	value: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
									// 	message: 'invalid email address',
									// },
								})}
							/>
						</IonItem>
						<ErrorMessage
							errors={errors}
							name="email"
							render={({ message }) => (
								<IonText
									color="danger"
									className="ion-margin-horizontal"
									style={{ fontSize: 12 + 'px', marginTop: 0 + 'px' }}
								>
									{message}
								</IonText>
							)}
						/>
					</section>
					{/* For Business Details */}
					<section className="ion-padding">
						<IonCard className="ion-no-margin ion-no-padding">
							<IonItem lines="none">
								{/* <IonIcon size="small" icon={person}></IonIcon> */}
								<IonText>
									<h5>Business Details</h5>
								</IonText>
							</IonItem>
						</IonCard>
						{/* For  Name */}
						<IonItem className="ion-margin-top">
							<IonLabel position="stacked">Business Name</IonLabel>
							<IonInput
								placeholder="Enter your Business Name"
								{...register('name', {
									// required: 'Business name is required',
									// pattern: {
									// 	value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
									// 	message: 'invalid email address',
									// },
								})}
							/>
						</IonItem>
						{/* For  Name Errors */}
						<ErrorMessage
							errors={errors}
							name="name"
							render={({ message }) => (
								<IonText
									color="danger"
									className="ion-margin-horizontal"
									style={{ fontSize: 12 + 'px', marginTop: 0 + 'px' }}
								>
									{message}
								</IonText>
							)}
						/>
						<br />
						<IonRow className="ion-align-items-center ion-margin-horizontal">
							<IonCol sizeMd="2" sizeSm="6" sizeXs="6">
								<IonLabel>Logo (square image)</IonLabel>
							</IonCol>
							<IonCol sizeMd="2" sizeSm="6" sizeXs="6">
								<div className="image-upload">
									<input
										hidden
										type="file"
										accept="image/*"
										{...rest}
										ref={e => {
											logoRef(e);
											logoInputRef.current = e;
										}}
										onChange={handleLogoChange}
									/>
									<img width="80" height="80" src={pictureUrl} alt=""></img>
								</div>
								<ErrorMessage
									errors={errors}
									name="logo"
									render={({ message }) => (
										<IonText
											color="danger"
											className=""
											style={{ fontSize: 12 + 'px' }}
										>
											{message}
										</IonText>
									)}
								/>
							</IonCol>
							<IonCol>
								<IonButton
									color="dark"
									size="small"
									shape="round"
									onClick={handlePictureClick}
								>
									Update Image
								</IonButton>
							</IonCol>
						</IonRow>

						{/* For Industry  */}
						<IonItem>
							<IonLabel>Industry</IonLabel>
							<Controller
								name="industry"
								render={({ field }) => (
									<IonSelect
										placeholder="Select One"
										value={field.value}
										onIonChange={e => {
											field.onChange(e.detail.value);
											// console.log(e.detail.value);
											// setValue('industry', e.detail.value);
										}}
									>
										<IonSelectOption value="hospitals">
											Hospitals
										</IonSelectOption>
										<IonSelectOption value="hotels">Hotels</IonSelectOption>
										<IonSelectOption value="factory">Factory</IonSelectOption>
										<IonSelectOption value="hostels">Hostels</IonSelectOption>
										<IonSelectOption value="restaurant">
											Restaurant
										</IonSelectOption>
										<IonSelectOption value="schools">Schools</IonSelectOption>
										<IonSelectOption value="theater">Theater</IonSelectOption>
										<IonSelectOption value="guestHouse">
											Guest House
										</IonSelectOption>
										<IonSelectOption value="ashram">Ashram</IonSelectOption>
									</IonSelect>
								)}
								control={control}
								// rules={{ required: 'Industry is required' }}
							/>
						</IonItem>
						{/* For Industry Errors */}
						<ErrorMessage
							errors={errors}
							name="industry"
							render={({ message }) => (
								<IonText
									color="danger"
									className="ion-margin-horizontal"
									style={{ fontSize: 12 + 'px', marginTop: 0 + 'px' }}
								>
									{message}
								</IonText>
							)}
						/>
						<br />
						{/* For Address Line 1 */}
						<IonItem>
							<IonLabel position="stacked">Address</IonLabel>
							<IonInput
								placeholder="Address Line 1"
								{...register('address_line1', {
									// required: 'Address is required',
								})}
							/>
						</IonItem>
						{/* For Address Line 1 Errors */}
						<ErrorMessage
							errors={errors}
							name="address_line1"
							render={({ message }) => (
								<IonText
									color="danger"
									className="ion-margin-horizontal"
									style={{ fontSize: 12 + 'px', marginTop: 0 + 'px' }}
								>
									{message}
								</IonText>
							)}
						/>
						<br />
						{/* For Address Line 2 */}
						<IonItem>
							{/* <IonLabel position="stacked">Address</IonLabel> */}
							<IonInput
								placeholder="Address Line 2"
								{...register('address_line2')}
							/>
						</IonItem>
						<br />
						{/* For City */}
						<IonItem>
							<IonLabel position="stacked">City</IonLabel>
							<IonInput
								placeholder="Enter City"
								{...register('city', {
									// required: 'City is required',
								})}
							/>
						</IonItem>
						{/* For City Errors */}
						<ErrorMessage
							errors={errors}
							name="city"
							render={({ message }) => (
								<IonText
									color="danger"
									className="ion-margin-horizontal"
									style={{ fontSize: 12 + 'px', marginTop: 0 + 'px' }}
								>
									{message}
								</IonText>
							)}
						/>
						<br />
						{/* For State */}
						<IonItem>
							<IonLabel position="stacked">State</IonLabel>
							<IonInput
								placeholder="Enter State"
								{...register('state', {
									// required: 'State is required',
								})}
							/>
						</IonItem>
						{/* For State Errors */}
						<ErrorMessage
							errors={errors}
							name="state"
							render={({ message }) => (
								<IonText
									color="danger"
									className="ion-margin-horizontal"
									style={{ fontSize: 12 + 'px', marginTop: 0 + 'px' }}
								>
									{message}
								</IonText>
							)}
						/>
						<br />
						{/* For Pin Code */}
						<IonItem>
							<IonLabel position="stacked">Pin</IonLabel>
							<IonInput
								placeholder="Enter Pin"
								{...register('pin_code', {
									// required: 'Pin Code  is required',
								})}
							/>
						</IonItem>
						{/* For State Errors */}
						<ErrorMessage
							errors={errors}
							name="pin_code"
							render={({ message }) => (
								<IonText
									color="danger"
									className="ion-margin-horizontal"
									style={{ fontSize: 12 + 'px', marginTop: 0 + 'px' }}
								>
									{message}
								</IonText>
							)}
						/>
						<br />
						{/* For Phone */}
						<IonItem>
							<IonLabel position="stacked">Phone</IonLabel>
							<IonInput
								placeholder="Enter Phone"
								{...register('phone', {
									// required: 'Phone number is required',
								})}
							/>
						</IonItem>
						{/* For State Errors */}
						<ErrorMessage
							errors={errors}
							name="phone"
							render={({ message }) => (
								<IonText
									color="danger"
									className="ion-margin-horizontal"
									style={{ fontSize: 12 + 'px', marginTop: 0 + 'px' }}
								>
									{message}
								</IonText>
							)}
						/>
						<br />
						{/* For GST IDentification Number */}
						<IonItem>
							<IonLabel position="stacked">GSTIN</IonLabel>
							<IonInput
								placeholder="Enter GST Identification Number"
								{...register('gstin', {
									// required: 'GST Identification Number is required',
								})}
							/>
						</IonItem>
						{/* For GST IDentification Number Errors */}
						<ErrorMessage
							errors={errors}
							name="gstin"
							render={({ message }) => (
								<IonText
									color="danger"
									className="ion-margin-horizontal"
									style={{ fontSize: 12 + 'px', marginTop: 0 + 'px' }}
								>
									{message}
								</IonText>
							)}
						/>
						<br />
						{/* For Latitude */}
						<IonItem>
							<IonLabel position="stacked">Latitude</IonLabel>
							<IonInput placeholder="Latitude" {...register('latitude', {})} />
						</IonItem>

						<ErrorMessage
							errors={errors}
							name="latitude"
							render={({ message }) => (
								<IonText
									color="danger"
									className="ion-margin-horizontal"
									style={{ fontSize: 12 + 'px', marginTop: 0 + 'px' }}
								>
									{message}
								</IonText>
							)}
						/>
						<br />
						{/* For Longitude */}
						<IonItem>
							<IonLabel position="stacked">Longitude</IonLabel>
							<IonInput placeholder="Longitude" {...register('longitude')} />
						</IonItem>
						<ErrorMessage
							errors={errors}
							name="longitude"
							render={({ message }) => (
								<IonText
									color="danger"
									className="ion-margin-horizontal"
									style={{ fontSize: 12 + 'px', marginTop: 0 + 'px' }}
								>
									{message}
								</IonText>
							)}
						/>
						<br />
						{/* For status */}
						<IonItem className="ion-margin-top">
							<IonLabel>Status</IonLabel>
							<Controller
								render={({ field }) => (
									<IonSelect
										placeholder="Select One"
										value={field.value}
										onIonChange={e => {
											// console.log(e.detail.value);
											field.onChange(e.detail.value);
											// setValue('status', e.detail.value);
										}}
									>
										<IonSelectOption value="active">Active</IonSelectOption>
										<IonSelectOption value="inactive">Inactive</IonSelectOption>
									</IonSelect>
								)}
								control={control}
								name="status"
								rules={{ required: 'Status is required' }}
							/>
						</IonItem>
						<ErrorMessage
							errors={errors}
							name="status"
							render={({ message }) => (
								<IonText
									color="danger"
									className="ion-margin-horizontal"
									style={{ fontSize: 12 + 'px', marginTop: 0 + 'px' }}
								>
									{message}
								</IonText>
							)}
						/>
						{/* <LoadScript googleMapsApiKey="AIzaSyDbix6IhpOdD9FO2DlmCFPCp3OrGgtbOvE">
							<GoogleMap
								onClick={placeMarker}
								mapContainerStyle={containerStyle}
								center={{
									lat: latitude ? latitude : defaultLat,
									lng: longitude ? longitude : defaultLong,
								}}
								zoom={latitude && longitude ? 14 : 7}
							>
								{latitude && longitude ? (
									<Marker
										position={{
											lat: latitude,
											lng: longitude,
										}}
									/>
								) : (
									''
								)}
								<></>
							</GoogleMap>
						</LoadScript> */}
					</section>
					{/* For Contract Details */}
					<section className="ion-padding-horizontal">
						<IonCard className="ion-no-margin ion-no-padding">
							<IonItem lines="none">
								{/* <IonIcon size="small" icon={person}></IonIcon> */}
								<IonText>
									<h5>Contract Details</h5>
								</IonText>
							</IonItem>
						</IonCard>
						{/* For Bill Type  */}
						<IonItem className="ion-margin-top">
							<IonLabel>Bill Type</IonLabel>
							<Controller
								render={({ field }) => (
									<IonSelect
										placeholder="Select One"
										value={field.value}
										onIonChange={e => {
											// console.log(e.detail.value);
											setIsBillRateVisible(e.detail.value === 'flat');
											field.onChange(e.detail.value);
											// setValue('bill_type', e.detail.value);
										}}
									>
										<IonSelectOption value="flat">Flat</IonSelectOption>
										<IonSelectOption value="per-kg">Per KG</IonSelectOption>
										<IonSelectOption value="per-piece">
											Per Piece
										</IonSelectOption>
									</IonSelect>
								)}
								control={control}
								name="bill_type"
								// rules={{ required: 'Bill Type is required' }}
							/>
						</IonItem>
						{/* For Bill Type Errors */}
						<ErrorMessage
							errors={errors}
							name="bill_type"
							render={({ message }) => (
								<IonText
									color="danger"
									className="ion-margin-horizontal"
									style={{ fontSize: 12 + 'px', marginTop: 0 + 'px' }}
								>
									{message}
								</IonText>
							)}
						/>
						<br />
						{/* For Bill Rate */}
						{isBillRateVisible && (
							<>
								<IonItem>
									<IonLabel position="stacked">Flat Bill Rate</IonLabel>
									<IonInput
										placeholder="Enter amount/month"
										{...register('flat_rate_monthly_inr', {
											// required: 'Bill Rate is required',
										})}
									/>
								</IonItem>
								<ErrorMessage
									errors={errors}
									name="flat_rate_monthly_inr"
									render={({ message }) => (
										<IonText
											color="danger"
											className="ion-margin-horizontal"
											style={{ fontSize: 12 + 'px', marginTop: 0 + 'px' }}
										>
											{message}
										</IonText>
									)}
								/>
							</>
						)}
						{/* For Bill Rate Errors */}

						<br />
						{/* For Contract File */}

						<IonRow className="ion-align-items-center ion-margin-horizontal ion-margin-bottom">
							<IonCol sizeMd="4" sizeSm="6">
								<IonLabel position="stacked">Contract File</IonLabel>
							</IonCol>
							<IonCol sizeMd="3" sizeSm="6">
								<input
									type="file"
									hidden
									accept=".pdf"
									{...contractRest}
									ref={e => {
										contractRef(e);
										contractInputRef.current = e;
									}}
									onChange={handleContractChange}
								/>

								<IonButton
									size="small"
									shape="round"
									onClick={() => contractInputRef.current.click()}
								>
									<IonText color={'light'}>Upload File</IonText>
								</IonButton>

								{/* <ErrorMessage
									errors={errors}
									name="contract_file"
									render={({ message }) => (
										<IonText
											color="danger"
											className="ion-padding-horizontal"
											style={{
												fontSize: 12 + 'px',
											}}
										>
											{message}
										</IonText>
									)}
								/> */}
							</IonCol>
							<IonCol sizeMd="5" sizeSm="12" sizeXs="12">
								{contractFile && (
									<ContractCard
										removeFile={removeFile}
										contractFile={contractFile}
										// progress={progress}
									/>
								)}
							</IonCol>
						</IonRow>
					</section>
					<section className="ion-padding-horizontal">
						<IonCard className="ion-no-margin ion-no-padding">
							<IonItem lines="none">
								{/* <IonIcon size="small" icon={person}></IonIcon> */}
								<IonText>
									<h5>Communications Details</h5>
								</IonText>
							</IonItem>
						</IonCard>
						<IonGrid className="ion-margin-vertical">
							<IonRow
								style={{ width: '100%' }}
								className="ion-align-items-center ion-margin-horizontal"
							>
								<IonCol sizeMd="4">
									<IonLabel position="fixed" style={{ width: '100%' }}>
										Additional Emails
									</IonLabel>
								</IonCol>
								<IonCol sizeMd="8" className="ion-interactive">
									<Controller
										name="additional_emails"
										control={control}
										defaultValue={[]}
										render={({
											field: { onChange, onBlur, name, value, ref },
										}) => (
											<ReactTags
												placeholder="Press enter to add new email"
												tags={value}
												delimiters={delimiters}
												handleDelete={i => {
													const newTags = value.filter(
														(tag, index) => index !== i
													);

													onChange(newTags);
												}}
												handleAddition={tag => {
													const newTags = [...value, tag];
													onChange(newTags);
												}}
												inputFieldPosition="bottom"
												autocomplete
											/>
										)}
									/>

									<ErrorMessage
										errors={errors}
										name="additional_emails"
										render={({ message }) => {
											/**
											 * Calculates the error message for additional emails based on the provided errors object.
											 * If there are errors in the additional_emails array, it returns the corresponding error message.
											 * Otherwise, it returns the default message.
											 *
											 * @param {Object} errors - The errors object containing additional_emails array.
											 * @param {string} message - The default error message.
											 * @returns {string} - The calculated error message.
											 */
											const findErrorMessage = (errors, message) => {
												const additionalEmailsError =
													Array.isArray(errors.additional_emails) &&
													errors.additional_emails.some(item => item.text);
												const errorItemId =
													Array.isArray(errors.additional_emails) &&
													errors.additional_emails.findIndex(
														item => item?.text
													);
												let errorMessage = additionalEmailsError
													? errors.additional_emails[errorItemId].text.message
													: message;

												return errorMessage;
											};

											return (
												<IonText
													color="danger"
													className="ion-padding-horizontal"
													style={{
														fontSize: 12 + 'px',
														marginLeft: -12 + 'px',
													}}
												>
													{findErrorMessage(errors, message)}
												</IonText>
											);
										}}
									/>
								</IonCol>
							</IonRow>
							<IonRow>
								<IonCol sizeMd="4">
									<IonItem lines="none">
										<IonLabel>Auto Approve</IonLabel>
									</IonItem>
								</IonCol>
								<IonCol>
									<IonItem lines="none">
										<Controller
											name="auto_approve"
											control={control}
											render={({ field }) => {
												return (
													<IonCheckbox
														slot="start"
														checked={!!field.value}
														onIonChange={e => {
															field.onChange(Number(e.detail.checked));
														}}
													/>
												);
											}}
										/>
									</IonItem>
								</IonCol>
							</IonRow>
						</IonGrid>
					</section>
					<section className="ion-padding-horizontal">
						<IonCard className="ion-no-margin ion-no-padding">
							<IonItem lines="none">
								<IonText>
									<h5>Serviced Product</h5>
								</IonText>
							</IonItem>
						</IonCard>
						<div
							style={{
								overflowX: 'auto',
								overflowY: 'hidden',
							}}
						>
							<IonGrid
								className="ion-margin-top "
								style={{
									color: '#353535',
									width: 1120 + 'px',
									overflowX: 'auto',
									paddingBottom: 10 + 'px',
								}}
							>
								<IonRow
									className="ionrow-style ion-align-items-center ion-text-center"
									style={{ backgroundColor: 'white', padding: 10 + 'px' }}
								>
									<IonCol className="ion-col-style" size="1">
										<b>Switch</b>
									</IonCol>
									<IonCol className="ion-col-style" size="1.2">
										<b>Image</b>
									</IonCol>
									<IonCol className="ion-col-style" size="2">
										<b>Name</b>
									</IonCol>
									<IonCol className="ion-col-style" size="2">
										<b>Internal notes</b>
									</IonCol>
									<IonCol className="ion-col-style" size="1.2">
										<b>Dimensions</b>
									</IonCol>
									<IonCol className="ion-col-style" size="1.2">
										<b>Weight/Pc</b>
									</IonCol>
									<IonCol className="ion-col-style" size="1.5">
										<b>Laundry Price/Pc</b>
									</IonCol>
									<IonCol className="ion-col-style" size="1.9">
										<b>Laundry Price/Kg</b>
									</IonCol>
								</IonRow>
								{fields?.map((product, index) => (
									<EditableProductRow
										key={product?.id}
										product={product}
										index={index}
										register={register}
										handleToggle={handleToggle}
									/>
								))}
								{unSelectedProducts.map(product => (
									<ToggleProductRow
										key={product?.id}
										handleToggle={handleToggle}
										product={product}
									/>
								))}
							</IonGrid>
						</div>
					</section>
					<IonGrid className="ion-text-center ion-margin-vertical">
						<IonButton shape="round" type="submit">
							<IonText color={'light'}>Add Customer</IonText>
						</IonButton>
					</IonGrid>
				</form>
				<IonToast
					isOpen={showToast}
					message={toastMessage}
					onDidDismiss={() => {
						setToastMessage('');
						setShowToast(false);
					}}
					duration={2000}
					position="top"
				></IonToast>
				<IonLoading isOpen={loading} message="Uploading.." />
				<IonLoading
					isOpen={createCustomerLoading || getAllProductLoading}
					message="Please Wait.."
				/>
			</IonContent>
		</IonPage>
	);
};

export default AddCustomer;
