import {
	IonButton,
	IonButtons,
	IonCard,
	IonCheckbox,
	IonCol,
	IonContent,
	IonGrid,
	IonHeader,
	IonIcon,
	IonInput,
	IonItem,
	IonLabel,
	IonLoading,
	IonPage,
	IonRow,
	IonSelect,
	IonSelectOption,
	IonText,
	IonTitle,
	IonToast,
	IonToolbar,
} from '@ionic/react';
import { WithContext as ReactTags } from 'react-tag-input';
import { arrowBack as arrowBackIcon } from 'ionicons/icons';
import React, { useEffect, useRef, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router';
import { CustomerFormInputs, CustomerSchema } from './AddCustomer';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessage } from '@hookform/error-message';
import secureAxios from '../../utils/SecureAxios';

import saveFile from '../../utils/SaveFile';
import EditableProductRow from '../../components/customer/EditableProductRow';
import { useDispatch, useSelector } from 'react-redux';
import { getAllProduct } from '../../actions/productActions';
import { getAccessToken } from '../../services/auth/userLocalStorage';
import ToggleProductRow from '../../components/customer/ToggleProductRow';
import { getCustomerById, updateCustomer } from '../../actions/customerAction';
const KeyCodes = {
	comma: 188,
	enter: [10, 13],
	space: 32,
};

const delimiters = [...KeyCodes.enter, KeyCodes.comma, KeyCodes.space];
const UpdateCustomer: React.FC = () => {
	const [showToast, setShowToast] = useState(false);
	const [toastMessage, setToastMessage] = useState('');
	const [isEdited, setIsEdited] = useState(false);
	const [loading, setLoading] = useState(true);
	const [pictureUrl, setPictureUrl] = useState();
	const params: { id: string } = useParams();
	const [unSelectedProducts, setUnSelectedProducts] = useState<any>([]);
	const [contractFileUrl, setContractFileUrl] = useState();
	const [isBillRateVisible, setIsBillRateVisible] = useState<Boolean>(false);

	const { editCustomerMessage, editCustomerError, editCustomerLoading } =
		useSelector((state: any) => state.updateCustomer);

	const activeProductsRef = useRef(null);

	const history = useHistory();
	const {
		handleSubmit,
		control,
		setValue,
		register,
		// getValues,
		reset,
		formState: { errors },
	} = useForm<CustomerFormInputs>({ resolver: yupResolver(CustomerSchema) });
	const logoInputRef = useRef<HTMLInputElement>();
	// console.log(errors);
	const {
		ref: logoRef,
		// onChange: logoOnChange,
		...rest
	} = register('logo', {});

	const handleLogoChange = async (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setLoading(true);
		// Check if a file has been selected
		if (event.target?.files?.length > 0) {
			// Get the first file from the selected files
			const file = event.target.files.item(0);

			// Update the value of the logo input
			const myPlatformPath = await saveFile(file, 'customer-logo-image');
			setLoading(false);
			setValue('logo', myPlatformPath);
			// Generate a URL for the selected file
			const pictureUrl = URL.createObjectURL(file);
			console.log(pictureUrl);

			// Set the picture URL
			// setPictureUrl(pictureUrl);
		}
	};
	const { currentUser } = useSelector((state: any) => state.currentUser);
	useEffect(() => {
		const accessToken = getAccessToken();

		if (accessToken && currentUser && currentUser.data && params.id) {
			dispatch(getCustomerById(params.id, accessToken.token));
		}
	}, [currentUser, params?.id, editCustomerMessage]);
	const { customerById, customerByIdLoading, customerByIdError } = useSelector(
		(state: any) => state.loadCustomerById
	);
	//
	// console.log(customer?.business?.industry);
	// console.log(customerById);
	useEffect(() => {
		if (!customerByIdLoading && customerById?.id) {
			// const response = await secureAxios(`customers/${params.id}`);
			// const customer = response.data.data;
			const additional_emails = customerById?.communication?.additional_emails
				?.split(',')
				.map((item: any) => ({
					id: item,
					text: item,
				}));
			// console.log(additional_emails);
			// setCustomer(response.data.data);
			// console.log(customer);

			setValue('first_name', customerById.first_name);
			setValue('last_name', customerById.last_name);
			setValue('email', customerById.email);
			setValue('industry', customerById.business.industry);
			setValue('name', customerById.business.name);
			setValue('address_line1', customerById.business.address_line1);
			setValue('address_line2', customerById.business.address_line2);
			setValue('city', customerById.business.city);
			setValue('state', customerById.business.state);
			setValue('pin_code', customerById.business.pin_code);
			setValue('phone', customerById.business.phone);
			setValue('gstin', customerById.business.gstin);
			setValue('latitude', customerById.business.latitude);
			setValue('logo', customerById.business.logo);
			setValue('longitude', customerById.business.longitude);
			setValue('status', customerById.business.status);

			setValue('bill_type', customerById.contract.bill_type);
			setValue(
				'flat_rate_monthly_inr',
				customerById?.contract.flat_rate_monthly_inr || ''
			);

			setValue('additional_emails', additional_emails);
			setValue('auto_approve', customerById.communication.auto_approve);
			// console.log(customerById.business.business_products);
			setValue('products', customerById.business_products);
			setPictureUrl(customerById.business.logo_display_url);
			setContractFileUrl(customerById.contract.contract_file_display_url);
			setValue('contract_file', customerById.contract?.contract_file || '');
			setLoading(false);
			// setValue('');
			// console.log(customerById.business.bill_type);
			// setValue('additional_emails',)
		}
	}, [customerByIdLoading, customerById?.id]);

	// console.log(errors);
	// console.log(contractFileUrl);
	const onSubmit = async (data: any) => {
		// console.log(data);
		const emails =
			data?.additional_emails
				?.map((data: { text: any }) => data.text)
				.join(',') || '';
		const accessToken = await getAccessToken();
		// console.log(customerById);
		const updatedCustomer = {
			id: customerById.id,
			first_name: data.first_name,
			last_name: data.last_name,
			email: data.email,
			business: {
				id: customerById.business.id,
				user_id: 0,
				name: data.name,
				logo: data.logo,
				industry: data.industry,
				address_line1: data.address_line1,
				address_line2: data.address_line2,
				city: data.city,
				state: data.state,
				pin_code: data.pin_code,
				phone: data.phone,
				gstin: data.gstin,
				latitude: data.latitude,
				longitude: data.longitude,
				status: data.status,
				contract: {
					id: customerById.contract.id,
					business_id: customerById.contract.business_id,
					bill_type: data.bill_type,
					flat_rate_monthly_inr: data.flat_rate_monthly_inr,
					contract_file: data.contract_file,
				},
				communication: {
					id: customerById.communication.id,
					business_id: customerById.communication.business_id,
					additional_emails: emails,
					auto_approve: data.auto_approve,
				},
				business_products: data.products,
			},
		};
		setIsEdited(true);
		// console.log(updatedCustomer);
		dispatch(updateCustomer(updatedCustomer, accessToken.token));
	};

	useEffect(() => {
		setLoading(false);

		if (
			editCustomerMessage?.id &&
			editCustomerMessage?.id === parseInt(params.id) &&
			isEdited
		) {
			//if product added successfully
			setShowToast(true);
			setToastMessage('Customer Updated Successfully');
			history.push('/app/customers');
		}
		if (editCustomerError) {
			//if any error happened
			setShowToast(true);
			setToastMessage('Something went wrong');
		}
	}, [editCustomerMessage, editCustomerError, params.id]);
	// console.log(params.id);

	const { fields, remove, append } = useFieldArray({
		control,
		name: 'products',
	});

	const {
		getAllProductLoading,
		getAllProductMessage: allProducts,
		getAllProductError,
	} = useSelector((state: any) => state.getAllProducts);

	// console.log(allProducts);
	const dispatch = useDispatch();
	// console.log(fields);
	useEffect(() => {
		const tokenObject = getAccessToken();
		if (allProducts.length) {
			const activeProducts = allProducts.filter(
				(product: any) => product.status === 'active'
			);
			activeProductsRef.current = activeProducts;

			// console.log(fields);
			// console.log(activeProducts);
			const filteredProducts = activeProducts.filter(
				(product: any) =>
					!fields.some((field: any) => field.product_id === product.id)
			);
			// console.log(filteredProducts);
			setUnSelectedProducts(filteredProducts);
			// setValue('products', filteredProducts);
		} else {
			dispatch(getAllProduct(tokenObject.token));
		}
	}, [allProducts, fields]);

	// console.log(getAllProductLoading);
	const activeProducts = activeProductsRef.current;

	const handleToggle = (id: string, isSelected: boolean) => {
		if (isSelected) {
			//if toggle checked then append the product

			// select only unselected product by filtering the selected product
			setUnSelectedProducts(
				unSelectedProducts.filter((product: any) => product.id !== id)
			);
			append({
				...activeProducts.find((product: any) => product.id === id),
				product_id: id,
			});
		} else {
			// 	//if toggle unchecked remove the product

			// 	// set the unselected product in unselected list
			setUnSelectedProducts([
				...unSelectedProducts,
				activeProducts.find(product => product.id === id),
			]);
			// 	// console.log(fields.findIndex((pd: any) => pd.productId === id));
			remove(fields.findIndex((pd: any) => pd.product_id === id));
		}
	};
	
	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start">
						<IonIcon
							size="large"
							onClick={e => history.goBack()}
							icon={arrowBackIcon}
							style={{
								cursor: 'pointer',
							}}
						/>
					</IonButtons>
					<IonTitle>Update Customer</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent className="ion-padding">
				<form
					onSubmit={handleSubmit(onSubmit)}
					className="responsive-width border"
				>
					<section className="ion-padding-top ion-padding-horizontal">
						<IonCard className="ion-no-margin ion-no-padding">
							<IonItem lines="none">
								{/* <IonIcon size="small" icon={person}></IonIcon> */}
								<IonText>
									<h5>Personal Details</h5>
								</IonText>
							</IonItem>
						</IonCard>
						{/* For First Name */}
						<IonItem className="ion-margin-top">
							<IonLabel position="stacked">First Name</IonLabel>
							<IonInput
								placeholder="Enter your first Name"
								{...register('first_name', {})}
							/>
						</IonItem>
						{/* For First Name Errors */}
						<ErrorMessage
							errors={errors}
							name="first_name"
							render={({ message }) => (
								<IonText
									color="danger"
									className="ion-margin-horizontal"
									style={{ fontSize: 12 + 'px', marginTop: 0 + 'px' }}
								>
									{message}
								</IonText>
							)}
						/>
						<br />
						{/* For Last Name */}
						<IonItem className="ion-margin-top">
							<IonLabel position="stacked">Last Name</IonLabel>
							<IonInput
								placeholder="Enter your last Name"
								{...register('last_name', {})}
							/>
						</IonItem>
						<ErrorMessage
							errors={errors}
							name="last_name"
							render={({ message }) => (
								<IonText
									color="danger"
									className="ion-margin-horizontal"
									style={{ fontSize: 12 + 'px', marginTop: 0 + 'px' }}
								>
									{message}
								</IonText>
							)}
						/>
						<br />
						<IonItem className="ion-margin-top">
							<IonLabel position="stacked">Email</IonLabel>
							<IonInput
								readonly
								placeholder="Enter your email address"
								{...register('email')}
							/>
						</IonItem>
						<ErrorMessage
							errors={errors}
							name="email"
							render={({ message }) => (
								<IonText
									color="danger"
									className="ion-margin-horizontal"
									style={{ fontSize: 12 + 'px', marginTop: 0 + 'px' }}
								>
									{message}
								</IonText>
							)}
						/>
					</section>
					{/* For Business Details */}
					<section className="ion-padding">
						<IonCard className="ion-no-margin ion-no-padding">
							<IonItem lines="none">
								{/* <IonIcon size="small" icon={person}></IonIcon> */}
								<IonText>
									<h5>Business Details</h5>
								</IonText>
							</IonItem>
						</IonCard>
						{/* For  Name */}
						<IonItem className="ion-margin-top">
							<IonLabel position="stacked">Business Name</IonLabel>
							<IonInput
								placeholder="Enter your Business Name"
								{...register('name')}
							/>
						</IonItem>
						{/* For  Name Errors */}
						<ErrorMessage
							errors={errors}
							name="name"
							render={({ message }) => (
								<IonText
									color="danger"
									className="ion-margin-horizontal"
									style={{ fontSize: 12 + 'px', marginTop: 0 + 'px' }}
								>
									{message}
								</IonText>
							)}
						/>
						<br />
						{/* For Logo */}
						<IonRow className="ion-align-items-center ion-margin-horizontal">
							<IonCol sizeMd="2" sizeSm="6" sizeXs="6">
								<IonLabel>Logo (square image)</IonLabel>
							</IonCol>
							<IonCol sizeMd="2" sizeSm="6" sizeXs="6">
								<div className="image-upload">
									<input
										hidden
										type="file"
										accept="image/*"
										{...rest}
										ref={e => {
											logoRef(e);
											logoInputRef.current = e;
										}}
										onChange={handleLogoChange}
									/>
									<img width="80" height="80" src={pictureUrl} alt=""></img>
								</div>
								<ErrorMessage
									errors={errors}
									name="logo"
									render={({ message }) => (
										<IonText
											color="danger"
											className=""
											style={{ fontSize: 12 + 'px' }}
										>
											{message}
										</IonText>
									)}
								/>
							</IonCol>
							<IonCol>
								<IonButton
									color="dark"
									size="small"
									shape="round"
									// onClick={handlePictureClick}
								>
									Update Image
								</IonButton>
							</IonCol>
						</IonRow>

						{/* For Industry  */}
						<IonItem>
							<IonLabel>Industry</IonLabel>
							<Controller
								name="industry"
								render={({ field }) => (
									<IonSelect
										placeholder="Select One"
										value={field.value}
										onIonChange={e => {
											field.onChange(e.detail.value);
										}}
									>
										<IonSelectOption value="hospitals">
											Hospitals
										</IonSelectOption>
										<IonSelectOption value="hotels">Hotels</IonSelectOption>
										<IonSelectOption value="factory">Factory</IonSelectOption>
										<IonSelectOption value="hostels">Hostels</IonSelectOption>
										<IonSelectOption value="restaurant">
											Restaurant
										</IonSelectOption>
										<IonSelectOption value="schools">Schools</IonSelectOption>
										<IonSelectOption value="theater">Theater</IonSelectOption>
										<IonSelectOption value="guestHouse">
											Guest House
										</IonSelectOption>
										<IonSelectOption value="ashram">Ashram</IonSelectOption>
									</IonSelect>
								)}
								control={control}
							/>
						</IonItem>
						{/* For Industry Errors */}
						<ErrorMessage
							errors={errors}
							name="industry"
							render={({ message }) => (
								<IonText
									color="danger"
									className="ion-margin-horizontal"
									style={{ fontSize: 12 + 'px', marginTop: 0 + 'px' }}
								>
									{message}
								</IonText>
							)}
						/>
						<br />
						{/* For Address Line 1 */}
						<IonItem>
							<IonLabel position="stacked">Address</IonLabel>
							<IonInput
								// value={customer?.business?.address_line1}
								placeholder="Address Line 1"
								{...register('address_line1', {
									// required: 'Address is required',
								})}
							/>
						</IonItem>
						{/* For Address Line 1 Errors */}
						<ErrorMessage
							errors={errors}
							name="address_line1"
							render={({ message }) => (
								<IonText
									color="danger"
									className="ion-margin-horizontal"
									style={{ fontSize: 12 + 'px', marginTop: 0 + 'px' }}
								>
									{message}
								</IonText>
							)}
						/>
						<br />
						{/* For Address Line 2 */}
						<IonItem>
							{/* <IonLabel position="stacked">Address</IonLabel> */}
							<IonInput
								placeholder="Address Line 2"
								{...register('address_line2')}
							/>
						</IonItem>
						<br />
						{/* For City */}
						<IonItem>
							<IonLabel position="stacked">City</IonLabel>
							<IonInput
								placeholder="Enter City"
								{...register('city', {
									// required: 'City is required',
								})}
							/>
						</IonItem>
						{/* For City Errors */}
						<ErrorMessage
							errors={errors}
							name="city"
							render={({ message }) => (
								<IonText
									color="danger"
									className="ion-margin-horizontal"
									style={{ fontSize: 12 + 'px', marginTop: 0 + 'px' }}
								>
									{message}
								</IonText>
							)}
						/>
						<br />
						{/* For State */}
						<IonItem>
							<IonLabel position="stacked">State</IonLabel>
							<IonInput
								placeholder="Enter State"
								{...register('state', {
									// required: 'State is required',
								})}
							/>
						</IonItem>
						{/* For State Errors */}
						<ErrorMessage
							errors={errors}
							name="state"
							render={({ message }) => (
								<IonText
									color="danger"
									className="ion-margin-horizontal"
									style={{ fontSize: 12 + 'px', marginTop: 0 + 'px' }}
								>
									{message}
								</IonText>
							)}
						/>
						<br />
						{/* For Pin Code */}
						<IonItem>
							<IonLabel position="stacked">Pin</IonLabel>
							<IonInput
								placeholder="Enter Pin"
								{...register('pin_code', {
									// required: 'Pin Code  is required',
								})}
							/>
						</IonItem>
						{/* For State Errors */}
						<ErrorMessage
							errors={errors}
							name="pin_code"
							render={({ message }) => (
								<IonText
									color="danger"
									className="ion-margin-horizontal"
									style={{ fontSize: 12 + 'px', marginTop: 0 + 'px' }}
								>
									{message}
								</IonText>
							)}
						/>
						<br />
						{/* For Phone */}
						<IonItem>
							<IonLabel position="stacked">Phone</IonLabel>
							<IonInput
								placeholder="Enter Phone"
								{...register('phone', {
									// required: 'Phone number is required',
								})}
							/>
						</IonItem>
						{/* For State Errors */}
						<ErrorMessage
							errors={errors}
							name="phone"
							render={({ message }) => (
								<IonText
									color="danger"
									className="ion-margin-horizontal"
									style={{ fontSize: 12 + 'px', marginTop: 0 + 'px' }}
								>
									{message}
								</IonText>
							)}
						/>
						<br />
						{/* For GST IDentification Number */}
						<IonItem>
							<IonLabel position="stacked">GSTIN</IonLabel>
							<IonInput
								placeholder="Enter GST Identification Number"
								{...register('gstin', {
									// required: 'GST Identification Number is required',
								})}
							/>
						</IonItem>
						{/* For GST IDentification Number Errors */}
						<ErrorMessage
							errors={errors}
							name="gstin"
							render={({ message }) => (
								<IonText
									color="danger"
									className="ion-margin-horizontal"
									style={{ fontSize: 12 + 'px', marginTop: 0 + 'px' }}
								>
									{message}
								</IonText>
							)}
						/>
						<br />
						{/* For Latitude */}
						<IonItem>
							<IonLabel position="stacked">Latitude</IonLabel>
							<IonInput placeholder="Latitude" {...register('latitude', {})} />
						</IonItem>

						<ErrorMessage
							errors={errors}
							name="latitude"
							render={({ message }) => (
								<IonText
									color="danger"
									className="ion-margin-horizontal"
									style={{ fontSize: 12 + 'px', marginTop: 0 + 'px' }}
								>
									{message}
								</IonText>
							)}
						/>
						<br />
						{/* For Longitude */}
						<IonItem>
							<IonLabel position="stacked">Longitude</IonLabel>
							<IonInput placeholder="Longitude" {...register('longitude')} />
						</IonItem>
						<ErrorMessage
							errors={errors}
							name="longitude"
							render={({ message }) => (
								<IonText
									color="danger"
									className="ion-margin-horizontal"
									style={{ fontSize: 12 + 'px', marginTop: 0 + 'px' }}
								>
									{message}
								</IonText>
							)}
						/>
						<br />
						{/* For status */}
						<IonItem className="ion-margin-top">
							<IonLabel>Status</IonLabel>
							<Controller
								render={({ field: { value, onChange } }) => (
									<IonSelect
										placeholder="Select One"
										value={value}
										onIonChange={e => {
											// console.log(e.detail.value);
											onChange(e.detail.value);
											// setValue('status', e.detail.value);
										}}
									>
										<IonSelectOption value="active">Active</IonSelectOption>
										<IonSelectOption value="inactive">Inactive</IonSelectOption>
									</IonSelect>
								)}
								control={control}
								name="status"
								rules={{ required: 'Status is required' }}
							/>
						</IonItem>
						<ErrorMessage
							errors={errors}
							name="status"
							render={({ message }) => (
								<IonText
									color="danger"
									className="ion-margin-horizontal"
									style={{ fontSize: 12 + 'px', marginTop: 0 + 'px' }}
								>
									{message}
								</IonText>
							)}
						/>
						{/* <LoadScript googleMapsApiKey="AIzaSyDbix6IhpOdD9FO2DlmCFPCp3OrGgtbOvE">
                        <GoogleMap
                            onClick={placeMarker}
                            mapContainerStyle={containerStyle}
                            center={{
                                lat: latitude ? latitude : defaultLat,
                                lng: longitude ? longitude : defaultLong,
                            }}
                            zoom={latitude && longitude ? 14 : 7}
                        >
                            {latitude && longitude ? (
                                <Marker
                                    position={{
                                        lat: latitude,
                                        lng: longitude,
                                    }}
                                />
                            ) : (
                                ''
                            )}
                            <></>
                        </GoogleMap>
                    </LoadScript> */}
					</section>
					{/* For Contract Details */}
					<section className="ion-padding-horizontal">
						<IonCard className="ion-no-margin ion-no-padding">
							<IonItem lines="none">
								{/* <IonIcon size="small" icon={person}></IonIcon> */}
								<IonText>
									<h5>Contract Details</h5>
								</IonText>
							</IonItem>
						</IonCard>
						{/* For Bill Type  */}
						<IonItem className="ion-margin-top">
							<IonLabel>Bill Type</IonLabel>
							<Controller
								render={({ field }) => (
									<IonSelect
										placeholder="Select One"
										value={field.value}
										onIonChange={e => {
											if (e.detail.value !== 'flat') {
												setValue('flat_rate_monthly_inr', '');
											}
											setIsBillRateVisible(e.detail.value === 'flat');
											field.onChange(e.detail.value);
											// setValue('bill_type', e.detail.value);
										}}
									>
										<IonSelectOption value="flat">Flat</IonSelectOption>
										<IonSelectOption value="per-kg">Per KG</IonSelectOption>
										<IonSelectOption value="per-piece">
											Per Piece
										</IonSelectOption>
									</IonSelect>
								)}
								control={control}
								name="bill_type"
							/>
						</IonItem>
						{/* For Bill Type Errors */}
						<ErrorMessage
							errors={errors}
							name="bill_type"
							render={({ message }) => (
								<IonText
									color="danger"
									className="ion-margin-horizontal"
									style={{ fontSize: 12 + 'px', marginTop: 0 + 'px' }}
								>
									{message}
								</IonText>
							)}
						/>
						<br />
						{/* For Bill Rate */}
						{isBillRateVisible ? (
							<>
								<IonItem>
									<IonLabel position="stacked">Flat Bill Rate</IonLabel>
									<IonInput
										placeholder="Enter amount/month"
										{...register('flat_rate_monthly_inr', {
											// required: 'Bill Rate is required',
										})}
									/>
								</IonItem>
								{/* For Bill Rate Errors */}
								<ErrorMessage
									errors={errors}
									name="flat_rate_monthly_inr"
									render={({ message }) => (
										<IonText
											color="danger"
											className="ion-margin-horizontal"
											style={{ fontSize: 12 + 'px', marginTop: 0 + 'px' }}
										>
											{message}
										</IonText>
									)}
								/>
							</>
						) : (
							<></>
						)}

						<br />
						{/* For Contract File */}
						{/* <IonGrid className="ion-margin-vertical">
							<IonRow
								style={{ width: '100%' }}
								className="ion-align-items-center "
							>
								<IonCol sizeMd="4">
									<IonLabel position="fixed" style={{ width: '100%' }}>
										Additional Emails
									</IonLabel>
								</IonCol>
								<IonCol sizeMd="8" className="ion-interactive">
									<IonContent scrollX={true}>
										<Document
											file={contractFileUrl}
											onLoadSuccess={onDocumentLoadSuccess}
										>
											<Page pageNumber={pageNumber} />
										</Document>
									</IonContent>
								</IonCol>
							</IonRow>
						</IonGrid> */}

						{/* 
						<IonRow className="ion-align-items-center ion-margin-horizontal ion-margin-bottom">
							<IonCol sizeMd="4">
								<IonLabel position="stacked">Contract File</IonLabel>
							</IonCol>
							<IonCol sizeMd="8">
								<input
									type="file"
									hidden
									accept=".pdf"
									{...contractRest}
									ref={e => {
										contractRef(e);
										contractInputRef.current = e;
									}}
									onChange={handleContractChange}
								/>

								<IonButton
									size="small"
									shape="round"
									onClick={() => contractInputRef.current.click()}
								>
									Upload File
								</IonButton>
								<ErrorMessage
									errors={errors}
									name="contract_file"
									render={({ message }) => (
										<IonText
											color="danger"
											className="ion-padding-horizontal"
											style={{
												fontSize: 12 + 'px',
											}}
										>
											{message}
										</IonText>
									)}
								/>
							</IonCol>
						</IonRow> */}
					</section>
					<section className="ion-padding-horizontal">
						<IonCard className="ion-no-margin ion-no-padding">
							<IonItem lines="none">
								{/* <IonIcon size="small" icon={person}></IonIcon> */}
								<IonText>
									<h5>Communications Details</h5>
								</IonText>
							</IonItem>
						</IonCard>
						<IonGrid className="ion-margin-vertical">
							<IonRow
								style={{ width: '100%' }}
								className="ion-align-items-center ion-margin-horizontal"
							>
								<IonCol sizeMd="4">
									<IonLabel position="fixed" style={{ width: '100%' }}>
										Additional Emails
									</IonLabel>
								</IonCol>
								<IonCol sizeMd="8" className="ion-interactive">
									<Controller
										name="additional_emails"
										control={control}
										defaultValue={
											[
												// { id: '1', text: 'adityac@gmail.com' },
												// { id: '2', text: 'adityaca@gmail.com' },
											]
										}
										render={({
											field: { onChange, onBlur, name, value, ref },
										}) => (
											<ReactTags
												placeholder="Press enter to add new email"
												tags={value}
												delimiters={delimiters}
												handleDelete={i => {
													const newTags = value.filter(
														(tag, index) => index !== i
													);

													onChange(newTags);
												}}
												handleAddition={tag => {
													const newTags = [...value, tag];
													onChange(newTags);
												}}
												inputFieldPosition="bottom"
												autocomplete
											/>
										)}
									/>

									<ErrorMessage
										errors={errors}
										name="additional_emails"
										render={({ message }) => {
											/**
											 * Calculates the error message for additional emails based on the provided errors object.
											 * If there are errors in the additional_emails array, it returns the corresponding error message.
											 * Otherwise, it returns the default message.
											 *
											 * @param {Object} errors - The errors object containing additional_emails array.
											 * @param {string} message - The default error message.
											 * @returns {string} - The calculated error message.
											 */
											const findErrorMessage = (errors, message) => {
												const additionalEmailsError =
													Array.isArray(errors.additional_emails) &&
													errors.additional_emails.some(item => item.text);
												const errorItemId =
													Array.isArray(errors.additional_emails) &&
													errors.additional_emails.findIndex(
														item => item?.text
													);
												let errorMessage = additionalEmailsError
													? errors.additional_emails[errorItemId].text.message
													: message;

												return errorMessage;
											};

											return (
												<IonText
													color="danger"
													className="ion-padding-horizontal"
													style={{
														fontSize: 12 + 'px',
														marginLeft: -12 + 'px',
													}}
												>
													{findErrorMessage(errors, message)}
												</IonText>
											);
										}}
									/>
								</IonCol>
							</IonRow>
							<IonRow>
								<IonCol sizeMd="4">
									<IonItem lines="none">
										<IonLabel>Auto Approve</IonLabel>
									</IonItem>
								</IonCol>
								<IonCol>
									<IonItem lines="none">
										<Controller
											name="auto_approve"
											control={control}
											// defaultValue={}
											render={({ field }) => {
												// console.log(field.value);
												return (
													<IonCheckbox
														slot="start"
														checked={!!field.value}
														onIonChange={e => {
															field.onChange(Number(e.detail.checked));
														}}
													/>
												);
											}}
										/>
									</IonItem>
								</IonCol>
							</IonRow>
						</IonGrid>
					</section>
					<section className="ion-padding-horizontal">
						<IonCard className="ion-no-margin ion-no-padding">
							<IonItem lines="none">
								<IonText>
									<h5>Serviced Product</h5>
								</IonText>
							</IonItem>
						</IonCard>
						<div
							style={{
								overflowX: 'auto',
								overflowY: 'hidden',
							}}
						>
							<IonGrid
								className="ion-margin-top "
								style={{
									color: '#353535',
									width: 1120 + 'px',
									overflowX: 'auto',
									paddingBottom: 10 + 'px',
								}}
							>
								<IonRow
									className="ionrow-style ion-align-items-center ion-text-center"
									style={{ backgroundColor: 'white', padding: 10 + 'px' }}
								>
									<IonCol className="ion-col-style" size="1">
										<b>Switch</b>
									</IonCol>
									<IonCol className="ion-col-style" size="1.2">
										<b>Image</b>
									</IonCol>
									<IonCol className="ion-col-style" size="2">
										<b>Name</b>
									</IonCol>
									<IonCol className="ion-col-style" size="2">
										<b>Internal notes</b>
									</IonCol>
									<IonCol className="ion-col-style" size="1.2">
										<b>Dimensions</b>
									</IonCol>
									<IonCol className="ion-col-style" size="1.2">
										<b>Weight/Pc</b>
									</IonCol>
									<IonCol className="ion-col-style" size="1.5">
										<b>Laundry Price/Pc</b>
									</IonCol>
									<IonCol className="ion-col-style" size="1.9">
										<b>Laundry Price/Kg</b>
									</IonCol>
								</IonRow>
								{/* TODO: First show all selected products */}

								{fields.map((product, index) => (
									<EditableProductRow
										key={product.id}
										product={product}
										index={index}
										register={register}
										handleToggle={handleToggle}
									/>
								))}

								{/* TODO: Then show all unselected products so that can be selected*/}

								{unSelectedProducts.map(product => (
									<ToggleProductRow
										key={product.id}
										handleToggle={handleToggle}
										product={product}
									/>
								))}
							</IonGrid>
						</div>
					</section>
					<IonGrid className="ion-text-center ion-margin-vertical">
						<IonButton shape="round" type="submit">
							<IonText color={'light'}>Update Customer</IonText>
						</IonButton>
					</IonGrid>
				</form>
				<IonToast
					isOpen={showToast}
					message={toastMessage}
					onDidDismiss={() => {
						setToastMessage('');
						setShowToast(false);
					}}
					duration={2000}
					position="top"
				></IonToast>
				<IonLoading
					isOpen={
						loading ||
						getAllProductLoading ||
						editCustomerLoading ||
						customerByIdLoading
					}
					message="Please Wait.."
				/>
			</IonContent>
		</IonPage>
	);
};

export default UpdateCustomer;
