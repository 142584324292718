import {
	IonButton,
	IonCol,
	IonContent,
	IonHeader,
	IonIcon,
	IonInput,
	IonItem,
	IonLabel,
	IonLoading,
	IonPage,
	IonRefresher,
	IonRefresherContent,
	IonRow,
	IonText,
	IonTitle,
	IonToolbar,
	useIonViewWillEnter,
} from '@ionic/react';
import {
	closeCircleOutline,
	search as searchIcon,
	// add as addIcon,
	// createSharp as createSharpIcon,
	// trashSharp as trashSharpIcon,
	addOutline as addOutlineIcon,
	// addCircle as addCircleIcon,
} from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import Title from '../../components/dashboard/Header';

import { Link } from 'react-router-dom';
import CustomerTable from '../../components/customer/CustomerTable';
import { useSelector } from 'react-redux';
import { getAccessToken } from '../../services/auth/userLocalStorage';
import { API_ROOT } from '../../constants/global';

const CustomerHome: React.FC = () => {
	const [query, setQuery] = useState<any>('');
	const [searchResults, setSearchResults] = useState<any>();

	// states for customers
	const [customers, setCustomers] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		if (query) {
			setSearchResults(
				customers.filter((customer: any) =>
					customer.business.name.toLowerCase().includes(query.toLowerCase())
				)
			);
		}
	}, [query, customers]);

	const { currentUserLoading } = useSelector((state: any) => state.currentUser);
	const { createCustomerLoading, createCustomerMessage } = useSelector(
		(state: any) => state.createCustomerData
	);
	const { editCustomerMessage } = useSelector(
		(state: any) => state.updateCustomer
	);
	const fetchData = async token => {
		const response = await fetch(`${API_ROOT}customers`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				authorization: `Bearer ${token}`,
			},
		});
		const data = await response.json();
		if (data) {
			setCustomers(data.data);
			// setNextPage(resdata?.meta.pagination.links.next);
			// setPreviousPage(resdata?.meta.pagination.links.previous);
		}
		setLoading(false);
	};
	useEffect(() => {
		const tokenObject = getAccessToken();
		fetchData(tokenObject.token);
		// console.log('customer Home rendered');
	}, [createCustomerMessage, editCustomerMessage]);
	// [editCustomerMessage, createCustomerLoading]);
	// console.log(customers);

	useIonViewWillEnter(() => {
		setSearchResults(null);
		setQuery('');
	});

	const onSearchCancel = e => {
		setSearchResults(null);
		setQuery('');
	};

	const doRefresh = e => {
		setSearchResults(null);
		setQuery('');
		fetchData(getAccessToken().token);
		setTimeout(() => {
			e.detail.complete();
		}, 1000);
	};

	return (
		<IonPage>
			<Title name="Customers" />
			<IonContent className="">
				<IonRefresher slot="fixed" onIonRefresh={doRefresh}>
					<IonRefresherContent></IonRefresherContent>
				</IonRefresher>
				<IonRow className="ion-margin-top ion-align-items-center ion-wrap-reverse">
					<IonCol className="" sizeMd="6" sizeXs="12">
						<IonItem className="ion-margin">
							<IonLabel>
								<IonIcon icon={searchIcon}></IonIcon>
							</IonLabel>
							<IonInput
								placeholder="Search Customers by Name"
								value={query}
								onIonChange={e => {
									// console.log(e.detail.value);
									// searchProductByName(e);
									setQuery(e.detail.value);
								}}
							></IonInput>
							{searchResults ? (
								<IonIcon
									icon={closeCircleOutline}
									onClick={e => {
										onSearchCancel(e);
									}}
								></IonIcon>
							) : (
								''
							)}
						</IonItem>
					</IonCol>
					<IonCol className="ion-hide-md-down" sizeMd="3"></IonCol>
					<IonCol
						className="ion-text-end ion-padding-horizontal"
						sizeMd="3"
						sizeXs="12"
					>
						<Link to={`customers/create/true`}>
							<IonButton shape="round" expand="full">
								<IonIcon icon={addOutlineIcon} color="light" />
								<IonText color="light">Add New Customer</IonText>
							</IonButton>
						</Link>
					</IonCol>
				</IonRow>
				<CustomerTable customers={searchResults ? searchResults : customers} />
				<IonLoading
					isOpen={!currentUserLoading && loading}
					message="Please Wait..."
				></IonLoading>
			</IonContent>
		</IonPage>
	);
};

export default CustomerHome;
