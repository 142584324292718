import {
	IonButton,
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardSubtitle,
	IonContent,
	IonGrid,
	IonHeader,
	IonIcon,
	IonLabel,
	IonPage,
	IonProgressBar,
	IonTitle,
	IonToolbar,
} from '@ionic/react';
import { trash } from 'ionicons/icons';
import React from 'react';

type ContractCardProps = {
	contractFile: any;
	removeFile: () => void;
	// progress: number
};

const ContractCard: React.FC<ContractCardProps> = ({
	contractFile,
	removeFile,
	// progress
}) => {
	return (
		<IonCard>
			<IonCardContent>
				<IonGrid className="ion-no-padding ion-align-items-center">
					<IonLabel>
						{contractFile.name} ({contractFile.size} kb)
					</IonLabel>
					<IonIcon
						color="danger"
						icon={trash}
						size="small"
						onClick={removeFile}
						className="ion-float-right"
						style={{ cursor: 'pointer' }}
					></IonIcon>
				</IonGrid>
				{/* {progress && progress < 100 ? (
					<IonProgressBar value={progress}></IonProgressBar>
				) : null} */}
			</IonCardContent>
		</IonCard>
	);
};

export default ContractCard;
