export const GET_ORDERS_REQUEST = 'GET_ORDERS_REQUEST';
export const GET_ORDERS_SUCCESS = 'GET_ORDERS_SUCCESS';
export const GET_ORDERS_FAIL = 'GET_ORDERS_FAIL';

export const GET_BUSINESSES_REQUEST = 'GET_BUSINESSES_REQUEST';
export const GET_BUSINESSES_SUCCESS = 'GET_BUSINESSES_SUCCESS';
export const GET_BUSINESSES_FAIL = 'GET_BUSINESSES_FAIL';

export const GET_BUSINESSES_PRODUCT_REQUEST = 'GET_BUSINESSES_PRODUCT_REQUEST';
export const GET_BUSINESSES_PRODUCT_SUCCESS = 'GET_BUSINESSES_PRODUCT_SUCCESS';
export const GET_BUSINESSES_PRODUCT_FAIL = 'GET_BUSINESSES_PRODUCT_FAIL';

export const CREATE_ADMIN_ORDER_REQUEST = 'CREATE_ADMIN_ORDER_REQUEST';
export const CREATE_ADMIN_ORDER_SUCCESS = 'CREATE_ADMIN_ORDER_SUCCESS';
export const CREATE_ADMIN_ORDER_FAIL = 'CREATE_ADMIN_ORDER_FAIL';
