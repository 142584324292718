import {
	IonBackButton,
	IonButton,
	IonButtons,
	IonCard,
	IonCol,
	IonContent,
	IonDatetime,
	IonGrid,
	IonHeader,
	IonIcon,
	IonInput,
	IonItem,
	IonLabel,
	IonList,
	IonLoading,
	IonPage,
	IonRadio,
	IonRadioGroup,
	IonSelect,
	IonSelectOption,
	IonText,
	IonTextarea,
	IonTitle,
	IonToolbar,
	isPlatform,
	useIonViewDidEnter,
} from '@ionic/react';
import { arrowBack as arrowBackIcon, logoYoutube } from 'ionicons/icons';
import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
// import { authenticateUserWhenLogin, authenticateUserWhenLogout, getCurrentUser } from '../actions/authActions';
import { setTokensObject } from '../services/auth/userLocalStorage';
import { API_ROOT } from '../constants/global';

const SSO: React.FC = (props: any) => {
	const [loading, setLoading] = useState(false);
	const [isNewTokenUserLoaded, setIsNewTokenUserLoaded] = useState(false);
	const history = useHistory();
	const dispatch = useDispatch();
	const { currentUserLoading, currentUser, currentUserError } = useSelector(
		(state: any) => state.currentUser
	);
	const token = new URLSearchParams(props.location.search).get('token');

	const loadCurrentUser = async (token: string) => {
		setLoading(true);
		const response = await fetch(`${API_ROOT}users/current`, {
			method: 'GET',
			headers: {
				authorization: `${token}`,
			},
		});
		const data = await response.json();
		if (
			token &&
			response.status === 200 &&
			data.data[0].store.storetype_id !== 0
		) {
			localStorage.clear();
			setTokensObject({
				access_token: token,
				expires_in: 0,
				refresh_token: '',
				token_type: '',
			});
			setLoading(false);
			// await dispatch(getCurrentUser(token));
			await setIsNewTokenUserLoaded(true);
		}
		if (token && response.status === 401 && data.error) {
			history.push('/');
		}
	};

	useEffect(() => {
		if (token) {
			loadCurrentUser(token);
		}
	}, [token]);

	// useEffect(() => {
	// 	if (isNewTokenUserLoaded) {
	// 		if (currentUserLoading === false && currentUser && currentUser.data) {
	// 			// dispatch(authenticateUserWhenLogin());
	// 			if (currentUser.data[0].store.storetype_id !== 0) {
	// 				//history.push('/my/userdashboard', {direction: 'none'});
	// 			} else {
	// 				//history.push('/getbusinessdetails');
	// 			}
	// 		}
	// 		if (currentUserLoading === false && currentUserError) {
	// 			history.push('/');
	// 		}
	// 	}
	// }, [currentUser, currentUserLoading, isNewTokenUserLoaded]);

	return (
		<IonPage>
			<IonLoading isOpen={currentUserLoading || loading}></IonLoading>
		</IonPage>
	);
};

export default SSO;
