import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
	IonContent,
	IonLabel,
	IonText,
	IonLoading,
	IonBackButton,
	IonHeader,
	IonToolbar,
	IonButtons,
	IonTitle,
	IonRadioGroup,
	IonItem,
	IonRadio,
	IonInput,
	IonButton,
	IonGrid,
	IonRow,
	IonCol,
	IonPage,
	IonList,
	IonListHeader,
	IonSelect,
	IonSelectOption,
	useIonAlert,
} from '@ionic/react';
import { getAccessToken } from '../../services/auth/userLocalStorage';
import { arrowBack as arrowBackIcon } from 'ionicons/icons';
import { API_ROOT } from '../../constants/global';

const CreateLeadsPage = (e: any) => {
	const user_id = e.match.params.id;

	const [name, setName] = useState<any>();
	const [about, setAbout] = useState<any>();
	const [phone, setPhone] = useState<any>();
	const [email, setEmail] = useState<any>();
	const [address, setAddress] = useState<any>();
	const [pin, setPin] = useState<any>();

	//validation
	const [isNameValid, setIsNameValid] = useState(true);
	const [isPhoneValid, setIsPhoneValid] = useState(true);
	const [isEmailValid, setIsEmailValid] = useState(true);
	const [isPinValid, setIsPinValid] = useState(true);
	const [isAddressValid, setIsAddressValid] = useState(true);

	const [isNameEmpty, setIsNameEmpty] = useState(false);

	const [present] = useIonAlert();

	const [loading, setLoading] = useState(false);

	const dispatch = useDispatch();
	const history = useHistory();

	const nameValidation = () => {
		let result = true;

		if (!name) {
			setIsNameEmpty(true);
			result = false;
		} else {
			setIsNameEmpty(false);
			if (/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(name)) {
				setIsNameValid(false);
				result = false;
			} else setIsNameValid(true);
		}
		return result;
	};

	const phoneValidation = () => {
		let result = true;
		if (phone) {
			if (/\D/.test(phone)) {
				setIsPhoneValid(false);
				result = false;
			} else {
				setIsPhoneValid(true);
				if (phone.length !== 10) {
					setIsPhoneValid(false);
					result = false;
				} else setIsPhoneValid(true);
			}
		}

		return result;
	};

	const pinValidation = () => {
		let result = true;
		if (pin) {
			if (/\D/.test(pin)) {
				setIsPinValid(false);
				result = false;
			} else {
				setIsPinValid(true);
				if (pin.length > 6) {
					setIsPinValid(false);
					result = false;
				} else setIsPinValid(true);
			}
		}

		return result;
	};

	const emailValidation = () => {
		let result = true;
		if (email) {
			if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
				setIsEmailValid(false);
				result = false;
			} else {
				setIsEmailValid(true);
			}
		}

		return result;
	};

	const addressValidation = () => {
		let result = true;
		if (address) {
			if (/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(address)) {
				setIsAddressValid(false);
				result = false;
			} else {
				setIsAddressValid(true);
			}
		}

		return result;
	};

	useEffect(() => {
		if (user_id) {
			setLoading(true);
			const token = getAccessToken();

			const fetchData = async () => {
				const response = await fetch(`${API_ROOT}partners/leads/${user_id}`, {
					method: 'GET',
					headers: {
						'Content-Type': 'application/json',
						authorization: `Bearer ${token.token}`,
					},
				});
				const data = await response.json();
				if (data !== undefined) {
					setName(data?.data.name);
					setAbout(data?.data.about);
					setPhone(data?.data.phone);
					setEmail(data?.data.email);
					setAddress(data?.data.address);
					setPin(data?.data.zip);
				}
			};
			setLoading(false);
			fetchData();
		}
	}, []);

	const submitHandler = async (e: any) => {
		e.preventDefault();
		setLoading(true);

		const res1 = await nameValidation();
		const res2 = await phoneValidation();
		const res3 = await pinValidation();
		const res4 = await emailValidation();
		const res5 = await addressValidation();

		if (res1 && res2 && res3 && res4 && res5) {
			setLoading(true);
			const token = getAccessToken();
			let resp;

			if (user_id) {
				const putData = {
					name: `${name}`,
					about: `${about}`,
					phone: `${phone}`,
					email: `${email}`,
					address: `${address}`,
					zip: `${pin}`,
					source: `partner`,
					id: `${user_id}`,
				};

				resp = await fetch(`${API_ROOT}partners/leads`, {
					method: 'PUT',
					headers: {
						'Content-Type': 'application/json',
						authorization: `Bearer ${token.token}`,
					},
					body: JSON.stringify(putData),
				});
			} else {
				const postData = {
					name: name ? `${name}` : undefined,
					about: about ? `${about}` : undefined,
					phone: phone ? `${phone}` : undefined,
					email: email ? `${email}` : undefined,
					address: address ? `${address}` : undefined,
					zip: pin ? `${pin}` : undefined,
					source: `partner`,
				};

				resp = await fetch(`${API_ROOT}partners/leads`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						authorization: `Bearer ${token.token}`,
					},
					body: JSON.stringify(postData),
				});
			}
			setLoading(false);

			if (resp.status === 200) {
				present({
					cssClass: 'my-css',
					header: name,
					message: 'Details Are Saved Successfully',
					buttons: ['OK'],
				});
			} else {
				present({
					cssClass: 'my-css',
					header: 'We are facing issues right now, please try in sometime',
					message: 'Error Code ' + resp.status,
					buttons: ['OK'],
				});
			}

			setLoading(false);
			if (loading === false) {
				history.goBack();
			}
		}

		setLoading(false);
	};

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start">
						<IonBackButton
							defaultHref="/my/leads"
							icon={arrowBackIcon}
							style={{
								cursor: 'pointer',
							}}
						/>
					</IonButtons>
					<IonTitle></IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent className="ion-padding">
				<form onSubmit={submitHandler}>
					<IonGrid>
						<IonRow className="ion-align-items-center">
							<IonCol size="4">
								<IonLabel>Name</IonLabel>
							</IonCol>
							<IonCol>
								<IonItem>
									<IonInput
										placeholder="Enter Lead Name"
										type="text"
										value={name}
										name="leadsName"
										onIonChange={e => {
											setName(e.detail.value);
										}}
									/>
								</IonItem>
								<IonText
									color="danger"
									className={`${
										isNameEmpty == false && isNameValid === true
											? 'ion-hide'
											: ''
									}`}
								>
									<h5
										style={{
											fontSize: 12 + 'px',
											marginTop: 0 + 'px',
										}}
										className={`ion-margin-horizontal ${
											isNameEmpty ? '' : 'ion-hide'
										}`}
									>
										Name is required.
									</h5>
									<h5
										style={{
											fontSize: 12 + 'px',
											marginTop: 0 + 'px',
										}}
										className={`ion-margin-horizontal ${
											isNameValid ? 'ion-hide' : ''
										}`}
									>
										Name can't contain special characters.
									</h5>
								</IonText>
							</IonCol>
						</IonRow>
						<IonRow className="ion-align-items-center">
							<IonCol size="4">
								<IonLabel>About</IonLabel>
							</IonCol>
							<IonCol>
								<IonItem>
									<IonInput
										placeholder="Enter Lead About"
										type="text"
										value={about}
										name="about"
										onIonChange={e => {
											setAbout(e.detail.value);
										}}
									/>
								</IonItem>
							</IonCol>
						</IonRow>
						<IonRow className="ion-align-items-center">
							<IonCol size="4">
								<IonLabel>Phone</IonLabel>
							</IonCol>
							<IonCol>
								<IonItem>
									<IonInput
										placeholder="Enter Phone Number"
										type="text"
										value={phone}
										name="phone"
										onIonChange={e => {
											setPhone(e.detail.value);
										}}
									/>
								</IonItem>
								<IonText
									color="danger"
									className={`${isPhoneValid == true ? 'ion-hide' : ''}`}
								>
									<h5
										style={{
											fontSize: 12 + 'px',
											marginTop: 0 + 'px',
										}}
										className={`ion-margin-horizontal ${
											isPhoneValid ? 'ion-hide' : ''
										}`}
									>
										Phone Number is not valid.
									</h5>
								</IonText>
							</IonCol>
						</IonRow>
						<IonRow className="ion-align-items-center">
							<IonCol size="4">
								<IonLabel>Email</IonLabel>
							</IonCol>
							<IonCol>
								<IonItem>
									<IonInput
										placeholder="Enter Email"
										type="text"
										value={email}
										name="email"
										onIonChange={e => {
											setEmail(e.detail.value);
										}}
									/>
								</IonItem>
								<IonText
									color="danger"
									className={`${isEmailValid === true ? 'ion-hide' : ''}`}
								>
									<h5
										style={{
											fontSize: 12 + 'px',
											marginTop: 0 + 'px',
										}}
										className={`ion-margin-horizontal ${
											isEmailValid ? 'ion-hide' : ''
										}`}
									>
										Email is invalid.
									</h5>
								</IonText>
							</IonCol>
						</IonRow>
						<IonRow className="ion-align-items-center">
							<IonCol size="4">
								<IonLabel>Address</IonLabel>
							</IonCol>
							<IonCol>
								<IonItem>
									<IonInput
										placeholder="Enter Address"
										type="text"
										value={address}
										name="address"
										onIonChange={e => {
											setAddress(e.detail.value);
										}}
									/>
								</IonItem>
								<IonText
									color="danger"
									className={`${isAddressValid === true ? 'ion-hide' : ''}`}
								>
									<h5
										style={{
											fontSize: 12 + 'px',
											marginTop: 0 + 'px',
										}}
										className={`ion-margin-horizontal ${
											isAddressValid ? 'ion-hide' : ''
										}`}
									>
										Address is not correct.
									</h5>
								</IonText>
							</IonCol>
						</IonRow>
						<IonRow className="ion-align-items-center">
							<IonCol size="4">
								<IonLabel>PIN</IonLabel>
							</IonCol>
							<IonCol>
								<IonItem>
									<IonInput
										placeholder="Enter PIN"
										type="text"
										value={pin}
										name="pin"
										onIonChange={e => {
											setPin(e.detail.value);
										}}
									/>
								</IonItem>
								<IonText
									color="danger"
									className={`${isPinValid === true ? 'ion-hide' : ''}`}
								>
									<h5
										style={{
											fontSize: 12 + 'px',
											marginTop: 0 + 'px',
										}}
										className={`ion-margin-horizontal ${
											isPinValid ? 'ion-hide' : ''
										}`}
									>
										Pin Code is not correct.
									</h5>
								</IonText>
							</IonCol>
						</IonRow>
					</IonGrid>
					<div className="ion-text-center">
						<IonButton shape="round" type="submit">
							Save
						</IonButton>
					</div>
				</form>
				<IonLoading
					isOpen={loading === true}
					message="Please Wait"
				></IonLoading>
			</IonContent>
		</IonPage>
	);
};

export default CreateLeadsPage;
