import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
	IonContent,
	IonLabel,
	IonText,
	IonLoading,
	IonBackButton,
	IonHeader,
	IonToolbar,
	IonButtons,
	IonTitle,
	IonRadioGroup,
	IonItem,
	IonRadio,
	IonInput,
	IonButton,
	IonGrid,
	IonRow,
	IonCol,
	IonPage,
	IonCheckbox,
	IonCard,
	IonIcon,
} from '@ionic/react';
import { createTax, clearErrors } from '../../../actions/dashboardActions';
import { getAccessToken } from '../../../services/auth/userLocalStorage';
import {
	arrowBack as arrowBackIcon,
	addCircle as addCircleIcon,
	removeCircle as removeCircleIcon,
} from 'ionicons/icons';

const CreateTaxPage = () => {
	const [taxType, setTaxType] = useState('FLAT');
	const [taxName, setTaxName] = useState<any>();
	const [taxAmount, setTaxAmount] = useState<
		string | number | null | undefined
	>('');
	const [isDistanceDependent, setIsDistanceDependent] = useState<any>(false);
	const [range, setRange] = useState<any>([0, 10000]);
	const [value, setValue] = useState<any>([0]);
	const [meter, setMeter] = useState<any>(0);

	//validation
	const [isTaxNameEmpty, setIsTaxNameEmpty] = useState(false);
	const [isTaxNameValidate, setIsTaxNameValidate] = useState(true);
	const [isTaxAmountEmpty, setIsTaxAmountEmpty] = useState(false);
	const [isTaxAmountValidate, setIsTaxAmountValidate] = useState(true);
	const [isTaxAmountValidate2, setIsTaxAmountValidate2] = useState(true);
	const [isValueValidate, setIsValueValidate] = useState([true]);
	const [isRangeValidate, setIsRangeValidate] = useState([true]);
	const [loading, setLoading] = useState(false);

	const dispatch = useDispatch();
	const history = useHistory();
	const { createTaxMessageLoading, createTaxMessage, createTaxMessageError } =
		useSelector((state: any) => state.createTaxData);
	const { currentUser } = useSelector((state: any) => state.currentUser);

	useEffect(() => {}, [meter]);

	// const taxAmountValidation = ()=>{
	//     let result = true;
	//     if(!taxAmount){
	//         setIsTaxAmountEmpty(true);
	//         result = false;
	//     }else{
	//         setIsTaxAmountEmpty(false);
	//         if((taxType !== "FLAT") && (0 > taxAmount || taxAmount > 100)){
	//             setIsTaxAmountValidate(false);
	//             result = false;
	//         }else{
	//             setIsTaxAmountValidate(true);
	//         }
	//         if(taxType === "FLAT" && !isDistanceDependent && !(/^\d+(\.\d{1,2})?$/.test(`${taxAmount}`))){
	//             setIsTaxAmountValidate2(false);
	//             result = false;
	//         }else{
	//             setIsTaxAmountValidate2(true);
	//         }
	//     }
	//     return result;
	// }

	const taxNameValidation = () => {
		let result = true;
		if (!taxName) {
			setIsTaxNameEmpty(true);
			result = false;
		} else {
			setIsTaxNameEmpty(false);
			if (/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(taxName)) {
				setIsTaxNameValidate(false);
				result = false;
			} else setIsTaxNameValidate(true);
		}
		return result;
	};

	const rangeValidation = () => {
		let result = true;
		for (let i = 0; i < value.length; i++) {
			if (
				(value[i] !== 0 && !value[i]) ||
				!/^\d+(\.\d{1,2})?$/.test(value[i])
			) {
				let arr = isValueValidate;
				arr[i] = false;
				setIsValueValidate(arr);
				result = false;
			} else {
				let arr = isValueValidate;
				arr[i] = true;
				setIsValueValidate(arr);
			}
		}
		for (let i = 0; i < range.length; i++) {
			if ((range[i] !== 0 && !range[i]) || /\D/.test(range[i])) {
				let arr = isRangeValidate;
				arr[i] = false;
				setIsRangeValidate(arr);
				result = false;
			} else {
				if (i !== range.length - 1 && result) {
					if (range[i + 1] < range[i]) {
						let arr = isRangeValidate;
						arr[i] = false;
						setIsRangeValidate(arr);
						result = false;
					} else {
						let arr = isRangeValidate;
						arr[i] = true;
						setIsRangeValidate(arr);
					}
				}
			}
		}
		return result;
	};

	const submitHandler = async (e: any) => {
		e.preventDefault();
		setLoading(true);
		const accessToken = await getAccessToken();
		let res1 = true;
		if (isDistanceDependent === false)
			// res1 = await taxAmountValidation();
			// const res2 = await taxNameValidation();
			// let res3 = true;
			// if(isDistanceDependent === true)
			//     {res3 = await rangeValidation();}
			// if( res1 && res2 && res3){
			//     if(currentUser && currentUser.data){
			//         if(isDistanceDependent === false){
			//             const data = {
			//                 store_id : currentUser.data[0].store_id,
			//                 name: `${taxName}`,
			//                 value: `${taxAmount}`,
			//                 method: `${taxType}`,
			//                 distance_dependent: isDistanceDependent ? "1" : "0"
			//             };
			//             await dispatch(createTax(getAccessToken(), data));
			//         }
			//         else{
			//             let range1 = [];
			//             for(let i=0;i<value.length;i++){
			//                 range1.push({
			//                     start: range[i],
			//                     end: range[i+1],
			//                     value: value[i]
			//                 })
			//             }
			//             const data = {
			//                 store_id : currentUser.data[0].store_id,
			//                 name: `${taxName}`,
			//                 value: `${value[value.length-1]}`,
			//                 method: `${taxType}`,
			//                 distance_dependent: isDistanceDependent ? "1" : "0",
			//                 range: range1
			//             };
			//             await dispatch(createTax(getAccessToken(), data));
			//         }
			//         setLoading(false);
			//         setIsDistanceDependent(false);
			//         setTaxType("FLAT");
			//         setTaxName('');
			//         setTaxAmount('');
			//         setRange([0,10000]);
			//         setValue([0]);
			//         setMeter(0);
			//     }
			//     if(loading === false && createTaxMessage){
			//         history.goBack();
			//     }
			// }
			setLoading(false);
	};

	const addInRange = () => {
		let arr = range;
		arr.pop();
		arr.push(0, 10000);
		setRange(arr);
		setValue([...value, 0]);
		setIsRangeValidate([...isRangeValidate, true]);
		setIsValueValidate([...isValueValidate, true]);
	};
	const removeInRange = (ind: number) => {
		let arr = range;
		if (ind > -1) {
			if (ind === 0) {
				arr.splice(ind, 1);
				arr[0] = 0;
			} else {
				arr.splice(ind, 1);
			}
		}
		setRange(arr);
		let valArray = value;
		if (ind > -1) {
			valArray.splice(ind, 1);
		}
		setValue(valArray);
		let rangeValidateArray = isRangeValidate;
		rangeValidateArray.pop();
		let valueValidateArray = isValueValidate;
		valueValidateArray.pop();
		setIsRangeValidate(rangeValidateArray);
		setIsValueValidate(valueValidateArray);
	};
	const setRangeValue = (e: any, ind: number) => {
		let arr = value;
		arr[ind] = e.detail.value;
		setValue(arr);
	};
	const fromKmChange = (e: any, ind: number) => {
		let arr = range;
		arr[ind] = e.detail.value;
		return arr;
	};
	const toKmChange = (e: any, ind: number) => {
		let arr = range;
		arr[ind + 1] = e.detail.value;
		return arr;
	};
	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start">
						<IonBackButton
							defaultHref="/my/settings/taxes"
							icon={arrowBackIcon}
							style={{
								cursor: 'pointer',
							}}
						/>
					</IonButtons>
					<IonTitle>Add Tax/Charge</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent className="ion-padding">
				<form onSubmit={submitHandler}>
					<IonItem lines="none">
						<IonLabel position="stacked">
							<h1>Select Tax/Charge type</h1>
						</IonLabel>
						<IonGrid
							className="ion-no-margin ion-no-padding ion-padding-bottom ion-padding-top"
							style={{ width: 100 + '%' }}
						>
							<IonRadioGroup
								name="taxType"
								value={taxType}
								onIonChange={e => {
									e.detail.value === 'PERCENTAGE'
										? setIsDistanceDependent(false)
										: setIsDistanceDependent(isDistanceDependent);
									setTaxType(e.detail.value);
								}}
							>
								<IonRow>
									<IonCol size="5">
										<IonItem lines="none">
											<IonRadio slot="start" value="FLAT" />
											<IonLabel>Flat</IonLabel>
										</IonItem>
									</IonCol>
									<IonCol>
										<IonItem lines="none">
											<IonLabel>Percentage</IonLabel>
											<IonRadio slot="start" value="PERCENTAGE" />
										</IonItem>
									</IonCol>
								</IonRow>
							</IonRadioGroup>
						</IonGrid>
					</IonItem>
					<IonItem>
						<IonLabel position="stacked">
							<h1>Enter the Tax name</h1>
						</IonLabel>
						<br />
						<IonInput
							placeholder="Enter the Tax name"
							type="text"
							value={taxName}
							name="taxName"
							onIonChange={e => {
								setTaxName(e.detail.value);
							}}
						/>
					</IonItem>
					<IonText
						color="danger"
						className={`${
							isTaxNameEmpty === false && isTaxNameValidate === true
								? 'ion-hide'
								: ''
						}`}
					>
						<h5
							style={{ fontSize: 12 + 'px', marginTop: 0 + 'px' }}
							className={`ion-margin-horizontal ${
								isTaxNameEmpty ? '' : 'ion-hide'
							}`}
						>
							Tax name is required.
						</h5>
						<h5
							style={{ fontSize: 12 + 'px', marginTop: 0 + 'px' }}
							className={`ion-margin-horizontal ${
								isTaxNameValidate ? 'ion-hide' : ''
							}`}
						>
							Tax name can't contain special characters.
						</h5>
					</IonText>
					<br />
					{isDistanceDependent !== true ? (
						<>
							<IonItem>
								<IonLabel position="stacked">
									<h1>{`${
										taxType === 'FLAT'
											? 'Enter the tax amount'
											: 'Enter tax percentage'
									}`}</h1>
								</IonLabel>
								<br />
								{/* <IonInput placeholder="0.0" type="tel" value={taxAmount} name="taxAmount" onIonChange={(e)=>{ setTaxAmount(e.detail.value)}} onIonBlur={e=>taxAmountValidation()}/> */}
							</IonItem>
							<IonText
								color="danger"
								className={`${
									isTaxAmountEmpty === false &&
									isTaxAmountValidate === true &&
									isTaxAmountValidate2 === true
										? 'ion-hide'
										: ''
								}`}
							>
								<h5
									style={{ fontSize: 12 + 'px', marginTop: 0 + 'px' }}
									className={`ion-margin-horizontal ${
										isTaxAmountEmpty ? '' : 'ion-hide'
									}`}
								>
									Tax {`${taxType === 'FLAT' ? 'amount' : 'percentage'}`} is
									required.
								</h5>
								<h5
									style={{ fontSize: 12 + 'px', marginTop: 0 + 'px' }}
									className={`ion-margin-horizontal ${
										isTaxAmountValidate ? 'ion-hide' : ''
									}`}
								>
									Tax percentage is not greater than 100 or less than 0.
								</h5>
								<h5
									style={{ fontSize: 12 + 'px', marginTop: 0 + 'px' }}
									className={`ion-margin-horizontal ${
										isTaxAmountValidate2 ? 'ion-hide' : ''
									}`}
								>
									Tax amount should contain numbers only or up to 2 decimal
									places only.
								</h5>
							</IonText>
							<br />
						</>
					) : (
						''
					)}
					{taxType !== 'PERCENTAGE' ? (
						<>
							<IonItem lines="none">
								<IonLabel>Distance Dependent</IonLabel>
								<IonCheckbox
									slot="start"
									value={isDistanceDependent}
									onIonChange={e => setIsDistanceDependent(e.detail.checked)}
								/>
							</IonItem>
							<br />
						</>
					) : (
						''
					)}
					{isDistanceDependent === true && taxType !== 'PERCENTAGE' ? (
						<IonGrid>
							<IonRow>
								<IonCol size="3" className="ion-text-center">
									From KMs
								</IonCol>
								<IonCol size="3" className="ion-text-center">
									To KMs
								</IonCol>
								<IonCol size="3" className="ion-text-center">
									value
								</IonCol>
							</IonRow>
							{value.map((val, ind) => {
								return (
									<IonRow className="ion-align-items-center" key={ind}>
										<IonCol size="3">
											<IonCard>
												<IonInput
													type="tel"
													placeholder="0"
													value={range[ind]}
													disabled={ind === 0 ? true : false}
													onIonChange={e => {
														setRange(fromKmChange(e, ind));
														setMeter(meter + 1);
													}}
												/>
												<IonText
													color="danger"
													className={`${
														isRangeValidate[ind] === true ? 'ion-hide' : ''
													}`}
												>
													<h5
														style={{ fontSize: 12 + 'px' }}
														className={`ion-no-margin ion-margin-horizontal ${
															isRangeValidate[ind] ? 'ion-hide' : ''
														}`}
													>
														Invalid
													</h5>
												</IonText>
											</IonCard>
										</IonCol>
										<IonCol size="3">
											<IonCard>
												<IonInput
													type="tel"
													placeholder={`${
														ind === value.length - 1 ? 'UNLIMITED' : '0'
													}`}
													value={`${
														ind === value.length - 1 ? '' : range[ind + 1]
													}`}
													disabled={ind === value.length - 1 ? true : false}
													onIonChange={e => {
														setRange(toKmChange(e, ind));
														setMeter(meter + 1);
													}}
												/>
												<IonText
													color="danger"
													className={`${
														(ind === 0 && value.length === 1) ||
														ind === value.length - 1 ||
														isRangeValidate[ind + 1] === true
															? 'ion-hide'
															: ''
													}`}
												>
													<h5
														style={{ fontSize: 12 + 'px' }}
														className={`ion-no-margin ion-margin-horizontal ${
															isRangeValidate[ind + 1] ? 'ion-hide' : ''
														}`}
													>
														Invalid
													</h5>
												</IonText>
											</IonCard>
										</IonCol>
										<IonCol size="3">
											<IonCard>
												<IonInput
													type="tel"
													placeholder="0"
													value={val}
													onIonChange={e => setRangeValue(e, ind)}
												/>
												<IonText
													color="danger"
													className={`${
														isValueValidate[ind] === true ? 'ion-hide' : ''
													}`}
												>
													<h5
														style={{ fontSize: 12 + 'px' }}
														className={`ion-no-margin ion-margin-horizontal ${
															isValueValidate[ind] ? 'ion-hide' : ''
														}`}
													>
														Invalid
													</h5>
												</IonText>
											</IonCard>
										</IonCol>
										<IonCol size="3">
											<IonIcon
												size="large"
												icon={
													ind === value.length - 1
														? ind !== 3
															? addCircleIcon
															: removeCircleIcon
														: removeCircleIcon
												}
												onClick={e => {
													ind === value.length - 1
														? ind !== 3
															? addInRange()
															: removeInRange(ind)
														: removeInRange(ind);
													setMeter(meter + 1);
												}}
											></IonIcon>
										</IonCol>
									</IonRow>
								);
							})}
						</IonGrid>
					) : (
						''
					)}

					<IonButton type="submit">Create Tax</IonButton>
				</form>
				<IonLoading
					isOpen={loading === true}
					message="Please Wait"
				></IonLoading>
			</IonContent>
		</IonPage>
	);
};

export default CreateTaxPage;
