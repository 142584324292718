import {
	CREATE_CUSTOMER_FAIL,
	CREATE_CUSTOMER_REQUEST,
	CREATE_CUSTOMER_SUCCESS,
	EDIT_CUSTOMER_FAIL,
	EDIT_CUSTOMER_REQUEST,
	EDIT_CUSTOMER_SUCCESS,
	GET_CUSTOMER_BY_ID_FAIL,
	GET_CUSTOMER_BY_ID_REQUEST,
	GET_CUSTOMER_BY_ID_SUCCESS,
} from '../constants/customerConstants';
import { API_ROOT } from '../constants/global';

export const getCustomerById =
	(customerId: string | number, token: string) => async (dispatch: any) => {
		try {
			dispatch({ type: GET_CUSTOMER_BY_ID_REQUEST });
			const response = await fetch(`${API_ROOT}customers/${customerId}`, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					authorization: `Bearer ${token}`,
				},
			});
			const data = await response.json();
			dispatch({
				type: GET_CUSTOMER_BY_ID_SUCCESS,
				payload: data.data,
			});
		} catch (error) {
			dispatch({
				type: GET_CUSTOMER_BY_ID_FAIL,
				payload: error,
			});
		}
	};

export const createCustomer =
	(customerData: any, accessToken: string) => async (dispatch: any) => {
		try {
			dispatch({ type: CREATE_CUSTOMER_REQUEST });
			const response = await fetch(`${API_ROOT}customers`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					authorization: `Bearer ${accessToken}`,
				},
				body: JSON.stringify(customerData),
			});
			const data = await response.json();
			if (response.status !== 200) {
				dispatch({
					type: CREATE_CUSTOMER_FAIL,
					payload: data,
				});
			} else {
				dispatch({
					type: CREATE_CUSTOMER_SUCCESS,
					payload: data.data,
				});
			}
		} catch (error) {
			console.log(error);
			dispatch({
				type: CREATE_CUSTOMER_FAIL,
				payload: error,
			});
		}
	};
export const updateCustomer =
	(customerData: any, accessToken: string) => async (dispatch: any) => {
		try {
			dispatch({ type: EDIT_CUSTOMER_REQUEST });
			const response = await fetch(`${API_ROOT}customers`, {
				method: 'PUT',
				headers: {
					'Content-Type': 'application/json',
					authorization: `Bearer ${accessToken}`,
				},
				body: JSON.stringify(customerData),
			});

			const data = await response.json();

			dispatch({
				type: EDIT_CUSTOMER_SUCCESS,
				payload: data.data,
			});
		} catch (error) {
			console.log(error);
			dispatch({
				type: EDIT_CUSTOMER_FAIL,
				payload: error,
			});
		}
	};
