export const CREATE_CUSTOMER_REQUEST = 'CREATE_CUSTOMER_REQUEST';
export const CREATE_CUSTOMER_SUCCESS = 'CREATE_CUSTOMER_SUCCESS';
export const CREATE_CUSTOMER_FAIL = 'CREATE_CUSTOMER_FAIL';

export const EDIT_CUSTOMER_REQUEST = 'EDIT_CUSTOMER_REQUEST';
export const EDIT_CUSTOMER_SUCCESS = 'EDIT_CUSTOMER_SUCCESS';
export const EDIT_CUSTOMER_FAIL = 'EDIT_CUSTOMER_FAIL';

export const GET_CUSTOMER_BY_ID_REQUEST = 'GET_CUSTOMER_BY_ID_REQUEST';
export const GET_CUSTOMER_BY_ID_SUCCESS = 'GET_CUSTOMER_BY_ID_SUCCESS';
export const GET_CUSTOMER_BY_ID_FAIL = 'GET_CUSTOMER_BY_ID_FAIL';
