import { API_ROOT } from '../constants/global';
import {
    CREATE_FINANCE_FAIL,
    CREATE_FINANCE_REQUEST,
    CREATE_FINANCE_SUCCESS,
    DELETE_FINANCE_FAIL,
    DELETE_FINANCE_REQUEST,
    DELETE_FINANCE_SUCCESS,
    EDIT_FINANCE_FAIL,
    EDIT_FINANCE_REQUEST,
    EDIT_FINANCE_SUCCESS,
    GET_FINANCE_BY_ID_REQUEST,
    GET_FINANCE_BY_ID_SUCCESS,
    GET_FINANCE_BY_ID_FAIL,
    SEARCH_FINANCE_QUERY_REQUEST,
    SEARCH_FINANCE_QUERY_SUCCESS,
    SEARCH_FINANCE_QUERY_FAIL,
    GET_ALL_FINANCE_REQUEST,
    GET_ALL_FINANCE_SUCCESS,
    GET_ALL_FINANCE_FAIL,
    GET_ALL_FINANCE_ALL_REQUEST,
    GET_ALL_FINANCE_ALL_SUCCESS,
    GET_ALL_FINANCE_ALL_FAIL,
} from '../constants/financeConstants'; // Adjust the import to your actual finance constants file

export const getFinanceById =
    (financeId: string | number, token: string) => async (dispatch: any) => {
        try {
            dispatch({ type: GET_FINANCE_BY_ID_REQUEST });
            const response = await fetch(`${API_ROOT}finance-report/${financeId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    authorization: `Bearer ${token}`,
                },
            });
            const data = await response.json();
            dispatch({
                type: GET_FINANCE_BY_ID_SUCCESS,
                payload: data.data,
            });
        } catch (error) {
            dispatch({
                type: GET_FINANCE_BY_ID_FAIL,
                payload: error,
            });
        }
    };

export const createFinance =
    (financeData: any, accessToken: string) => async (dispatch: any) => {
        try {
            dispatch({ type: CREATE_FINANCE_REQUEST });
            const response = await fetch(`${API_ROOT}finance-report`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    authorization: `Bearer ${accessToken}`,
                },
                body: JSON.stringify(financeData),
            });
            const data = await response.json();
            if (response.status === 200) {
                dispatch({
                    type: CREATE_FINANCE_SUCCESS,
                    payload: data.data,
                });
            } else {
                dispatch({
                    type: CREATE_FINANCE_FAIL,
                    payload: data,
                });
            }
        } catch (error) {
            console.log(error);
            dispatch({
                type: CREATE_FINANCE_FAIL,
                payload: error,
            });
        }
    };

export const updateFinance =
    (financeData: any, accessToken: string) => async (dispatch: any) => {
        try {
            dispatch({ type: EDIT_FINANCE_REQUEST });
            const response = await fetch(`${API_ROOT}finance-report`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    authorization: `Bearer ${accessToken}`,
                },
                body: JSON.stringify(financeData),
            });
            const data = await response.json();
            dispatch({
                type: EDIT_FINANCE_SUCCESS,
                payload: data.data,
            });
        } catch (error) {
            console.log(error);
            dispatch({
                type: EDIT_FINANCE_FAIL,
                payload: error,
            });
        }
    };

export const deleteFinance =
    (accessToken: string, financeId) =>
    async (dispatch: any) => {
        try {
            dispatch({ type: DELETE_FINANCE_REQUEST });
            const response = await fetch(
                `${API_ROOT}finance-report/${financeId}`,
                {
                    method: 'DELETE',
                    headers: {
                        authorization: `Bearer ${accessToken}`,
                    },
                }
            );
            const data = await response.json();
            dispatch({
                type: DELETE_FINANCE_SUCCESS,
                payload: data,
            });
        } catch (error) {
            dispatch({
                type: DELETE_FINANCE_FAIL,
                payload: error,
            });
        }
    };

export const getAllFinance = (accessToken: string,pageNo: number,perPage: string) => async (dispatch: any) => {
    try {
        dispatch({ type: GET_ALL_FINANCE_REQUEST });
        const response = await fetch(`${API_ROOT}finance-report?page=${pageNo}&per_page=${perPage}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${accessToken}`,
            },
        });
        const data = await response.json();
        dispatch({
            type: GET_ALL_FINANCE_SUCCESS,
            payload: data.data,
        });
    } catch (error) {
        dispatch({
            type: GET_ALL_FINANCE_FAIL,
            payload: error,
        });
    }
};

export const getAllFinanceAll = (accessToken: string) => async (dispatch: any) => {
    try {
        dispatch({ type: GET_ALL_FINANCE_ALL_REQUEST });
        const response = await fetch(`${API_ROOT}finance-report`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${accessToken}`,
            },
        });
        const data = await response.json();
        dispatch({
            type: GET_ALL_FINANCE_ALL_SUCCESS,
            payload: data.data,
        });
    } catch (error) {
        dispatch({
            type: GET_ALL_FINANCE_ALL_FAIL,
            payload: error,
        });
    }
};


//will see in it later on 
export const searchQueryFinance =
    (query: string) => async (dispatch: any) => {
        try {
            dispatch({ type: SEARCH_FINANCE_QUERY_REQUEST });
            const response = await fetch(
                `${API_ROOT}api/search/finance-reports?query=${query}`,
                {
                    method: 'GET',
                }
            );
            const data = await response.json();
            dispatch({
                type: SEARCH_FINANCE_QUERY_SUCCESS,
                payload: data,
            });
        } catch (error) {
            dispatch({
                type: SEARCH_FINANCE_QUERY_FAIL,
                payload: error,
            });
        }
    };
