import {
	IonCol,
	IonImg,
	IonInput,
	IonLabel,
	IonRow,
	IonText,
	IonToggle,
} from '@ionic/react';
import React, { useState } from 'react';

type ToggleProductRowProps = {
	product: any;
	handleToggle: any;
};

const ToggleProductRow: React.FC<ToggleProductRowProps> = ({
	product,
	handleToggle,
}) => {
	const [selected, setSelected] = useState(false);

	return (
		<IonRow
			className="ionrow-style ion-align-items-center ion-text-center"
			style={{ height: '80px' }}
		>
			<IonCol className="ion-col-style" sizeMd="1">
				<IonToggle
					enableOnOffLabels
					checked={selected}
					onIonChange={e => {
						// console.log(product.id, product.product_id);
						// console.log(e.detail.checked);
						setSelected(e.detail.checked);
						handleToggle(product.id, e.detail.checked);
					}}
				>
					Select
				</IonToggle>
			</IonCol>

			<IonCol className="ion-col-style " sizeMd="1.2">
				<div>
					<IonImg
						style={{
							width: '100%',
							height: '50px',
						}}
						src={product.image_display_url}
					/>
				</div>
			</IonCol>
			<IonCol className="ion-col-style" sizeMd="2">
				<IonText color={'tertiary'}>{product.name}</IonText>
			</IonCol>
			<IonCol className="ion-col-style" sizeMd="2">
				<IonText color={'tertiary'}>{product.internal_notes}</IonText>
			</IonCol>
			<IonCol className="ion-col-style" sizeMd="1.2">
				<IonText color={'tertiary'}>{product.dimension}</IonText>
			</IonCol>
			<IonCol className="ion-col-style" sizeMd="1.2">
				<IonText color={'tertiary'}>{product.weight_in_gms}</IonText>
			</IonCol>
			<IonCol className="ion-col-style" sizeMd="1.5">
				<IonText color={'tertiary'}>{product.laundry_service_price}</IonText>
			</IonCol>
			<IonCol className="ion-col-style" sizeMd="1.9">
				<IonText color={'tertiary'}>
					{product.laundry_service_price_per_kg}
				</IonText>
			</IonCol>
		</IonRow>
	);
};

export default ToggleProductRow;
