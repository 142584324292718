import {
	IonButton,
	IonCol,
	IonContent,
	IonHeader,
	IonIcon,
	IonImg,
	IonPage,
	IonRow,
	IonTitle,
	IonToolbar,
} from '@ionic/react';

import { create, trash } from 'ionicons/icons';
import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';

import { Link, useHistory } from 'react-router-dom';

interface SingleCustomerRowProps {
	customer: any;
}

const SingleCustomerRow: React.FC<SingleCustomerRowProps> = ({ customer }) => {
	const [showModal, setShowModal] = useState(false);
	const handleOpenModal = () => {
		setShowModal(true);
	};

	const history = useHistory();

	return (
		<IonRow
			key={customer.id}
			className="ionrow-style ion-align-items-center ion-text-center"
			style={{ height: '80px' }}
			onClick={() => {
				history.push(`/app/customers/update/${customer.id}`);
			}}
		>
			<IonCol className="ion-col-style" sizeLg="3" sizeMd="3" sizeSm="4.5">
				<div>
					<IonImg
						style={{
							width: '100%',
							height: '50px',
						}}
						src={customer?.business?.logo_display_url}
					/>
				</div>
			</IonCol>
			<IonCol className="ion-col-style" sizeLg="5" sizeMd="5" sizeSm="4.5">
				{customer.business.name}
			</IonCol>

			<IonCol
				className="ion-col-style"
				sizeLg="2"
				sizeMd="2"
				sizeSm="3"
				style={{
					textTransform: 'capitalize',
				}}
			>
				{customer.business.status}
			</IonCol>

			<IonCol className="ion-col-style ion-hide-md-down" sizeLg="2" sizeMd="2">
				<IonButton
					size="small"
					fill="outline"
					onClick={() => {
						history.push(`/app/customers/update/${customer.id}`);
					}}
				>
					<IonIcon slot="icon-only" size="small" icon={create} />
				</IonButton>
			</IonCol>
		</IonRow>
	);
};

export default SingleCustomerRow;
