import {
	CREATE_CUSTOMER_FAIL,
	CREATE_CUSTOMER_REQUEST,
	CREATE_CUSTOMER_SUCCESS,
	EDIT_CUSTOMER_FAIL,
	EDIT_CUSTOMER_REQUEST,
	EDIT_CUSTOMER_SUCCESS,
	GET_CUSTOMER_BY_ID_FAIL,
	GET_CUSTOMER_BY_ID_REQUEST,
	GET_CUSTOMER_BY_ID_SUCCESS,
} from '../constants/customerConstants';

export const loadCustomerByIdReducer = (
	state = { customer: {}, customerByIdLoading: false },
	action: any
) => {
	switch (action.type) {
		case GET_CUSTOMER_BY_ID_REQUEST:
			return {
				...state,
				customerByIdLoading: true,
				customerByIdError: null,
			};
		case GET_CUSTOMER_BY_ID_SUCCESS:
			return {
				...state,
				customerByIdLoading: false,
				customerById: action.payload,
			};
		case GET_CUSTOMER_BY_ID_FAIL:
			return {
				...state,
				customerByIdLoading: false,
				customerByIdError: action.payload,
			};
		// case CLEAR_STATE:
		// 	return state;
		default:
			return state;
	}
};

export const createCustomerReducer = (
	state = { createCustomerMessage: {} },
	action: any
) => {
	switch (action.type) {
		case CREATE_CUSTOMER_REQUEST:
			return {
				...state,
				createCustomerLoading: true,
				createCustomerError: null,
			};
		case CREATE_CUSTOMER_SUCCESS:
			return {
				...state,
				createCustomerLoading: false,
				createCustomerMessage: action.payload,
			};
		case CREATE_CUSTOMER_FAIL:
			return {
				...state,
				createCustomerLoading: false,
				createCustomerError: 'Something Went Wrong',
			};
		default:
			return state;
	}
};

export const updateCustomerReducer = (
	state = { editCustomerMessage: {}, editCustomerLoading: false },
	action: any
) => {
	switch (action.type) {
		case EDIT_CUSTOMER_REQUEST:
			return {
				...state,
				editCustomerLoading: true,
				editCustomerError: null,
			};
		case EDIT_CUSTOMER_SUCCESS:
			return {
				...state,
				editCustomerLoading: false,
				editCustomerMessage: action.payload,
			};
		case EDIT_CUSTOMER_FAIL:
			return {
				...state,
				editCustomerLoading: false,
				editCustomerError: action.payload,
			};

		default:
			return state;
	}
};
