import {
	IonButton,
	IonCol,
	IonContent,
	IonHeader,
	IonIcon,
	IonImg,
	IonPage,
	IonRow,
	IonTitle,
	IonToolbar,
} from '@ionic/react';

import { create, trash } from 'ionicons/icons';
import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';

import { Link, useHistory } from 'react-router-dom';

interface SingleProductRowProps {
	product: any;
}

const SingleProductRow: React.FC<SingleProductRowProps> = ({ product }) => {
	const [showModal, setShowModal] = useState(false);
	const handleOpenModal = () => {
		setShowModal(true);
	};

	const updateHandleCloseModal = () => {
		setShowModal(false);
	};
	const history = useHistory();

	return (
		<IonRow
			key={product.id}
			className="ionrow-style ion-align-items-center ion-text-center"
			style={{ height: '80px' }}
			onClick={() => {
				history.push(`/app/products/update/${product.id}`);
			}}
		>
			{/* <IonCol className="ion-col-style" sizeMd="1" sizeSm="2">
				
				{product.id}
			</IonCol> */}

			<IonCol className="ion-col-style" sizeLg="2" sizeMd="3" sizeSm="4.5">
				<div>
					<IonImg
						style={{
							width: '100%',
							height: '50px',
						}}
						src={product?.image_display_url}
					/>
				</div>
				{/* {row?.current_subscription.end_date != null
									? new Date(row?.current_subscription?.end_date)
											.toDateString()
											.split(' ')
											.slice(1)
											.join(' ')
									: 'null'} */}
			</IonCol>
			<IonCol className="ion-col-style" sizeLg="3" sizeMd="5" sizeSm="4.5">
				{/* <Link className="" to={`/app/products/update/${product.id}`}> */}
				{product.name}
				{/* </Link> */}
			</IonCol>
			<IonCol className="ion-col-style ion-hide-lg-down" sizeLg="2">
				{/* {row?.onboarding.amount} */}
				{product?.dimension}
			</IonCol>
			<IonCol className="ion-col-style ion-hide-lg-down" sizeLg="1">
				{product.internal_notes}
			</IonCol>
			<IonCol
				className="ion-col-style"
				sizeLg="2"
				sizeMd="2"
				sizeSm="3"
				style={{
					textTransform: 'capitalize',
				}}
			>
				{product.status}
				{/* <IonText className="ion-padding">
									{row?.city == 'undefined' || row?.city == null
										? 'null'
										: `${row?.city}`}
								</IonText>
								<br />
								{row?.phone === null ? null : (
									<ContactButton data={row?.phone} btn="Call" />
								)}
								{row?.email === null ? null : (
									<ContactButton data={row?.email} btn="Mail" />
								)} */}
			</IonCol>

			{/* <IonCol className="ion-col-style ion-hide-md-down" sizeMd="1.5">
				
				{product?.weight_in_gms}
			</IonCol> */}

			{/* <IonCol className="ion-col-style ion-hide-md-down" sizeMd="1.5">
				{product?.laundry_service_price}
				{row?.onboarding.date != null
									? new Date(row?.onboarding.date)
											.toDateString()
											.split(' ')
											.slice(1)
											.join(' ')
									: 'null'}
			</IonCol> */}

			<IonCol className="ion-col-style ion-hide-md-down" sizeLg="2" sizeMd="2">
				{/* {row?.onboarding.amount} */}
				<IonButton
					size="small"
					fill="outline"
					onClick={() => {
						history.push(`/app/products/update/${product.id}`);
					}}
				>
					<IonIcon slot="icon-only" size="small" icon={create}></IonIcon>
				</IonButton>
				{/* <IonButton size="small" fill="outline">
					<IonIcon slot="icon-only" size="small" icon={trash}></IonIcon>
				</IonButton> */}
			</IonCol>
		</IonRow>
	);
};

export default SingleProductRow;
