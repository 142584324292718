import React from 'react';
import {
	IonTabs,
	IonRouterOutlet,
	IonTabBar,
	IonTabButton,
	IonIcon,
	IonLabel,
} from '@ionic/react';
import { Route, Redirect } from 'react-router';
import {
	calendar,
	location,
	informationCircle,
	people,
	home,
	homeSharp,
	appsSharp,
	settingsSharp,
	cashSharp,
	storefrontOutline,
	peopleOutline,
	fileTrayOutline,
	homeOutline,
	listOutline,
} from 'ionicons/icons';
import Dashboard from './dashboard/Dashboard';
import IKAcademy from './dashboard/IKAcademy/IKAcademy';
import Subscriptions from './dashboard/subscriptions/Subscriptions';
import Customer from './dashboard/Customer/Customer';
import Coupons from './dashboard/coupons/Coupons';
import CreateCouponPage from './dashboard/coupons/CreateCouponPage';
import Banners from './dashboard/banners/Banners';
import Settings from './dashboard/settings/Settings';
import BasicInfo from './dashboard/settings/BasicInfo';
import Localization from './dashboard/settings/Localization';
import ServiceDetails from './dashboard/settings/ServiceDetails';
import Payments from './dashboard/settings/Payments';
import QuickOrders from './dashboard/settings/QuickOrders';
import Taxes from './dashboard/settings/taxes/Taxes';
import Orders from './dashboard/orders/OrdersHome';
import Marketing from './dashboard/marketing/Marketing';
import CreateStaffPage from './dashboard/staff/CreateStaffPage';
import Staffs from './dashboard/staff/Staff';
import CreateTaxPage from './dashboard/settings/taxes/CreateTaxes';
// import Home from './auth/Home/Home';
// import GetBusinessDetails from './auth/GetBusinessDetails';
// import SignUpWithEmailOTP from './auth/SignUpWithEmailOTP/SignUpWithEmailOTP';
import Catalogue from './pages/Catalogue';
import UpdateCategoryPage from './pages/UpdateCategory';
import AddCategoryPage from './pages/AddCategory';
import OrderDetails from './dashboard/orders/OrderDetails';
import ProtectedRoute from './route/ProtectedRoute';
import AddProduct from './pages/AddProduct';
import AddSubCategoryPage from './pages/AddSubCategory';
import UpdateSubCategoryPage from './pages/UpdateSubCategory';
import UpdateProduct from './pages/UpdateProduct';
import ProductsHomeScreen from './components/products/ProductsHomeScreen';
import HelpAndSupport from './dashboard/HelpAndSupport/HelpAndSupport';
import Invoice from './pages/Invoice';
import AddBanner from './dashboard/banners/AddBanner';
import PageListing from './dashboard/page/PageListing';
import CreatePage from './dashboard/page/CreatePage';
import UpdatePage from './dashboard/page/UpdatePage';
import Stores from './components/stores/Stores';
import BankAccountDetails from './dashboard/settings/BankAccountDetails';
import MyStoreDetails from './components/stores/MyStoreDetails';
import Support from './dashboard/HelpAndSupport/Support';
import Features from './components/dashboard/Features';
import Leads from './dashboard/leads/Leads';
import CreateLeadsPage from './dashboard/leads/CreateLeadsPage';
import LandingPages from './dashboard/landingpages/LandingPages';
import Reports from './dashboard/reports/Reports';
import DashboardHome from './dashboard/Home/DashboardHome';
import AddCustomer from './dashboard/Customer/AddCustomer';
import CustomerHome from './dashboard/Customer/CustomerHome';
import AddProduct2 from './pages/AddProduct2';
import UpdateCustomer from './dashboard/Customer/UpdateCustomer';
import Finance from './dashboard/Finance/Finance';
import Operations from './dashboard/Operations/Operations';
import CreateFinance from './dashboard/Finance/CreateFinance';
import CreateOperation from './dashboard/Operations/CreateOperation';
import CreateOperationForm from './dashboard/Operations/CreateOperationForm';
import AddOrder from './dashboard/orders/AddOrder';
import CreateFinanceForm from './dashboard/Finance/CreateFinanceForm';
import Test from './dashboard/Finance/Test';
import UpdateFinance from './dashboard/Finance/UpdateFinance';
import Testting from './dashboard/Finance/Testing';

interface MainTabsProps {}

const MainTabs: React.FC<MainTabsProps> = () => {
	return (
		<IonTabs>
			<IonRouterOutlet>
				<ProtectedRoute path="/app/home" component={Dashboard} exact={true} />
				<ProtectedRoute
					path="/app/customers"
					component={CustomerHome}
					exact={true}
				/>
				{/* <ProtectedRoute
					path="/app/customer"
					component={Customer}
					exact={true}
				/> */}
				{/* <ProtectedRoute
					path="/my/ikacademy"
					component={IKAcademy}
					exact={true}
				/>
				<ProtectedRoute path="/my/features" component={Features} exact={true} />
				<ProtectedRoute
					path="/my/subscriptions"
					component={Subscriptions}
					exact={true}
				/>
				
				<ProtectedRoute path="/app/customer" component={Customer} exact={true} />
				<ProtectedRoute path="/my/coupons" component={Coupons} exact={true} />
				<ProtectedRoute
					path="/my/createcoupon"
					component={CreateCouponPage}
					exact={true}
				/>
				<ProtectedRoute
					path="/my/createcoupon/:id"
					component={CreateCouponPage}
					exact={true}
				/>
				<ProtectedRoute path="/my/banners" component={Banners} exact={true} />
				<ProtectedRoute path="/my/settings" component={Settings} exact={true} />
				<ProtectedRoute
					path="/my/settings/basicinfo"
					component={BasicInfo}
					exact={true}
	/>
				<ProtectedRoute
					path="/app/localization"
					component={Localization}
					exact={true}
			/>
				<ProtectedRoute
					path="/my/settings/servicedetails"
					component={ServiceDetails}
					exact={true}
				/>
				<ProtectedRoute
					path="/app/servicedetails"
					component={ServiceDetails}
					exact={true}
				/>
				<ProtectedRoute
					path="/my/settings/payments"
					component={Payments}
					exact={true}
			/>
				<ProtectedRoute
					path="/my/settings/quickorders"
					component={QuickOrders}
					exact={true}
			/>*/}
				<ProtectedRoute
					path="/app/quickorders"
					component={QuickOrders}
					exact={true}
				/>
				<ProtectedRoute path="/app/taxes" component={Taxes} exact={true} />
				<ProtectedRoute
					path="/app/create-tax"
					component={CreateTaxPage}
					exact={true}
				/>
				{/*<ProtectedRoute path="/my/staffs" component={Staffs} exact={true} />
				<ProtectedRoute
					path="/my/createstaff"
					component={CreateStaffPage}
					exact={true}
				/>*/}
				<ProtectedRoute path="/app/orders" component={Orders} exact={true} />
				<ProtectedRoute
					path="/app/orders/create/true"
					component={AddOrder}
					exact={true}
				/>
				<ProtectedRoute
					path="/app/operations"
					component={Operations}
					exact={true}
				/>
				<ProtectedRoute
					path="/app/operations/create/true"
					component={CreateOperation}
					exact={true}
				/>
				<ProtectedRoute
					path="/app/operations/v2/create/true"
					component={CreateOperationForm}
					exact={true}
				/>
				<ProtectedRoute
					path="/app/v2finance"
					component={Test}
					exact={true}
				/>
				<ProtectedRoute
					path="/app/v2finance/update/true/:id"
					component={UpdateFinance}
					exact={true}
				/>
				<ProtectedRoute path="/app/finance" component={Finance} exact={true} />
				<ProtectedRoute
					path="/app/finance/create/true"
					component={CreateFinance}
					exact={true}
				/>
				<ProtectedRoute
					path="/app/v2finance/v2/create/true"
					component={CreateFinanceForm}
					exact={true}
				/>
			
				{/* <ProtectedRoute
					path="/my/catalogue"
					component={Catalogue}
					exact={true}
				/> */}
				{/* <ProtectedRoute path="/app/reports" component={Reports} exact={true} /> */}
				{/*<Route exact path="/my/catalogue/category/add/:fp">
					<AddCategoryPage />
				</Route>
				<Route exact path="/app/subcategory/add/:id/:name/:fp">
					<AddSubCategoryPage />
				</Route>
				<Route exact path="/my/catalogue/category/update/:id">
					{' '}
					<UpdateCategoryPage />
				</Route>
				<Route exact path="/my/catalogue/subcategory/update/:id">
					{' '}
					<UpdateSubCategoryPage />
				</Route>
				<ProtectedRoute
					path="/my/marketing"
					component={Marketing}
					exact={true}
				/> */}
				<ProtectedRoute
					path="/app/products"
					component={ProductsHomeScreen}
					exact={true}
				/>
				{/* <ProtectedRoute path="/app/stores" component={Stores} exact={true} /> */}
				{/* <ProtectedRoute
					path="/my/orders/orderdetails"
					component={OrderDetails}
					exact={true}
				/>*/}
				<ProtectedRoute
					path="/app/orders/order-details/invoice/:id"
					component={Invoice}
					exact={true}
				/>
				<ProtectedRoute
					path="/app/products/update/:id"
					component={UpdateProduct}
					exact={true}
				/>
				<ProtectedRoute
					path="/app/products/create/true"
					component={AddProduct}
					exact={true}
				/>
				
				<ProtectedRoute
					path="/app/customers/create/true"
					component={AddCustomer}
					exact={true}
				/>
				<ProtectedRoute
					path="/app/customers/update/:id"
					component={UpdateCustomer}
					exact={true}
				/>
				{/*<ProtectedRoute
					path="/my/help&support"
					component={HelpAndSupport}
					exact={true}
				/>
				<ProtectedRoute path="/my/support" component={Support} exact={true} />
				<ProtectedRoute
					path="/my/addbanner"
					component={AddBanner}
					exact={true}
				/>
				<ProtectedRoute path="/my/pages" component={PageListing} exact={true} />
				<ProtectedRoute
					path="/my/pages/create"
					component={CreatePage}
					exact={true}
				/>
				<ProtectedRoute
					path="/my/pages/update/:id"
					component={UpdatePage}
					exact={true}
				/> 

				{/* added */}
				<ProtectedRoute path="/app/stores" component={Stores} exact={true} />
				<ProtectedRoute
					path="/app/stores/store-details/:id"
					component={MyStoreDetails}
					exact={true}
				/>
				{/* 	<ProtectedRoute
					path="/my/settings/bankdetails"
					component={BankAccountDetails}
					exact={true}
				/>
				<ProtectedRoute path="/my/leads" component={Leads} exact={true} />
				<ProtectedRoute
					path="/my/createleads"
					component={CreateLeadsPage}
					exact={true}
				/>*/}
				<ProtectedRoute
					path="/app/createleads/:id"
					component={CreateLeadsPage}
					exact={true}
				/>
				<ProtectedRoute
					path="/app/landingpages"
					component={LandingPages}
					exact={true}
				/>
				<ProtectedRoute
					path="/app/testingx"
					component={Testting}
					exact={true}
				/>
				{/* end added */}
			</IonRouterOutlet>
			<IonTabBar slot="bottom">
				<IonTabButton tab="schedule" href="/app/home">
					<IonIcon icon={homeOutline} />
					<IonLabel>Home</IonLabel>
				</IonTabButton>
				<IonTabButton tab="stores" href="/app/orders">
					<IonIcon icon={listOutline} />
					<IonLabel>Orders</IonLabel>
				</IonTabButton>
			</IonTabBar>
		</IonTabs>
	);
};

export default MainTabs;
