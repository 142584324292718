import {
	IonButton,
	IonButtons,
	IonCard,
	IonCol,
	IonContent,
	IonDatetime,
	IonDatetimeButton,
	IonGrid,
	IonHeader,
	IonIcon,
	IonInput,
	IonItem,
	IonLabel,
	IonModal,
	IonPage,
	IonRow,
	IonSelect,
	IonSelectOption,
	IonText,
	IonTitle,
	IonToolbar,
} from '@ionic/react';
import { arrowBack as arrowBackIcon } from 'ionicons/icons';
import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useHistory } from 'react-router';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessage } from '@hookform/error-message';
import {
	electricityUnitsOptions,
	hourOptions,
	weightOptionsGm,
	weightOptionsKg,
} from './operationOptions';

const OperationSchema = yup.object().shape({
	date: yup.string().required('Date is required'),
	receivedLinen: yup
		.string()
		.oneOf(weightOptionsKg.map(option => option.value)),
	washedLinen: yup
		.string()
		.oneOf(weightOptionsKg.map(option => option.value)),
	washerRunTime: yup.string().oneOf(hourOptions.map(option => option.value)),
	extractorRunTime: yup
		.string()
		.oneOf(hourOptions.map(option => option.value)),
	ironingRunTime: yup.string().oneOf(hourOptions.map(option => option.value)),
	electricityUnitsUsed: yup
		.string()
		.oneOf(electricityUnitsOptions.map(option => option.value)),
	hypoBleachUsed: yup
		.string()
		.oneOf(weightOptionsGm.map(option => option.value)),
	supermeUsed: yup
		.string()
		.oneOf(weightOptionsGm.map(option => option.value)),
	neutralColorUsed: yup
		.string()
		.oneOf(weightOptionsGm.map(option => option.value)),
	crystalSodaUsed: yup
		.string()
		.oneOf(weightOptionsGm.map(option => option.value)),

	oilGoUsed: yup.string().oneOf(weightOptionsGm.map(option => option.value)),
	bleachPowderUsed: yup
		.string()
		.oneOf(weightOptionsGm.map(option => option.value)),
	sodaPowderUsed: yup
		.string()
		.oneOf(weightOptionsGm.map(option => option.value)),
	causticSodaUsed: yup
		.string()
		.oneOf(weightOptionsGm.map(option => option.value)),
	excelBleachUsed: yup
		.string()
		.oneOf(weightOptionsGm.map(option => option.value)),
});

type OperationFormInputs = yup.InferType<typeof OperationSchema>;

const CreateOperationForm: React.FC = () => {
	const history = useHistory();
	const {
		handleSubmit,
		register,

		control,
		formState: { errors },
		reset,
	} = useForm<OperationFormInputs>({
		resolver: yupResolver(OperationSchema),
	});

	const onSubmit = data => {
		console.log(data);
	};

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start">
						<IonIcon
							size="large"
							onClick={e => history.goBack()}
							icon={arrowBackIcon}
							style={{
								cursor: 'pointer',
							}}
						/>
					</IonButtons>
					<IonTitle>Create Operation Report</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent className="ion-padding">
				<form
					onSubmit={handleSubmit(onSubmit)}
					className="responsive-width border"
				>
					{/* For Date */}

					<IonGrid>
						<IonRow className="ion-align-items-center ion-justify-content-start">
							<IonCol sizeMd="4">
								<IonItem lines="none">
									<IonLabel>Date</IonLabel>
								</IonItem>
							</IonCol>
							<IonCol>
								<Controller
									name="date"
									control={control}
									render={({ field }) => (
										<IonItem lines="none">
											<IonDatetimeButton datetime="datetime"></IonDatetimeButton>
											<IonModal
												keepContentsMounted={true}
											>
												<IonItem>
													<IonDatetime
														id="datetime"
														presentation="date"
														value={
															field.value
																? new Date(
																		field.value
																  ).toISOString()
																: new Date().toISOString()
														}
														showDefaultButtons={
															true
														}
														onIonChange={e => {
															// console.log(e.detail.value);
															field.onChange(
																e.detail.value
															);
														}}
													/>
												</IonItem>
											</IonModal>
										</IonItem>
									)}
								/>
							</IonCol>
						</IonRow>
					</IonGrid>
					<IonItem className="ion-margin-top ion-padding-horizontal">
						<IonLabel>Received Linen (kg)</IonLabel>
						<Controller
							render={({ field }) => (
								<IonSelect
									placeholder="-Select-"
									value={field.value}
									onIonChange={e => {
										field.onChange(e.detail.value);
									}}
								>
									{weightOptionsKg.map(option => (
										<IonSelectOption
											key={option.id}
											value={option.value}
										>
											{option.label}
										</IonSelectOption>
									))}
								</IonSelect>
							)}
							control={control}
							name="receivedLinen"
						/>
					</IonItem>
					<IonItem className="ion-margin-top ion-padding-horizontal">
						<IonLabel>Washed Linen (kg)</IonLabel>
						<Controller
							render={({ field }) => (
								<IonSelect
									placeholder="-Select-"
									value={field.value}
									onIonChange={e => {
										field.onChange(e.detail.value);
									}}
								>
									{weightOptionsKg.map(option => (
										<IonSelectOption
											key={option.id}
											value={option.value}
										>
											{option.label}
										</IonSelectOption>
									))}
								</IonSelect>
							)}
							control={control}
							name="washedLinen"
						/>
					</IonItem>
					<section className="ion-padding-horizontal ion-margin-top">
						<IonCard className="ion-no-margin ion-no-padding">
							<IonItem lines="none">
								{/* <IonIcon size="small" icon={person}></IonIcon> */}
								<IonText>
									<h5>Machines</h5>
								</IonText>
							</IonItem>
						</IonCard>

						<IonItem className="ion-margin-top ">
							<IonLabel>Washer Run Time</IonLabel>
							<Controller
								render={({ field }) => (
									<IonSelect
										placeholder="-Select-"
										value={field.value}
										onIonChange={e => {
											field.onChange(e.detail.value);
										}}
									>
										{hourOptions.map(option => (
											<IonSelectOption
												key={option.id}
												value={option.value}
											>
												{option.label}
											</IonSelectOption>
										))}
									</IonSelect>
								)}
								control={control}
								name="washerRunTime"
							/>
						</IonItem>
						<IonItem className="ion-margin-top ">
							<IonLabel>Extractor Run Time</IonLabel>
							<Controller
								render={({ field }) => (
									<IonSelect
										placeholder="-Select-"
										value={field.value}
										onIonChange={e => {
											field.onChange(e.detail.value);
										}}
									>
										{hourOptions.map(option => (
											<IonSelectOption
												key={option.id}
												value={option.value}
											>
												{option.label}
											</IonSelectOption>
										))}
									</IonSelect>
								)}
								control={control}
								name="extractorRunTime"
							/>
						</IonItem>
						<IonItem className="ion-margin-top ">
							<IonLabel>Ironing Run Time</IonLabel>
							<Controller
								render={({ field }) => (
									<IonSelect
										placeholder="-Select-"
										value={field.value}
										onIonChange={e => {
											field.onChange(e.detail.value);
										}}
									>
										{hourOptions.map(option => (
											<IonSelectOption
												key={option.id}
												value={option.value}
											>
												{option.label}
											</IonSelectOption>
										))}
									</IonSelect>
								)}
								control={control}
								name="ironingRunTime"
							/>
						</IonItem>
					</section>

					<section className="ion-padding-horizontal ion-margin-top">
						<IonCard className="ion-no-margin ion-no-padding">
							<IonItem lines="none">
								{/* <IonIcon size="small" icon={person}></IonIcon> */}
								<IonText>
									<h5>Electricity</h5>
								</IonText>
							</IonItem>
						</IonCard>
						<IonItem className="ion-margin-top ">
							<IonLabel>Electricity Units Used</IonLabel>
							<Controller
								render={({ field }) => (
									<IonSelect
										placeholder="-Select-"
										value={field.value}
										onIonChange={e => {
											field.onChange(e.detail.value);
										}}
									>
										{electricityUnitsOptions.map(option => (
											<IonSelectOption
												key={option.id}
												value={option.value}
											>
												{option.label}
											</IonSelectOption>
										))}
									</IonSelect>
								)}
								control={control}
								name="electricityUnitsUsed"
							/>
						</IonItem>
					</section>
					<section className="ion-padding-horizontal ion-margin-top">
						<IonCard className="ion-no-margin ion-no-padding">
							<IonItem lines="none">
								<IonText>
									<h5>Chemicals & Detergents</h5>
								</IonText>
							</IonItem>
						</IonCard>
						<IonItem className="ion-margin-top ">
							<IonLabel>Hypo Bleach</IonLabel>
							<Controller
								render={({ field }) => (
									<IonSelect
										placeholder="-Select-"
										value={field.value}
										onIonChange={e => {
											field.onChange(e.detail.value);
										}}
									>
										{weightOptionsGm.map(option => (
											<IonSelectOption
												key={option.id}
												value={option.value}
											>
												{option.label}
											</IonSelectOption>
										))}
									</IonSelect>
								)}
								control={control}
								name="hypoBleachUsed"
							/>
						</IonItem>
						<IonItem className="ion-margin-top ">
							<IonLabel>Supreme (Soda)</IonLabel>
							<Controller
								render={({ field }) => (
									<IonSelect
										placeholder="-Select-"
										value={field.value}
										onIonChange={e => {
											field.onChange(e.detail.value);
										}}
									>
										{weightOptionsGm.map(option => (
											<IonSelectOption
												key={option.id}
												value={option.value}
											>
												{option.label}
											</IonSelectOption>
										))}
									</IonSelect>
								)}
								control={control}
								name="supermeUsed"
							/>
						</IonItem>
						<IonItem className="ion-margin-top ">
							<IonLabel>Neutral Color (Rinse)</IonLabel>
							<Controller
								render={({ field }) => (
									<IonSelect
										placeholder="-Select-"
										value={field.value}
										onIonChange={e => {
											field.onChange(e.detail.value);
										}}
									>
										{weightOptionsGm.map(option => (
											<IonSelectOption
												key={option.id}
												value={option.value}
											>
												{option.label}
											</IonSelectOption>
										))}
									</IonSelect>
								)}
								control={control}
								name="neutralColorUsed"
							/>
						</IonItem>
						<IonItem className="ion-margin-top ">
							<IonLabel>Crystal Soda</IonLabel>
							<Controller
								render={({ field }) => (
									<IonSelect
										placeholder="-Select-"
										value={field.value}
										onIonChange={e => {
											field.onChange(e.detail.value);
										}}
									>
										{weightOptionsGm.map(option => (
											<IonSelectOption
												key={option.id}
												value={option.value}
											>
												{option.label}
											</IonSelectOption>
										))}
									</IonSelect>
								)}
								control={control}
								name="crystalSodaUsed"
							/>
						</IonItem>
						<IonItem className="ion-margin-top ">
							<IonLabel>Oil Go</IonLabel>
							<Controller
								render={({ field }) => (
									<IonSelect
										placeholder="-Select-"
										value={field.value}
										onIonChange={e => {
											field.onChange(e.detail.value);
										}}
									>
										{weightOptionsGm.map(option => (
											<IonSelectOption
												key={option.id}
												value={option.value}
											>
												{option.label}
											</IonSelectOption>
										))}
									</IonSelect>
								)}
								control={control}
								name="oilGoUsed"
							/>
						</IonItem>
						<IonItem className="ion-margin-top ">
							<IonLabel>Bleach Powder</IonLabel>
							<Controller
								render={({ field }) => (
									<IonSelect
										placeholder="-Select-"
										value={field.value}
										onIonChange={e => {
											field.onChange(e.detail.value);
										}}
									>
										{weightOptionsGm.map(option => (
											<IonSelectOption
												key={option.id}
												value={option.value}
											>
												{option.label}
											</IonSelectOption>
										))}
									</IonSelect>
								)}
								control={control}
								name="bleachPowderUsed"
							/>
						</IonItem>
						<IonItem className="ion-margin-top ">
							<IonLabel>Soda Powder</IonLabel>
							<Controller
								render={({ field }) => (
									<IonSelect
										placeholder="-Select-"
										value={field.value}
										onIonChange={e => {
											field.onChange(e.detail.value);
										}}
									>
										{weightOptionsGm.map(option => (
											<IonSelectOption
												key={option.id}
												value={option.value}
											>
												{option.label}
											</IonSelectOption>
										))}
									</IonSelect>
								)}
								control={control}
								name="sodaPowderUsed"
							/>
						</IonItem>
						<IonItem className="ion-margin-top ">
							<IonLabel>Caustic Soda</IonLabel>
							<Controller
								render={({ field }) => (
									<IonSelect
										placeholder="-Select-"
										value={field.value}
										onIonChange={e => {
											field.onChange(e.detail.value);
										}}
									>
										{weightOptionsGm.map(option => (
											<IonSelectOption
												key={option.id}
												value={option.value}
											>
												{option.label}
											</IonSelectOption>
										))}
									</IonSelect>
								)}
								control={control}
								name="causticSodaUsed"
							/>
						</IonItem>
						<IonItem className="ion-margin-top ">
							<IonLabel>Excel Bleach</IonLabel>
							<Controller
								render={({ field }) => (
									<IonSelect
										placeholder="-Select-"
										value={field.value}
										onIonChange={e => {
											field.onChange(e.detail.value);
										}}
									>
										{weightOptionsGm.map(option => (
											<IonSelectOption
												key={option.id}
												value={option.value}
											>
												{option.label}
											</IonSelectOption>
										))}
									</IonSelect>
								)}
								control={control}
								name="excelBleachUsed"
							/>
						</IonItem>
					</section>
					<IonGrid className="ion-text-center ion-margin-vertical">
						<IonButton shape="round" type="submit">
							<IonText color={'light'}>Submit</IonText>
						</IonButton>
					</IonGrid>
				</form>
			</IonContent>
		</IonPage>
	);
};
export default CreateOperationForm;
