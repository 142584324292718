import {
	arrowBack as arrowBackIcon,
	closeOutline,
	caretUpSharp,
	caretDownSharp,
} from 'ionicons/icons';

import { useHistory } from 'react-router';

import { getAccessToken } from '../../services/auth/userLocalStorage';
import { useDispatch, useSelector } from 'react-redux';
import {
	createAdminOrder,
	getALLBusiness,
	getProductsByBusinessId,
} from '../../actions/orderActions';
import { Controller, useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import {
	IonButton,
	IonButtons,
	IonIcon,
	IonList,
	IonLabel,
	IonCol,
	IonContent,
	IonGrid,
	IonHeader,
	IonInput,
	IonItem,
	IonImg,
	IonLoading,
	IonModal,
	IonPage,
	IonRadio,
	IonRadioGroup,
	IonRow,
	IonSelect,
	IonText,
	IonTitle,
	IonToast,
	IonToolbar,
	RadioGroupChangeEventDetail,
	IonSelectOption,
	IonThumbnail,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';

const orderProductSchema = Yup.object().shape({
	id: Yup.number().required('Required'),
	count_pickup: Yup.number(),
	count_verification: Yup.number(),
	count_ready_for_dispatch: Yup.number(),
	count_out_for_delivery: Yup.number(),
	count_delivered: Yup.number(),
	notes_pickup: Yup.string(),
});

const orderSchema = Yup.object().shape({
	business_id: Yup.number().required('Please Select the business first'),
	status: Yup.string().required('Order status is Required'),
	order_products: Yup.array().of(orderProductSchema).required('Required'),
});

interface RadioGroupCustomEvent<T = any> extends CustomEvent {
	detail: RadioGroupChangeEventDetail<T>;
	target: HTMLIonRadioGroupElement;
}
const AddOrder = (props: any) => {
	const [showToast, setShowToast] = useState(false);
	const [toastMessage, setToastMessage] = useState('');
	const [isOrderCreated, setIsOrderCreated] = useState(false);

	const dispatch = useDispatch();
	const history = useHistory();

	const [showModal, setShowModal] = useState(false);

	const {
		businesses: businessData,
		loading: businessLoading,
		// error,
	} = useSelector((state: any) => state.allBusiness);

	const {
		order,
		loading: createOrderLoading,
		error: createOrderError,
	} = useSelector((state: any) => state.createAdminOrder);

	const {
		products: productData,
		loading: productLoading,
		// error: productError,
	} = useSelector((state: any) => state.productsByBusinessId);

	const {
		control,
		handleSubmit,
		register,
		setError,
		reset,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(orderSchema),
	});

	const onSubmit = (data: any) => {
		const newOrder = {
			business_id: data.business_id,
			order_products: data.order_products
				.filter(
					(product: any) =>
						product?.count_pickup ||
						product?.count_verification ||
						product?.count_ready_for_dispatch ||
						product?.count_out_for_delivery ||
						product?.count_delivered
				)
				.map((product: any) => {
					return {
						id: product.id,
						count_pickup: product.count_pickup || 0,
						count_verification: product.count_verification || 0,
						count_ready_for_dispatch: product.count_ready_for_dispatch || 0,
						count_out_for_delivery: product.count_out_for_delivery || 0,
						count_delivered: product.count_delivered || 0,
						notes_pickup: product.notes_pickup || '',
					};
				}),
			status: data.status,
		};
		// console.log(newOrder);

		dispatch(createAdminOrder(getAccessToken().token, newOrder));
	};

	useEffect(() => {
		dispatch(getALLBusiness(getAccessToken().token));
	}, [dispatch]);

	useEffect(() => {
		// if order is created then remove all products for business so we dispatched
		if (isOrderCreated)
			dispatch(getProductsByBusinessId(getAccessToken().token, 0));
	}, [isOrderCreated, dispatch]);

	useEffect(() => {
		if (order?.id) {
			setShowToast(true);
			setToastMessage('Order Added Successfully');
			history.push('/app/orders');
			reset();
			setIsOrderCreated(true);
		}
		if (createOrderError) {
			//if any error happened
			setShowToast(true);
			setToastMessage('Something went wrong');
		}
	}, [order, createOrderError, history, reset]);

	const handleToastDismiss = () => {
		setToastMessage('');
		setShowToast(false);
	};

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start">
						<IonIcon
							size="large"
							onClick={() => history.goBack()}
							icon={arrowBackIcon}
						/>
					</IonButtons>
					<IonTitle>Add Order</IonTitle>
				</IonToolbar>
			</IonHeader>

			<IonContent className="ion-padding">
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="responsive-width-90 border ion-padding">
						{/* Display Business Names */}
						<Controller
							render={({ field }) => (
								<>
									<IonItem
										className=""
										onClick={() => setShowModal(true)}
										style={{ cursor: 'pointer' }}
									>
										<IonLabel className="ion-padding-start">
											Select Business Name
										</IonLabel>

										{field?.value ? (
											<IonText slot="end">
												{
													businessData.find(
														(business: any) => business.id === field?.value
													).name
												}
											</IonText>
										) : (
											''
										)}

										<IonIcon
											slot="end"
											icon={showModal ? caretUpSharp : caretDownSharp}
											style={{
												fontSize: 14 + 'px',
											}}
										/>
									</IonItem>
									<ErrorMessage
										errors={errors}
										name={`business_id`}
										render={({ message }) => (
											<IonText
												color="danger"
												className="ion-margin-start ion-padding-horizontal"
												style={{
													fontSize: 12 + 'px',
												}}
											>
												{message}
											</IonText>
										)}
									/>
									<IonModal
										isOpen={showModal}
										onDidDismiss={() => setShowModal(false)}
									>
										<IonHeader>
											<IonItem lines="none">
												<IonTitle>Select Business Name</IonTitle>
												<IonButton
													fill="clear"
													size="large"
													slot="end"
													onClick={() => setShowModal(false)}
												>
													<IonIcon
														slot="icon-only"
														icon={closeOutline}
													></IonIcon>
												</IonButton>
											</IonItem>
										</IonHeader>
										<IonContent>
											<IonRadioGroup
												// value={
												// 	selectedBusinessId ? selectedBusinessId : undefined
												// }
												onIonChange={(e: RadioGroupCustomEvent) => {
													field.onChange(e.detail.value);
													setShowModal(!showModal);
													dispatch(
														getProductsByBusinessId(
															getAccessToken().token,
															Number(e.detail.value)
														)
													);
												}}
											>
												{businessData.map(business => (
													<IonItem key={business.id} lines="none" className="">
														<IonText>{business.name}</IonText>
														<IonRadio slot="start" value={business.id} />
													</IonItem>
												))}
											</IonRadioGroup>
										</IonContent>
									</IonModal>
								</>
							)}
							control={control}
							name="business_id"
						/>

						<IonItem className="ion-margin-start ion-margin-top">
							<IonLabel>Order Status</IonLabel>
							<Controller
								render={({ field }) => (
									<IonSelect
										slot="end"
										value={field.value}
										onIonChange={e => {
											field.onChange(e.detail.value);
										}}
									>
										<IonSelectOption value="APPROVED">Approved</IonSelectOption>
										<IonSelectOption value="VERIFIED">Verified</IonSelectOption>
										<IonSelectOption value="READY_FOR_DISPATCH">
											Ready For Dispatch
										</IonSelectOption>
										<IonSelectOption value="OUT_FOR_DELIVERY">
											Out For Delivery
										</IonSelectOption>
										<IonSelectOption value="DELIVERED">
											Delivered
										</IonSelectOption>
									</IonSelect>
								)}
								control={control}
								name="status"
							/>
						</IonItem>
						<ErrorMessage
							errors={errors}
							name={`status`}
							render={({ message }) => (
								<IonText
									color="danger"
									className="ion-margin-start ion-padding-horizontal"
									style={{
										fontSize: 12 + 'px',
									}}
								>
									{message}
								</IonText>
							)}
						/>
						{productData.length ? (
							<div
								style={{
									overflowX: 'auto',
									overflowY: 'hidden',
								}}
							>
								<div
									style={{
										minWidth: '520px',
										overflowX: 'auto',
									}}
								>
									<IonList>
										<IonItem>
											<IonGrid>
												<IonRow className="ion-align-items-center ion-text-center ">
													<IonCol size="4" className="ion-col-style">
														<IonLabel className="ion-margin-start">
															<h2
																style={{
																	fontWeight: 'bold',
																}}
															>
																Product
															</h2>
														</IonLabel>
													</IonCol>
													<IonCol size="8">
														<IonItem>
															<IonGrid>
																<IonRow className="ion-text-center ">
																	<IonCol size="12">
																		<IonLabel>
																			<h2
																				style={{
																					fontWeight: 'bold',
																				}}
																			>
																				Counts
																			</h2>
																		</IonLabel>
																	</IonCol>
																</IonRow>
															</IonGrid>
														</IonItem>
														<IonGrid>
															<IonRow>
																<IonCol size="2.4" className={'ion-col-style'}>
																	<IonLabel>
																		<h2
																			style={{
																				fontWeight: 'bold',
																			}}
																		>
																			Pickup
																		</h2>
																	</IonLabel>
																</IonCol>
																<IonCol size="2.4" className={'ion-col-style'}>
																	<IonLabel>
																		<h2
																			style={{
																				fontWeight: 'bold',
																			}}
																		>
																			Verify
																		</h2>
																	</IonLabel>
																</IonCol>
																<IonCol size="2.4" className={'ion-col-style'}>
																	<IonLabel>
																		<h2
																			style={{
																				fontWeight: 'bold',
																			}}
																		>
																			Dispatch
																		</h2>
																	</IonLabel>
																</IonCol>
																<IonCol size="2.4" className={'ion-col-style'}>
																	<IonLabel>
																		<h2
																			style={{
																				fontWeight: 'bold',
																			}}
																		>
																			Out for Delivery
																		</h2>
																	</IonLabel>
																</IonCol>
																<IonCol size="2.4">
																	<IonLabel>
																		<h2
																			style={{
																				fontWeight: 'bold',
																			}}
																		>
																			Delivered
																		</h2>
																	</IonLabel>
																</IonCol>
															</IonRow>
														</IonGrid>
													</IonCol>
												</IonRow>
											</IonGrid>
										</IonItem>
									</IonList>
									{productData?.map((product: any, index) => (
										<IonList key={product.id} className="ion-no-padding">
											<IonItem lines="none">
												<IonGrid>
													<IonRow className="ion-align-items-center">
														<IonCol className="ion-hide">
															<IonInput
																value={product.id}
																{...register(`order_products.${index}.id`)}
															/>
														</IonCol>
														<IonCol
															size="4"
															className="ion-align-items-center ion-justify-content-center  ion-col-style"
															style={{
																display: 'flex',
															}}
														>
															<IonThumbnail className="ion-margin-end ion-hide-sm-down">
																<IonImg src={product.image_display_url} />
															</IonThumbnail>
															<IonLabel className="ion-text-center ">
																{product.name}
															</IonLabel>
														</IonCol>
														{/* Pick up column */}
														<IonCol
															size="1.6"
															className="ion-col-style ion-justify-content-center ion-align-items-center"
															style={{
																display: 'flex',
															}}
														>
															<IonItem
																lines={'full'}
																// disabled={order?.data?.status !== 'NEW'}
															>
																<IonLabel className="ion-text-center">
																	<Controller
																		control={control}
																		name={`order_products.${index}.count_pickup`}
																		render={({ field }) => (
																			<IonInput
																				className="ion-text-center"
																				value={field.value ? field.value : 0}
																				style={{ width: '100%' }}
																				onIonChange={e => {
																					if (!isNaN(Number(e.target.value))) {
																						field.onChange(
																							Number(e.detail.value)
																						);
																						// if (Number(e.detail.value) > 0)
																						// 	handleProductAdd(product.id);
																						// else
																						// 	handleProductRemove(product.id);
																					} else {
																						setError(
																							`order_products.${index}.count_pickup`,
																							{
																								type: 'pattern',
																								message: 'Numbers Only',
																							}
																						);
																					}
																				}}
																			/>
																		)}
																	/>
																	<ErrorMessage
																		errors={errors}
																		name={`order_products.${index}.count_pickup`}
																		render={({ message }) => (
																			<IonText
																				color="danger"
																				className="ion-padding-horizontal"
																				style={{
																					fontSize: 12 + 'px',
																				}}
																			>
																				{message}
																			</IonText>
																		)}
																	/>
																</IonLabel>
															</IonItem>
														</IonCol>
														{/* Verify column */}
														<IonCol
															size="1.6"
															className="ion-col-style ion-justify-content-center ion-align-items-center"
															style={{
																display: 'flex',
															}}
														>
															<IonItem
																lines={'full'}
																// disabled={order?.data?.status !== 'NEW'}
															>
																<IonLabel className="ion-text-center">
																	<Controller
																		control={control}
																		name={`order_products.${index}.count_verification`}
																		render={({ field }) => (
																			<IonInput
																				className="ion-text-center"
																				value={field.value ? field.value : 0}
																				style={{ width: '100%' }}
																				onIonChange={e => {
																					if (!isNaN(Number(e.target.value))) {
																						field.onChange(
																							Number(e.detail.value)
																						);
																						// if (Number(e.detail.value) > 0)
																						// 	handleProductAdd(product.id);
																						// else
																						// 	handleProductRemove(product.id);
																					} else {
																						setError(
																							`order_products.${index}.count_verification`,
																							{
																								type: 'pattern',
																								message: 'Numbers Only',
																							}
																						);
																					}
																				}}
																			/>
																		)}
																	/>
																	<ErrorMessage
																		errors={errors}
																		name={`order_products.${index}.count_verification`}
																		render={({ message }) => (
																			<IonText
																				color="danger"
																				className="ion-padding-horizontal"
																				style={{
																					fontSize: 12 + 'px',
																				}}
																			>
																				{message}
																			</IonText>
																		)}
																	/>
																</IonLabel>
															</IonItem>
														</IonCol>
														{/* Dispatch column */}
														<IonCol
															size="1.6"
															className="ion-col-style ion-justify-content-center ion-align-items-center"
															style={{
																display: 'flex',
															}}
														>
															<IonItem lines={'full'}>
																<IonLabel>
																	<Controller
																		control={control}
																		name={`order_products.${index}.count_ready_for_dispatch`}
																		render={({ field }) => (
																			<IonInput
																				className="ion-text-center"
																				value={field.value ? field.value : 0}
																				style={{ width: '100%' }}
																				onIonChange={e => {
																					if (!isNaN(Number(e.target.value))) {
																						field.onChange(
																							Number(e.detail.value)
																						);
																						// if (Number(e.detail.value) > 0)
																						// 	handleProductAdd(product.id);
																						// else
																						// 	handleProductRemove(product.id);
																					} else {
																						setError(
																							`order_products.${index}.count_ready_for_dispatch`,
																							{
																								type: 'pattern',
																								message: 'Numbers Only',
																							}
																						);
																					}
																				}}
																			/>
																		)}
																	/>
																	<ErrorMessage
																		errors={errors}
																		name={`order_products.${index}.count_ready_for_dispatch`}
																		render={({ message }) => (
																			<IonText
																				color="danger"
																				className="ion-padding-horizontal"
																				style={{
																					fontSize: 12 + 'px',
																				}}
																			>
																				{message}
																			</IonText>
																		)}
																	/>
																</IonLabel>
															</IonItem>
														</IonCol>
														{/* Out for Delivery column */}
														<IonCol
															size="1.6"
															className="ion-col-style ion-justify-content-center ion-align-items-center"
															style={{
																display: 'flex',
															}}
														>
															<IonItem lines={'full'}>
																<IonLabel>
																	<Controller
																		control={control}
																		name={`order_products.${index}.count_out_for_delivery`}
																		render={({ field }) => (
																			<IonInput
																				className="ion-text-center"
																				value={field.value ? field.value : 0}
																				style={{ width: '100%' }}
																				onIonChange={e => {
																					if (!isNaN(Number(e.target.value))) {
																						field.onChange(
																							Number(e.detail.value)
																						);
																					} else {
																						setError(
																							`order_products.${index}.count_out_for_delivery`,
																							{
																								type: 'pattern',
																								message: 'Numbers Only',
																							}
																						);
																					}
																				}}
																			/>
																		)}
																	/>
																	<ErrorMessage
																		errors={errors}
																		name={`order_products.${index}.count_out_for_delivery`}
																		render={({ message }) => (
																			<IonText
																				color="danger"
																				className="ion-padding-horizontal"
																				style={{
																					fontSize: 12 + 'px',
																				}}
																			>
																				{message}
																			</IonText>
																		)}
																	/>
																</IonLabel>
															</IonItem>
														</IonCol>
														{/* Delivered column */}
														<IonCol
															size="1.6"
															className="ion-justify-content-center ion-align-items-center"
															style={{
																display: 'flex',
															}}
														>
															<IonItem lines={'full'}>
																<IonLabel className="ion-text-center">
																	<Controller
																		control={control}
																		name={`order_products.${index}.count_delivered`}
																		render={({ field }) => (
																			<IonInput
																				className="ion-text-center"
																				value={field.value ? field.value : 0}
																				style={{ width: '100%' }}
																				onIonChange={e => {
																					if (!isNaN(Number(e.target.value))) {
																						field.onChange(
																							Number(e.detail.value)
																						);
																						// if (Number(e.detail.value) > 0)
																						// 	handleProductAdd(product.id);
																						// else
																						// 	handleProductRemove(product.id);
																					} else {
																						setError(
																							`order_products.${index}.count_delivered`,
																							{
																								type: 'pattern',
																								message: 'Numbers Only',
																							}
																						);
																					}
																				}}
																			/>
																		)}
																	/>
																	<ErrorMessage
																		errors={errors}
																		name={`order_products.${index}.count_delivered`}
																		render={({ message }) => (
																			<IonText
																				color="danger"
																				className="ion-padding-horizontal"
																				style={{
																					fontSize: 12 + 'px',
																				}}
																			>
																				{message}
																			</IonText>
																		)}
																	/>
																</IonLabel>
															</IonItem>
														</IonCol>
													</IonRow>
												</IonGrid>
											</IonItem>
										</IonList>
									))}
								</div>
							</div>
						) : (
							''
						)}

						<IonGrid className="ion-text-center ion-margin-vertical">
							<IonButton shape="round" type="submit">
								<IonText color={'light'}>Add Order</IonText>
							</IonButton>
						</IonGrid>
						<IonLoading
							isOpen={
								!!productLoading || !!businessLoading || !!createOrderLoading
							}
							message={'Please wait...'}
						/>
					</div>
				</form>

				<IonToast
					isOpen={showToast}
					message={toastMessage}
					onDidDismiss={handleToastDismiss} // Call handleToastDismiss when toast is dismissed
					duration={2000}
					position="top"
				></IonToast>
			</IonContent>
		</IonPage>
	);
};

export default AddOrder;
