import {
	IonButton,
	IonCol,
	IonContent,
	IonHeader,
	IonIcon,
	IonImg,
	IonPage,
	IonRow,
	IonTitle,
	IonToolbar,
} from '@ionic/react';

import { create, trash } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';

import { Link, useHistory } from 'react-router-dom';

interface SingleOrderRowProps {
	order: any;
}

const SingleOrderRow: React.FC<SingleOrderRowProps> = ({ order }) => {
	// const [showModal, setShowModal] = useState(false);
	// const handleOpenModal = () => {
	// 	setShowModal(true);
	// };
	const [sumOfCountPickup, setSumOfCountPickup] = useState<number>(0);

	useEffect(() => {
		setSumOfCountPickup(
			order?.order_products.reduce(
				(sum: number, product: any) => sum + product.count_pickup,
				0
			)
		);
	}, []);

	const history = useHistory();
	// console.log(order);
	return (
		<IonRow
			key={order.id}
			className="ionrow-style ion-align-items-center ion-text-center"
			style={{ height: '80px' }}
			// onClick={() => {
			// 	history.push(`/app/orders/update/${order.id}`);
			// }}
		>
			<IonCol className="ion-col-style" sizeMd="4" sizeSm="4.5">
				{/* <div>
					<IonImg
						style={{
							width: '100%',
							height: '50px',
						}}
						// src={order?.business?.logo_display_url}
					/>
				</div> */}
				{order?.business?.name}
			</IonCol>
			<IonCol className="ion-col-style" sizeMd="6" sizeSm="4.5">
				{/* {order.business.name} */}
				{order?.order_products.length +
					' Products & ' +
					sumOfCountPickup +
					' Items'}
			</IonCol>

			<IonCol className="" sizeMd="2" sizeSm="3">
				{order.status}
			</IonCol>

			{/* <IonCol className="ion-col-style ion-hide-md-down" sizeLg="2" sizeMd="2">
				<IonButton
					size="small"
					fill="outline"
					onClick={() => {
						// history.push(`/app/orders/update/${order.id}`);
					}}
				>
					<IonIcon slot="icon-only" size="small" icon={create} />
				</IonButton>
			</IonCol> */}
		</IonRow>
	);
};

export default SingleOrderRow;
